import { Float, Text } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import * as THREE from 'three';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useControls } from 'leva';
// import { useTheatre } from '../../../../stores/useTheatre';

export function Line({ index }) {
  /*
   * properties
   */

  const group = useRef();
  const material = useRef();
  const [theatreObject, setTheatreObject] = useState(null);

  // const config = useControls('copy ° tech', {
  //   metalness: { value: 0.15, min: 0, max: 1, step: 0.01 },
  //   roughness: { value: 1, min: 0, max: 1, step: 0.01 },
  //   // envMapIntensity: { value: 55, min: 0, max: 100, step: 0.01 },
  //   envMapIntensity: { value: 3.5, min: 0, max: 100, step: 0.01 },
  //   // color: { value: '#d87a00' },
  // });

  const config = {
    metalness: .15,
    roughness: 1,
    envMapIntensity: 3.5,
  }

  const colorLight = new THREE.Color('#07796d');
  const colorDark = new THREE.Color('#276937');

  /*
   * hooks
   */

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        // console.log(data.opacity);
        material.current.opacity = data.opacity;
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  /*
   * visuals
   */

  return (
    <>
      <e.group
        theatreKey={'copy-' + index}
        additionalProps={{
          opacity: .5,
        }}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        ref={group}
        objRef={setTheatreObject}
      >
        <Float
          speed={2} // Animation speed, defaults to 1
          rotationIntensity={0.14} // XYZ rotation intensity, defaults to 1
          floatIntensity={0} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
          floatingRange={[0, 0]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
          <Text
            font="/fonts/Mechsuit.woff"
            castShadow
            receiveShadow
            position={[0.1, -4 + index + index * 1.05, -3.5]}
            fontSize={1.9}
            maxWidth={1}
            lineHeight={1}
            letterSpacing={-.02}
          >
            {index % 2 === 0 ? 'HOME' : 'SWEET'}
            <meshStandardMaterial
              ref={material}
              envMapIntensity={config.envMapIntensity}
              roughness={config.envMapIntensity}
              metalness={config.metalness}
              // color={config.color}
              color={colorDark.lerp(colorLight, index / 4)}
            />
          </Text>
        </Float>
      </e.group>
    </>
  );
}
