import { useHelper } from '@react-three/drei';
import { useControls } from 'leva';
import { useRef } from 'react';
import { BoxHelper, PlaneHelper } from 'three';

export function ShadowPlane() {
  /*
   * properties
   */

  const mesh = useRef();

  // useHelper(mesh, BoxHelper);

  // const shadowConfig = useControls('shadow plane ° reflect', {
  //   position: { value: { x: 0, y: 0, z: -4.26 }, min: -5, max: 5, step: 0.01 },
  //   rotation: { value: { x: 0, y: -0.1, z: 0 }, min: -5, max: 5, step: 0.01 },
  //   opacity: { value: .5, min: 0, max: 2, step: 0.01 },
  //   color: '#7f0090',
  // });

  const shadowConfig = {
    position: { x: 0, y: 0, z: -4.26 },
    rotation: { x: 0, y: -0.1, z: 0 },
    opacity: 0.5,
    color: '#7f0090',
  };

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <mesh
        ref={mesh}
        position={[shadowConfig.position.x, shadowConfig.position.y, shadowConfig.position.z]}
        rotation={[shadowConfig.rotation.x, shadowConfig.rotation.y, shadowConfig.rotation.z]}
        receiveShadow
      >
        <planeGeometry args={[18, 15]} />
        <shadowMaterial transparent={true} opacity={shadowConfig.opacity} color={shadowConfig.color} />
      </mesh>
    </>
  );
}
