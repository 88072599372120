import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';

export function Intro() {
  /*
   * properties
   */

  const laser = useRef();
  const laserMaterial = useRef();

  /*
   * hooks
   */

  useFrame(() => {
    laser.current.scale.set(0.5 + Math.random() * 0.5, 0.9 + Math.random() * 0.1, 0.5 + Math.random() * 0.5);
    laserMaterial.current.opacity = 1 + Math.random() * 0.7;
  });

  /*
   * visuals
   */

  return (
    <>
      <group position={[0, 7, 9]} rotation={[2, 0, -2.2]}>
        <mesh>
          <capsuleGeometry args={[0.8, 5, 4, 40]} />
          <meshStandardMaterial color="#fe9600" />
        </mesh>
        <mesh ref={laser}>
          <cylinderGeometry args={[0.04, 0.04, 50, 6, 1]} />
          <meshStandardMaterial ref={laserMaterial} transparent flatShading color={[10, 5, 3]} />
        </mesh>
      </group>
    </>
  );
}
