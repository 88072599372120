import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { useMouse } from '../../../../stores/useMouse';
import { Line } from '@react-three/drei';
import { Part } from './Part';
import { editable as e } from '@theatre/r3f';
import { useContact } from '../../../../stores/useContact';
import { useSection } from '../../../../stores/useSection';
import { useSounds } from '../../../../stores/useSounds';
import gsap from 'gsap';

export function World() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);

  const container = useRef();

  const outer = useRef();
  const inner = useRef();
  const line = useRef();

  const world = useRef();
  const [theatreObject, setTheatreObject] = useState(null);

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      showWorld();
    }
  }, [transition]);

  const showWorld = () => {
    setTimeout(() => {
      playSound('showElementSmall');
    }, 1700);
    const scale = 1;
    gsap.to(world.current.scale, {
      delay: 1,
      x: scale,
      y: scale,
      z: scale,
      duration: 2,
      ease: 'power4.inOut',
    });
  };

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        // console.log(data.opacity);

        inner.current.material.opacity = data.innerOpacity;
        inner.current.visible = data.innerOpacity > 0;

        outer.current.material.opacity = data.wireframeOpacity;
        outer.current.visible = data.innerOpacity > 0;

        useContact.getState().setLineSize(data.lineSize);
        line.current.scale.set(data.lineSize, data.lineSize, data.lineSize);
        line.current.visible = data.lineSize > 0;
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  useFrame((state, delta) => {
    world.current.rotation.y += delta * 0.2;
  });

  /*
   * visuals
   */
  return (
    <>
      {/* <group ref={container}> */}
      <e.group
        ref={container}
        theatreKey="world"
        additionalProps={{
          innerOpacity: 1,
          wireframeOpacity: 1,
          lineSize: 1,
        }}
        objRef={setTheatreObject}
      >
        <group ref={world} scale={[0.7, 0.7, 0.7]}>
          <mesh ref={outer}>
            <sphereGeometry args={[2.9, 13, 8]} />
            <meshBasicMaterial wireframe color={new THREE.Color(0.4, 20, 0.4)} transparent opacity={0.14} />
          </mesh>

          <mesh ref={inner}>
            <sphereGeometry args={[2.7, 40, 20]} />
            <meshStandardMaterial transparent color="#005c76" />
          </mesh>

          <Line
            ref={line}
            points={[
              [0, 4.1, 0],
              [0, -4.1, 0],
            ]}
            color={new THREE.Color(0.15, 1.5, 0.15)}
            lineWidth={4}
            dashed={true}
            dashScale={12}
          />

          {[...Array(16)].map((value, index) => (
            <Part key={index} index={index} />
          ))}
        </group>
      </e.group>
    </>
  );
}
