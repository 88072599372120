import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useRef } from 'react';
import { editable as e } from '@theatre/r3f';
import * as THREE from 'three';
import { useSounds } from '../../../../stores/useSounds';

export function Mohawk({ index, topY }) {
  /*
   * properties
   */

  const material = useRef();
  const colorLight = new THREE.Color(1 * 14, 0.1 * 14, 0.1 * 14);
  const colorDark = new THREE.Color(0.1 * 3, 0.5 * 3, 0.3 * 3);

  const playSound = useSounds((state) => state.playSound);
  const laser = useRef();
  const isShown = useRef(false);

  // const config = useControls('stop', {
  //   position: { value: { x: 0, y: 1.84, z: 0 }, min: -5, max: 5, step: 0.01 },
  //   top: { value: 0.14, min: 0, max: 1, step: 0.01 },
  //   bot: { value: 0.2, min: 0, max: 1, step: 0.01 },
  //   height: { value: 0.2, min: 0, max: 1, step: 0.01 },
  // });

  const config = {
    position: { x: 0, y: 1.84, z: 0 },
    top: 0.14,
    bot: 0.2,
    height: 0.2,
  };

  /*
   * hooks
   */

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    const lerp = 0.5 + Math.sin(time + index * 0.3) * 0.5;
    material.current.color.lerpColors(colorLight, colorDark, lerp);

    const scale = laser.current.scale.x;
      if (scale > 0.5 && !isShown.current) {
        isShown.current = true;
        playSound('showElementSmall');
      } else if (scale < 0.5 && isShown.current) {
        isShown.current = false;
      }
  });

  /*
   * visuals
   */

  return (
    <>
      <group rotation={[0.3 + 0.4 * index, 0, 0]}>
        <e.mesh ref={laser} theatreKey={'mohawkLaser' + index} position={[0, 0.5, -0.1]} castShadow>
          <cylinderGeometry args={[0.13, 0.04, 4.8, 10]} />
          <meshBasicMaterial ref={material} color={colorLight} />
        </e.mesh>

        <e.mesh theatreKey={'mohawkCap' + index} position={[config.position.x, topY + 0.5, config.position.z - 0.1]}>
          <cylinderGeometry args={[config.top, config.bot, config.height, 16]} />
          <meshStandardMaterial color="#573a00" envMapIntensity={1} roughness={0.15} metalness={0.99} />
        </e.mesh>
      </group>
    </>
  );
}
