import { Decal, GradientTexture, Html } from '@react-three/drei';
import { useControls } from 'leva';
import * as THREE from 'three';
import { BulgeDecal } from './BulgeDecal';
import { editable as e } from '@theatre/r3f';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import style from './Bulge.module.css';
import { useFrame } from '@react-three/fiber';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { useSection } from '../../../../stores/useSection';
import { useSounds } from '../../../../stores/useSounds';

const copy = {
  who: 'Drawing from more than 20 years of experience I help to create award winning digital experiences. I collaborate with agencies, freelancers and clients to convert ideas and designs into unique, striking and purposeful projects.',
  what: 'Having worn different hats in my career, I consider myself first and foremost a front-end web developer. I specialize in interactive animations and real time 3D graphics for the web and turn concepts into functional projects with innovative web technologies.',
  how: 'I like to jump in at the later design stage in an R&D capacity to build a proof-of-concept. Together with the team members I continue to iterate until the result is robust, functional and accessible finished project.',
  skills: ['WebGL', 'React', 'GSAP', 'HTML', 'JavaScript', 'CSS'],
};

export function Bulge({ material, front, flipped }) {
  /*
   * properties
   */

  let texture = '';
  if (front && !flipped) {
    texture = 'who';
  } else if (!front && !flipped) {
    texture = 'what';
  } else if (front && flipped) {
    texture = 'skills';
  } else if (!front && flipped) {
    texture = 'how';
  }

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);
  const [theatreObject, setTheatreObject] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const textShown = useRef(false);
  const paragraph = useRef();
  const panel = useRef();
  const line1 = useRef();
  const line2 = useRef();
  const laserMaterial = useRef();
  const laser = useRef();
  const group = useRef();

  const isBulgeShown = useRef(false);

  const text = copy[texture];

  // const config = useControls(
  //   front ? 'bulge front' : 'bulge back',
  //   front
  //     ? {
  //         position: { value: { x: -2.69, y: 1.68, z: 2.21 }, min: -5, max: 5, step: 0.01 },
  //         rotation: { value: { x: 0, y: 0.65, z: 1.44 }, min: -5, max: 5, step: 0.01 },
  //       }
  //     : {
  //         position: { value: { x: -3.0, y: 2.44, z: -2.71 }, min: -5, max: 5, step: 0.01 },
  //         rotation: { value: { x: 0, y: -0.65, z: 1.2 }, min: -5, max: 5, step: 0.01 },
  //       }
  // );

  let config = {};

  if (front) {
    config.position = { x: -2.69, y: 1.68, z: 2.21 };
    config.rotation = { x: 0, y: 0.65, z: 1.44 };
  } else {
    config.position = { x: -3.0, y: 2.44, z: -2.71 };
    config.rotation = { x: 0, y: -0.65, z: 1.2 };
  }

  /*
   * hooks
   */

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject || transition != 'show') return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        setOpacity(data.opacity);

        if (textShown.current != data.showCopy) {
          textShown.current = data.showCopy;
          // console.log('change text, bulge ' + texture);
          // console.log(showText.current);

          if (textShown.current) {
            showText();
          } else {
            hideText();
          }
        }
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  useEffect(() => {
    // console.log('Bulge :: change transition');
    // console.log('transition: ' + transition);
    if (transition === 'hide') {
      hidePanel();
    }
  }, [transition]);

  const hidePanel = () => {
    // console.log('Bulge :: hidePanel');
    if (panel.current) {
      gsap.to(panel.current, { opacity: 0, duration: 0.5, ease: 'power4.in' });
    }
  };

  const showText = () => {
    // console.log('showText');

    if (line1.current && line2.current && paragraph.current) {
      playSound('showBig');

      gsap.killTweensOf(line1.current);
      gsap.killTweensOf(line2.current);
      gsap.to(line1.current, { delay: 0.2, scaleY: 1, duration: 0.7, ease: 'power4.out' });
      gsap.to(line2.current, { delay: 0.3, scaleY: 1, duration: 0.7, ease: 'power4.out' });

      gsap.killTweensOf(paragraph.current.children);
      gsap.set(paragraph.current.children, { autoAlpha: 0, y: 30, rotation: 10 });

      const stagger = texture === 'skills' ? 0.1 : 0.02;
      gsap.to(paragraph.current.children, {
        autoAlpha: 1,
        y: 0,
        rotation: 0,
        duration: 0.3,
        stagger,
        ease: 'back.out',
      });
    }
  };

  const hideText = () => {
    // console.log('hideText');

    if (line1.current && line2.current && paragraph.current) {
      playSound('hide');

      gsap.killTweensOf(line1.current);
      gsap.killTweensOf(line2.current);
      gsap.to(line1.current, { scaleY: 0, duration: 0.3, ease: 'power4.out' });
      gsap.to(line2.current, { scaleY: 0, duration: 0.3, ease: 'power4.out' });

      gsap.killTweensOf(paragraph.current.children);

      const stagger = texture === 'skills' ? 0.02 : 0.005;
      gsap.to(paragraph.current.children, { autoAlpha: 0, duration: 0.05, stagger, ease: 'sine.in' });
    }
  };

  useFrame(() => {
    laser.current.scale.set(0.5 + Math.random() * 0.5, 0.9 + Math.random() * 0.1, 0.5 + Math.random() * 0.5);
    laserMaterial.current.opacity = 1 + Math.random() * 0.7;

    const scale = group.current.scale.x;
    if (scale > 0.75 && !isBulgeShown.current) {
      isBulgeShown.current = true;
      playSound('thud');
    } else if (scale < 0.75 && isBulgeShown.current) {
      isBulgeShown.current = false;
    }
  });
  /*
   * visuals
   */

  return (
    <>
      <group scale={[flipped ? -1 : 1, 1, 1]}>
        <group
          position={[config.position.x, config.position.y, config.position.z]}
          rotation={[config.rotation.x, config.rotation.y, config.rotation.z]}
        >
          <e.group
            ref={group}
            theatreKey={'bulge ' + texture}
            additionalProps={{
              opacity: 1,
              showCopy: false,
            }}
            objRef={setTheatreObject}
          >
            <mesh position={[0, 5.5, 0]} ref={laser}>
              <cylinderGeometry args={[0.03, 0.04, 10, 6, 1]} />
              <meshBasicMaterial
                ref={laserMaterial}
                color={[3, 2.5, 1.5]}
                transparent={true}
                blending={THREE.AdditiveBlending}
              >
                <GradientTexture
                  stops={[0, 1]} // As many stops as you want
                  colors={['rgb(0%, 0%, 0%)', 'rgb(500%, 400%, 200%)']} // Colors need to match the number of stops
                  size={32} // Size is optional, default = 1024
                />
              </meshBasicMaterial>
            </mesh>
            <mesh material={material} castShadow receiveShadow>
              <capsuleGeometry args={[0.46, 1.9, 4, 25]} />
              <BulgeDecal flipped={flipped} decal={texture} opacity={opacity} />
              <Decal
                debug={false}
                rotation={[0, 0, 0]}
                position={[0, 0.95, 0]}
                scale={[1, 0.07, 1]} // Scale of the decal
              >
                <meshStandardMaterial
                  polygonOffset={true}
                  polygonOffsetUnits={-10}
                  metallness={0}
                  roughness={0.3}
                  envMapIntensity={0}
                  color={[6, 5, 3]}
                />
              </Decal>
            </mesh>

            <mesh position={[0, -1.4, 0]} material={material} castShadow receiveShadow>
              <cylinderGeometry args={[0.2, 0.1, 0.2, 25]} />
            </mesh>
          </e.group>

          <Html
            center
            rotation={[0, 0, -1.57]}
            position={[-1.5, 0, 0]}
            scale={[flipped ? -1 : 1, 1, 1]}
            distanceFactor={1.5}
            transform
            wrapperClass={style.wrapper}
          >
            <div ref={panel} className={`${style.panel} ${texture === 'skills' ? style.small : ''}`}>
              <div ref={line1} className={style.line1} />
              <div ref={line2} className={style.line2} />
              {texture !== 'skills' && (
                <p aria-label={text} ref={paragraph} className={style.copy}>
                  {text.split(' ').map((word, index) => (
                    <span key={index}>{word}</span>
                  ))}
                </p>
              )}
              {texture === 'skills' && (
                <ul ref={paragraph} className={style.ul}>
                  {text.map((line, index) => (
                    <li aria-label={line} key={index}>
                      &nbsp;{line}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Html>
        </group>
      </group>
    </>
  );
}
