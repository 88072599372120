import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useRef } from 'react';
import * as THREE from 'three';

export function Nose({ left }) {
  /*
   * properties
   */

  const laser = useRef();

  // const configTop = useControls('Nose', {
  //   position: { value: { x: -0.2, y: -1.01, z: 2.72 }, min: -4, max: 4, step: 0.01 },
  //   rotation: { value: { x: 0, y: -0.55, z: -0.69 }, min: -2, max: 2, step: 0.01 },
  // });

  const configTop = {
    position: { x: -0.2, y: -1.01, z: 2.72 },
    rotation: { x: 0, y: -0.55, z: -0.69 },
  };

  const material = useRef();
  // const colorGreen = new THREE.Color('#71ff66');
  // const colorBlue = new THREE.Color('#66ffcb');

  const multiplier = 2;
  const colorGreen = new THREE.Color(0.05 * multiplier, 1.0 * multiplier, 0.05 * multiplier);
  const colorBlue = new THREE.Color(0.0 * multiplier, 1.0 * multiplier, 1.0 * multiplier);

  /*
   * hooks
   */

  useFrame(() => {
    laser.current.scale.set(0.7 + Math.random() * 0.6, 1, 0.7 + Math.random() * 0.6);
  });

  /*
   * visuals
   */

  return (
    <>
      <group scale={[left ? 1 : -1, 1, 1]}>
        <mesh
          ref={laser}
          position={[configTop.position.x, configTop.position.y, configTop.position.z]}
          rotation={[configTop.rotation.x, configTop.rotation.y, configTop.rotation.z]}
        >
          <cylinderGeometry args={[0.04, 0.02, 0.5, 10, 1, true]} />
          <meshBasicMaterial ref={material} color={new THREE.Color().lerpColors(colorGreen, colorBlue, 0.3)} />
        </mesh>
      </group>
    </>
  );
}
