import { useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { useSection } from '../../../../stores/useSection';
import gsap from 'gsap';
import { useSounds } from '../../../../stores/useSounds';
import { useMaps } from '../../../../stores/useMaps';

export function HaloRing({ pct, speed, index }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);
  const mesh = useRef();

  // const texture = useTexture('/textures/cyber/cybertext.png').clone();

  const texture = useMemo(() => {
    return useMaps.getState().cybertext.clone();
  }, []);

  texture.repeat.set(4, 1);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;

  let isDrifting = true;
  let nextTime = 0;

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      showHalo();
    }
  }, [transition]);

  const showHalo = () => {
    const scale = 1;
    const delay = 1 + index * 0.3;
    gsap.to(mesh.current.scale, {
      delay,
      x: scale,
      y: scale,
      z: scale,
      onStart: () => {
        playSound('generalButton');
      },
      duration: 1,
      ease: 'back.out',
    });
  };

  useFrame((state, delta) => {
    // const time = state.clock.elapsedTime;
    const time = state.clock.elapsedTime;

    setDrift(time);
    drift(delta);
    setLasers(delta);
  });

  const drift = (delta) => {
    if (isDrifting) {
      texture.offset.y += delta * 1;
    }
  };

  const setDrift = (time) => {
    if (time > nextTime) {
      isDrifting = !isDrifting;

      if (isDrifting) {
        nextTime = time + 0.3 + Math.random() * 1;
        texture.offset.set(0, -1 + Math.random() * 2);
      } else {
        nextTime = time + 6 + Math.random() * 10;
        texture.offset.set(0, 0);
      }
    }
  };

  const setLasers = (delta) => {
    mesh.current.rotation.y += delta * speed;
    mesh.current.material.opacity = 0.75 + Math.random() * 0.25;
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh} position={[0, pct * 0.2, 0]} castShadow scale={[0.3, 0.3, 0.3]}>
        <cylinderGeometry args={[3.5, 3.5, 0.17, 32, 1, true]} />
        <meshBasicMaterial
          map={texture}
          color={new THREE.Color(2.6, 2.6, 2.6)}
          transparent={true}
          depthWrite={false}
          // depthTest={false}
          // depthFunc={THREE.LessEqualDepth}
          polygonOffset={true}
          polygonOffsetFactor={10}
          polygonOffsetUnits={10}
          side={THREE.DoubleSide}
          blending={THREE.AdditiveBlending}
        />
      </mesh>
    </>
  );
}
