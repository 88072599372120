import { useEffect, useMemo, useRef, useState } from 'react';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import style from './Extra.module.css';
import * as THREE from 'three';

import gsap from 'gsap';
import { useSection } from '../../../stores/useSection';

export function Extra() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const container = useRef();
  const block1 = useRef();
  const block2 = useRef();
  const [visible, setVisible] = useState(true);
  let items;

  /*
   * hooks
   */
  useEffect(() => {
    // console.log('hier');

    items = Array.from(container.current.querySelectorAll('span'));
    const intervalId = setInterval(setItems, 3000);

    loop();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      show();
    }
  }, [transition]);

  const show = () => {
    // console.log('Intro :: show');

    const y = window.innerHeight * 0.03;

    gsap.to([block1.current, block2.current], { opacity: 0, y: y });

    gsap.to([block1.current, block2.current], {
      opacity: 0.65,
      y: 0,
      delay: 3,
      stagger: 0.2,
      duration: 1,
      ease: 'power4.out',
    });
  };

  const setItems = () => {
    for (const item of items) {
      item.style.opacity = Math.random() > 0.5 ? 1 : 0.5;
    }
  };

  const loop = () => {
    if (container.current) {
      let scrollPosition = Math.min(1, useScrollPosition.getState().scrollPosition * 9);

      container.current.style.opacity = 1 - scrollPosition;

      setVisible(scrollPosition < 1);

      requestAnimationFrame(loop);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.extra} ${visible ? style.visible : ''}`}>
        <div ref={block1} className={style.block}>
          <p className={style.title}>Skull properties</p>
          <p className={style.body}>
            <span>Reflective material</span>
            <br />
            <span>Colorful decals</span>
            <br />
            <span>Glowing extensions</span>
            <br />
          </p>
        </div>
        <div ref={block2} className={style.block}>
          <p className={style.title}>Features</p>
          <p className={style.body}>
            <span>Metal mohawk</span>
            <br />
            <span>Dual displays</span>
            <br />
            <span>Plastic cross</span>
          </p>
        </div>
      </div>
    </>
  );
}
