import { Route, Routes } from 'react-router-dom';

import { Clone, PerformanceMonitor, Preload, SoftShadows, useGLTF, useKTX2 } from '@react-three/drei';
import { Work } from './sites/work/Work';
import { useMaps } from '../stores/useMaps';
import { About } from './sites/about/About';
import { Info } from './sites/info/Info';
import { Home } from './sites/home/Home';
import { NotFound } from './sites/notfound/NotFound';
import { PostProcessing } from './core/Postprocessing';
import { SharedLight } from './core/SharedLight';
import { Canvas, useThree } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import { useEffect, useRef, useState } from 'react';
import { useSection } from '../stores/useSection';
import { Materials } from './Materials';
import { useSize } from '../stores/useSize';
import { Pages } from './Pages';

export function App3D() {
  // console.log('App3D render');
  /*
   * properties
   */

  const [shadows, setShadows] = useState(true);
  const loaded = useMaps((state) => state.loaded);

  const dpr = useSize((state) => state.dpr);
  const setDpr = useSize((state) => state.setDpr);

  // const loaded = useMaps((state) => state.loaded);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('App3D -- onMount');
  }, []);

  const performanceChange = (state) => {
    // console.log(state);

    const sum = state.averages.reduce((acc, val) => acc + val, 0);
    const averageFps = state.averages.length ? sum / state.averages.length : 0;
    // console.log(averageFps);

    const deviceDpr = Math.min(2, window.devicePixelRatio);

    if (averageFps < 24) {
      if (dpr === deviceDpr && averageFps > 14) {
        setDpr(deviceDpr * 0.75);
      } else if (dpr > deviceDpr * 0.5) {
        setDpr(deviceDpr * 0.5);
      } else if (shadows) {
        setShadows(false);
      }
    }
  };

  return (
    <>
      <Canvas
        shadows={shadows}
        flat={false}
        dpr={dpr}
        gl={{
          powerPreference: 'high-performance',
          antialias: false,
          stencil: false,
          // depth: false, //probleem op iOS
        }}
      >
        {/* <Perf position="top-left" /> */}
        <Materials />
        <SharedLight />
        <Preload all />

        {loaded && (
          <>
            <PerformanceMonitor
              ms={250}
              iterations={5}
              onChange={performanceChange}
              bounds={(refreshrate) => [24, 200]}
            />
            {shadows && <SoftShadows size={10} samples={7} focus={2} />}
            <PostProcessing dpr={dpr} />

            <Pages />
          </>
        )}
      </Canvas>
    </>
  );
}
