import { NeckItem } from './NeckItem';

export function Neck() {
  /*
   * properties
   */

  const total = 3;

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <group position={[0, -1.4, -.95]} rotation={[Math.PI * 0.57, 0, 0]}>
        {Array.from({ length: total }, (_, i) => (
          <NeckItem key={i} index={i} total={total} />
        ))}
      </group>
    </>
  );
}
