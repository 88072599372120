import { useEffect, useRef, useState } from 'react';
import style from './HireModalButton.module.css';
import gsap from 'gsap';
import { useScrollPosition } from '../../stores/useScrollPosition';
import { RandomLabel } from './RandomLabel';
import { useSounds } from '../../stores/useSounds';
import { useMouse } from '../../stores/useMouse';

export function HireModalButton({ onClick }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const isTouch = useMouse((state) => state.isTouch);
  const button = useRef();
  const [isOver, setIsOver] = useState(false);

  const onMouseOver = (index) => {
    // console.log('over');
    if (!isTouch) {
      playSound('generalButton');
      setIsOver(true);
    }
  };

  const onMouseOut = () => {
    // console.log('out');
    if (!isTouch) {
      playSound('generalButton');
      setIsOver(false);
    }
  };

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={style.wrapper}>
        <button
          aria-label="Email"
          ref={button}
          className={style.button}
          onClick={onClick}
          onMouseEnter={onMouseOver}
          // onMouseOver={onMouseOver}
          onMouseLeave={onMouseOut}
          // onMouseOut={onMouseOut}
        >
          <div className={style.block1} />
          <div className={style.block2} />
          <RandomLabel label="Email" active={isOver} />
          <div className={style.subLabel}>//DROP ME AN</div>
        </button>
      </div>
    </>
  );
}
