import { useEffect, useRef } from 'react';
import { Billboard, shaderMaterial } from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import * as THREE from 'three';

const BackgroundMaterial = shaderMaterial(
  {
    uFrequency: new THREE.Vector2(1, 1),
    uTime: 0,
    uColor1: new THREE.Color(1.0, 1.0, 1.0),
    uColor2: new THREE.Color(1.0, 1.0, 1.0),
    uColor3: new THREE.Color(1.0, 1.0, 1.0),
    uColor4: new THREE.Color(1.0, 1.0, 1.0),
  },
  vertexShader,
  fragmentShader
);

extend({ BackgroundMaterial });

export function Background({ color1, color2, color3, color4 }) {
  /*
   * properties
   */

  const material = useRef();
  const plane = useRef();

  /*
   * hooks
   */
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = (e) => {
    const ratio = window.innerWidth / window.innerHeight;

    plane.current.scale.set(Math.max(1, ratio), Math.max(1, 1 / ratio), 1);

    // console.log(plane.current.scale);
  };

  useFrame((state, delta) => {
    material.current.uniforms.uTime.value += delta;
  });

  /*
   * visuals
   */

  return (
    <>
      <Billboard>
        <mesh ref={plane} position={[0, 0, -22]}>
          <planeGeometry args={[32, 32]} />

          <backgroundMaterial
            ref={material}
            uTime={0}
            uColor1={color1}
            uColor2={color2}
            uColor3={color3}
            uColor4={color4}
          />

          {/* <meshBasicMaterial wireframe /> */}
        </mesh>
      </Billboard>
    </>
  );
}
