import { useEffect } from 'react';
import { BigButton } from '../../component/BigButton';
import { Scroller } from '../../component/Scroller';
import { SectionContent } from '../../component/SectionContent';
import { Content } from './Content';
import { Extra } from './Extra';
import { Intro } from './Intro';

export function Work() {
  /*
   * properties
   */

  // console.log('Work 2D :: render');

  /*
   * hooks
   */

  // useEffect(() => {
  //   console.log('Work 2D :: onMount');
  // });

  /*
   * visuals
   */

  return (
    <>
      {/* <Scroller label="work" /> */}
      <SectionContent>
        <Intro />
        <Extra />
        {/* <Content /> */}
        <Scroller label="work">
          <Content />
        </Scroller>
        <BigButton label="Info" subLabel="//CONTACT INFORMATION" topLabel="コンタック" link="info" />
      </SectionContent>
    </>
  );
}
