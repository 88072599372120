import { Html } from '@react-three/drei';
import style from './Panel.module.css';
import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../../stores/useSection';
import { editable as e } from '@theatre/r3f';
import { useScrollPosition } from '../../../../stores/useScrollPosition';
import { useFrame } from '@react-three/fiber';
import { useSounds } from '../../../../stores/useSounds';

export function Panel({ text, index, position, rotation }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);
  const isReady = useRef(false);
  const textShown = useRef(false);
  const paragraph = useRef();
  const panel = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      isReady.current = true;
    }, 2100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const showText = () => {
    playSound('show');

    const children = panel.current.querySelectorAll('span, a');

    gsap.killTweensOf(children);
    gsap.set(children, { autoAlpha: 0, y: 30, rotation: 10 });

    gsap.to(children, { autoAlpha: 1, y: 0, rotation: 0, duration: 0.3, stagger: 0.04, ease: 'back.out' });
  };

  const hideText = () => {
    playSound('hide');

    const children = panel.current.querySelectorAll('span, a');

    gsap.killTweensOf(children);

    gsap.to(children, { autoAlpha: 0, y: 30, rotation: 10, duration: 0.3, stagger: 0.01, ease: 'sine.in' });
  };

  useEffect(() => {
    if (transition === 'hide') {
      hidePanel();
    }
  }, [transition]);

  const hidePanel = () => {
    gsap.to(panel.current, { opacity: 0, duration: 0.5, ease: 'power4.in' });
  };

  useFrame((state) => {
    // console.log(isReady.current);
    if (isReady.current) {
      const scrollPosition = useScrollPosition.getState().scrollPosition;

      let shown;
      if (index === 0) {
        shown = scrollPosition > 0.19 && scrollPosition < 0.4;
      } else if (index === 1) {
        shown = scrollPosition > 0.4571 && scrollPosition < 0.6857;
      } else {
        shown = scrollPosition > 0.7428 && scrollPosition < 0.9;
      }

      if (textShown.current != shown) {
        textShown.current = shown;

        if (shown) {
          showText();
        } else {
          hideText();
        }
      }
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <Html center position={position} rotation={rotation} distanceFactor={2.5} transform wrapperClass={style.wrapper}>
        <p aria-label={text.join(' ')} ref={panel} className={style.panel}>
          {text.map((line, index) => (
            <span ref={paragraph} key={index} className={style.copy}>
              {line.split(' ').map((word, index) => (
                <span key={index}>{word}</span>
              ))}
            </span>
          ))}
        </p>
      </Html>
    </>
  );
}
