import { useEffect, useRef } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { MathUtils } from 'three';
// import { PerspectiveCamera, editable as e } from '@theatre/r3f';
import { editable as e, SheetProvider } from '@theatre/r3f';
import { useFrame } from '@react-three/fiber';
import { useMouse } from '../../stores/useMouse';
import { useSection } from '../../stores/useSection';
import gsap from 'gsap';

export function SectionCamera({ section }) {
  /*
   * properties
   */

  const camera = useRef();
  const transition = useSection((state) => state.transition);
  const ratio = 1 / 1;

  /*
   * hooks
   */

  // set camera FOV after resizing
  useEffect(() => {
    // console.log('SectionCamera :: onMount');
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    camera.current.position.z = -1.1;
    gsap.to(camera.current.position, { delay: 0.5, duration: 1, z: 0, ease: 'power4.inOut' });
  };

  const hide = () => {
    gsap.to(camera.current.position, { duration: 0.8, z: -1, ease: 'power4.in' });
  };

  const resizeHandler = (e) => {
    const fov = 50;
    const cam = camera.current;

    if (cam.aspect > ratio) {
      //window too wide
      cam.fov = fov;
    } else {
      // window too narrow
      const cameraHeight = Math.tan(MathUtils.degToRad(fov * 0.5));
      const rat = cam.aspect / ratio;
      cam.fov = MathUtils.radToDeg(Math.atan(cameraHeight / rat)) * 2;
    }
  };

  useFrame((state) => {
    const { slowPctX, slowPctY } = useMouse.getState();

    const time = state.clock.elapsedTime;
    let xPct = Math.sin(time * 0.983);
    let yPct = Math.cos(time * 0.652);

    const x = slowPctX * 0.7 + xPct * 0.3;
    const y = slowPctY * 0.7 + yPct * 0.3;

    camera.current.position.x = x * -0.2;
    camera.current.position.y = y * 0.2;
    camera.current.rotation.y = x * -0.03;
    camera.current.rotation.x = y * -0.03;
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group theatreKey={section + ' camera'}>
        <PerspectiveCamera ref={camera} makeDefault={true} near={0.1} far={100} />
      </e.group>

      {/* <PerspectiveCamera ref={camera} position={[0, 0, 10]} makeDefault near={0.1} far={100} /> */}
      {/* <OrbitControls makeDefault dampingFactor={1} /> */}
    </>
  );
}
