import { useEffect, useRef } from 'react';
import { Line } from './Line';
import style from './NotFound.module.css';
import gsap from 'gsap';
import { useSection } from '../../../stores/useSection';
import { Layer } from '../../Layer';

export function NotFound() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const body = useRef();

  // console.log('Work 2D :: render');

  /*
   * hooks
   */

  // useEffect(() => {
  //   if (transition === 'show') {
  //     show();
  //   } else if (transition === 'hide') {
  //     hide();
  //   }
  // }, [transition]);

  // const show = () => {
  //   gsap.set(body.current.children, { opacity: 0, y: window.innerHeight * 0.02 });
  //   gsap.to(body.current.children, { opacity: 1, y: 0, duration: 0.5, stagger: 0.06, ease: 'power4.out' });
  // };

  // const hide = () => {
  //   gsap.to(body.current.children, {
  //     opacity: 0,
  //     // y: window.innerHeight * 0.02,
  //     duration: 0.3,
  //     stagger: 0.01,
  //     ease: 'power4.in',
  //   });
  // };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.wrapper}>
        <div ref={body} className={style.body}>
          <Line index={0} label="@@@@@@@@%##**++++*%@@@@@@@" />
          <Line index={1} label="@@@@@@*========-----=#@@@@" />
          <Line index={2} label="@@@@#=-----==-------::-*@@" />
          <Line index={3} label="@@@#=----------------:--=@" />
          <Line index={4} label="@@@*----====-------------%" />
          <Line index={5} label="@@@*---======------------@" />
          <Line index={6} label="@@@-::::-====-:---------+@" />
          <Line index={7} label="@@@:    :-==-.    .-----%@" />
          <Line index={8} label="@@@-     .-=-     .-::-=@@" />
          <Line index={9} label="@@%-:....  --:.   --::-#@@" />
          <Line index={10} label="@@@=--:-.  .--:------:=@@@" />
          <Line index={11} label="@@@@%#=-:. .----+++#@#@@@@" />
          <Line index={12} label="@@@@@@#--------*@@@@@@@@@@" />
          <Line index={13} label="@@@@@@#\\::|||/@@@@@@@@@@@" />
          <br />
          <Line index={14} label="404-NOT-FOUND.TXT skull//1" />
        </div>
      </div>
    </>
  );
}
