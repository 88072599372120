import { useEffect, useRef } from 'react';
import style from './Footer1.module.css';
import SVG from './svg/footer1.svg?react';

export function Footer1() {
  /*
   * properties
   */

  const container = useRef();
  let parts;

  /*
   * hooks
   */

  useEffect(() => {
    parts = Array.from(container.current.querySelectorAll('#parts > *'));

    const intervalId = setInterval(render, 1107);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const render = () => {
    for (const part of parts) {
      // console.log(part);
      part.style.opacity = Math.random() > .9 ? .5 : 1;
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.footer1} ref={container}>
        <SVG />
      </div>
    </>
  );
}
