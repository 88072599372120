import LuiquidEffect from './LuiquidEffect.jsx';
import { forwardRef } from 'react';

export default forwardRef(function Liquid(props, ref) {
  const effect = new LuiquidEffect(props);

  return (
    <>
      <primitive ref={ref} object={effect} />
    </>
  );
});
