import { useEffect, useRef, useState } from 'react';
import { Howler } from 'howler';
import style from './SoundButton.module.css';
import gsap from 'gsap';
import { useSounds } from '../../stores/useSounds';
import { loadSounds } from '../../stores/useSounds';
import SVG from '../shared/svg/sound.svg?react';

export function SoundButton() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  let [start, setStart] = useState(false);
  let [soundOn, setSoundOn] = useState(true);
  let [switchedOff, setSwitchedOff] = useState(false);
  let background = useRef();

  let volume = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    if (!switchedOff) {
      gsap.to(volume, { current: 1, duration: 3, onUpdate: setVolume, ease: 'sine.out' });
    }
  }, [start]);

  useEffect(() => {
    document.addEventListener('visibilitychange', tabSwitch);

    return () => {
      document.removeEventListener('visibilitychange', tabSwitch);
    };
  });

  const tabSwitch = () => {
    if (document.visibilityState === 'visible') {
      Howler.mute(false);
    } else {
      Howler.mute(true);
    }
  };

  useEffect(() => {
    Howler.volume(0);

    const intervalId = setInterval(() => {
      if (Howler._audioUnlocked) {
        setStart(true);

        loadSounds();
        playSound('music');

        clearInterval(intervalId);
      }
    }, 250);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const soundHandler = () => {
    // console.log('soundHandler');

    gsap.killTweensOf(volume);

    //not async
    setSwitchedOff(soundOn);

    // console.log('switchedOff: ' + switchedOff);

    if (soundOn) {
      gsap.to(volume, { current: 0, duration: 0.5, onUpdate: setVolume });
    } else {
      gsap.to(volume, { current: 1, duration: 0.5, onUpdate: setVolume });
    }

    //async!
    setSoundOn(!soundOn);
  };

  const setVolume = () => {
    Howler.volume(volume.current);
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={`${style.sound} ${switchedOff ? style.switchedOff : ''}`}>
        <button aria-label="sound" className={style.button} onClick={soundHandler} />
        <SVG />
      </div>
    </>
  );
}
