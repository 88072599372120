import { Environment, OrthographicCamera, useHelper } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { folder, useControls } from 'leva';
import { useEffect, useRef } from 'react';
import { CameraHelper, DirectionalLightHelper, SpotLightHelper } from 'three';
import { useResponsive } from '../../../../stores/useResponsive';
// import { useControls } from 'leva';

export function Lighting() {
  /*
   * properties
   */

  const x = useResponsive((state) => state.x);
  const y = useResponsive((state) => state.y);

  const shadowLight = useRef();

  const camera = useRef();

  // const config = useControls('lights ° tech', {
  //   helpers: true },
  //   'ambient light': folder({
  //     intensityAmbient: 1.5, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorAmbient: '#ebffde', label: 'color' },
  //   }),
  //   'shadow directional light': folder({
  //     positionShadow: { x: -1.7, y: 1.6, z: 2 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //     // positionShadow: { x: -2, y: 1, z: 4 }, min: -15, max: 15, step: 0.01, label: 'position' },
  //     intensityShadow: 2.6, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorShadow: '#def5ff', label: 'color' },
  //   }),
  //   'shadow camera': folder({
  //     left: -7, min: -10, max: 10, step: 0.01 },
  //     right: 7, min: -10, max: 10, step: 0.01 },
  //     top: 8, min: -10, max: 10, step: 0.01 },
  //     bottom: -6, min: -10, max: 10, step: 0.01 },
  //     far: 20, min: 0, max: 50, step: 0.01 },
  //   }),
  // });

  const config = {
    helpers: true,
    intensityAmbient: 1.5,
    colorAmbient: '#ebffde',
    positionShadow: { x: -1.7, y: 1.6, z: 2 },
    intensityShadow: 2.6,
    colorShadow: '#def5ff',
    left: -7,
    right: 7,
    top: 8,
    bottom: -6,
    far: 20,
  };

  // useHelper(shadowLight, DirectionalLightHelper);
  // useHelper(camera, CameraHelper);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log(x);
    shadowLight.current.target.position.x = x * 0.4;
  }, [x]);

  useEffect(() => {
    // console.log(y);
    shadowLight.current.target.position.y = y * 0.2;
  }, [y]);

  /*
   * visuals
   */

  return (
    <>
      <ambientLight intensity={config.intensityAmbient} color={config.colorAmbient} />

      <directionalLight
        ref={shadowLight}
        position={[config.positionShadow.x, config.positionShadow.y, config.positionShadow.z]}
        intensity={config.intensityShadow}
        color={config.colorShadow}
        castShadow
      >
        <orthographicCamera
          attach="shadow-camera"
          ref={camera}
          far={config.far}
          top={config.top}
          bottom={config.bottom}
          left={config.left}
          right={config.right}
        />
      </directionalLight>

      {shadowLight && shadowLight.current && <primitive object={shadowLight.current.target} />}

      {/* <Environment
        background={false}
        path="./textures/environment/"
        files={[
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
        ]}
      /> */}
    </>
  );
}
