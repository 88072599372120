import { useControls } from 'leva';
import { Perf } from 'r3f-perf';

export function Staging() {
  /*
   * properties
   */

  // const config = useControls('staging cyber', {
  //   showPerf: { value: false, label: 'Perf panel' },
  //   bg: '#005c76',
  // });

  const config = {
    showPerf: false,
    bg: '#005c76',
  };

  // const showPerf = false;

  /*
   * visuals
   */

  return (
    <>
      <color attach="background" args={[config.bg]} />
      {config.showPerf && <Perf position="top-left" />}
    </>
  );
}
