import { Skull } from './skull/Skull';
import { Copy } from './copy/Copy';
import { ShadowPlane } from './staging/ShadowPlane';
import { Section } from '../../core/Section';
import { SectionCamera } from '../../components/SectionCamera';
import { useTheatre } from '../../../stores/useTheatre';
import { SheetProvider } from '@theatre/r3f';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Lighting } from './staging/Lighting';
import { PostProcessing } from './staging/PostProcessing';
import { Staging } from './staging/Staging';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import { useEffect } from 'react';
// import { Background } from './background/Background';
import { Dust } from '../../components/Dust/Dust';
import { Background } from '../../components/background/Background';
import * as THREE from 'three';
import { Primitive } from '../../components/primitives/Primitive';
import { Primitives } from '../../components/primitives/Primitives';
import { useSounds } from '../../../stores/useSounds';

export function Content() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const project = useTheatre((state) => state.project);
  const sheet = project.sheet('Work Section');

  let isTvShown = false;
  let isCopyShown = false;
  let isBigCopyShown = false;
  let isSkullShown = false;

  /*
   * hooks
   */

  // useEffect(() => {
  //   console.log('Work Content :: onMount');
  // })

  useFrame(() => {
    const scroll = useScrollPosition.getState().scrollPosition;
    sheet.sequence.position = Math.max(0, scroll) * 5.5;

    if (scroll > 0.05 && !isCopyShown) {
      isCopyShown = true;
      playSound('showElementSmall');
    } else if (scroll < 0.05 && isCopyShown) {
      isCopyShown = false;
    }

    if (scroll > 0.1 && !isBigCopyShown) {
      isBigCopyShown = true;
      playSound('showBig');
    } else if (scroll < 0.1 && isBigCopyShown) {
      isBigCopyShown = false;
      playSound('showBig');
    }

    if (scroll > 0.45 && !isSkullShown) {
      isSkullShown = true;
      playSound('showBig');
    } else if (scroll < 0.45 && isSkullShown) {
      isSkullShown = false;
    }

    if (scroll > 0.55 && !isTvShown) {
      isTvShown = true;
      playSound('show');
    } else if (scroll < 0.55 && isTvShown) {
      isTvShown = false;
      playSound('show');
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <SheetProvider sheet={sheet}>
        <Section>
          <Dust color="#c897ff" />
          <Skull />
          <Copy />
          <ShadowPlane />
          <Lighting />
        </Section>

        <Background
          color1={new THREE.Color(0.86, 0.0, 0.69)}
          color2={new THREE.Color(0.12, 0.0, 0.07)} // 33, 0, 20
          color3={new THREE.Color(0.06, 0.0, 0.25)}
          color4={new THREE.Color(0.48, 0.0, 0.88)}
        />
        <Primitives color="#87009b" section="work" />

        <SectionCamera section="work" />
      </SheetProvider>
    </>
  );
}
