import { ScrollControls } from '@react-three/drei';
import { Content } from './Content';
import { useEffect } from 'react';
import { useSection } from '../../../stores/useSection';

export function Home() {
  // console.log('Home -- render');

  /*
   * properties
   */

  const show = useSection((state) => state.show);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Home 3D :: onMount');
    show();
  }, []);

  /*
   * visuals
   */

  return (
    <>
      <Content />
    </>
  );
}
