import { useEffect, useRef, useState } from 'react';
import { editable as e } from '@theatre/r3f';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export function Laser({ index, position }) {
  /*
   * properties
   */

  const laserMaterial = useRef();
  const laser = useRef();

  /*
   * hooks
   */

  useFrame(() => {
    laser.current.scale.set(0.7 + Math.random() * 0.4, 0.997 + Math.random() * 0.012, 0.7 + Math.random() * 0.4);
    // laser.current.position.y = .485 + Math.random() * .03;
    laserMaterial.current.opacity = 0.55 + Math.random() * 0.12;
  });
  /*
   * visuals
   */

  return (
    <>
      <mesh
        position={[
          Math.sin(Math.PI * 2 * (index / 3)) * 0.176,
          0.4 + 0.15 * index,
          Math.cos(Math.PI * 2 * (index / 3)) * 0.176 + 0.112,
        ]}
        // rotation={[Math.cos(Math.PI * 2 * (index / 3)) * 0.04, 0, Math.sin(Math.PI * 2 * (index / 3)) * 0.04]}
        rotation={[0.45, 0, 0]}
        ref={laser}
      >
        <cylinderGeometry args={[0.1, 0.01, 8.6, 12, 1]} />
        <meshBasicMaterial
          ref={laserMaterial}
          color={[0.2 * 1.2, 2 * 1.2, 0.3 * 1.2]}
          transparent={true}
          blending={THREE.AdditiveBlending}
        ></meshBasicMaterial>
        {/* <meshBasicMaterial ref={laserMaterial} color={[6, 3, 1]} transparent={true} opacity={0.5} /> */}
      </mesh>
    </>
  );
}
