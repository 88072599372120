import { BigButton } from '../../component/BigButton';
import { SectionContent } from '../../component/SectionContent';
import { Scroller } from '../../component/Scroller';
// import style from './Home.module.css';

export function Home() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <Scroller label="home" />
      <SectionContent>
        <BigButton label="About" subLabel="//CONCERNING ME" topLabel="について" link="about" />
      </SectionContent>
    </>
  );
}
