import { useEffect } from 'react';
import { useMouse } from '../stores/useMouse';

export function MouseController() {
  /*
   * properties
   */
  const { setMousePosition, calculateSlowMousePosition, setMouseMaximums, initMousePosition, setTouch } = useMouse();

  let lastUpdate;

  /*
   * hooks
   */

  // start
  useEffect(() => {
    window.addEventListener('touchstart', touchHandler);
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('mousemove', handleMouseMove);

    const intervalId = setInterval(loop, 1000 / 60);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('touchstart', touchHandler);
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const touchHandler = (event) => {
    console.log('touchHandler');
    setTouch();

    handleTouchMove(event);
    // window.removeEventListener('touchstart', touchHandler);
  };

  // resize
  const resizeHandler = () => {
    const xMax = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const yMax = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    setMouseMaximums(xMax, yMax);
  };

  // mouse/touch move
  const handleTouchMove = (event) => {
    const { touches, changedTouches } = event.originalEvent ?? event;
    const touch = touches[0] ?? changedTouches[0];
    handleMove(touch.pageX, touch.pageY);
  };

  const handleMouseMove = (event) => {
    handleMove(event.clientX, event.clientY);
  };

  const handleMove = (x, y) => {
    if (!useMouse.getState().inited) {
      initMousePosition(window.innerWidth * 0.5, window.innerHeight * 0.5);
    } else {
      setMousePosition(x, y);
    }
  };

  const loop = (timestamp) => {
    if (useMouse.getState().inited) {
      var now = Date.now();
      var delta = now - lastUpdate;
      lastUpdate = now;

      if (delta) {
        // console.log(delta);
        calculateSlowMousePosition(delta);
      }
    }
  };
}
