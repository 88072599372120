import { useNavigate } from 'react-router-dom';
import { Skull } from './skull/Skull';
import { Lighting } from './staging/Lighting';
import { PostProcessing } from './staging/PostProcessing';
import { Staging } from './staging/Staging';
import { useEffect } from 'react';
import { useTheatre } from '../../../stores/useTheatre';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import { SheetProvider } from '@theatre/r3f';
import { Section } from '../../core/Section';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { SectionCamera } from '../../components/SectionCamera';
import { ShadowPlane } from './staging/ShadowPlane';
import { Background } from '../../components/background/Background';
import { Copy } from './copy/Copy';
import { Dust } from '../../components/Dust/Dust';
import * as THREE from 'three';
import { Primitives } from '../../components/primitives/Primitives';
import { useSection } from '../../../stores/useSection';
import { Intro } from './intro/Intro';
import { useSounds } from '../../../stores/useSounds';

export function Content() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const project = useTheatre((state) => state.project);
  const sheet = project.sheet('About Section');

  let isSkullShown = false;
  let isLaserShown = false;

  /*
   * hooks
   */

  useFrame(() => {
    const scroll = useScrollPosition.getState().scrollPosition;
    sheet.sequence.position = Math.max(0, scroll) * 7.0;

    if (scroll === 0 && !isLaserShown) {
      isLaserShown = true;
      playSound('showElementLoud');
    } else if (scroll > 0 && isLaserShown) {
      isLaserShown = false;
    }

    if (scroll > 0.05 && !isSkullShown) {
      isSkullShown = true;
      playSound('showElementSmall');
    } else if (scroll < 0.05 && isSkullShown) {
      isSkullShown = false;
      playSound('showElementSmall');
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <SheetProvider sheet={sheet}>
        <Dust color="#ffc85d" />
        <Intro />
        <Copy />
        <Skull />
        <Lighting />
        <ShadowPlane />
        <Background
          color1={new THREE.Color(1.0, 0.48, 0.0)}
          color2={new THREE.Color(0.22, 0.04, 0.0)}
          color3={new THREE.Color(0.22, 0.1, 0.0)}
          color4={new THREE.Color(1.0, 0.28, 0.0)}
        />
        <Primitives color="#fe9600" section="about" />

        <SectionCamera section="about" />
      </SheetProvider>
    </>
  );
}
