import { Route, Routes } from 'react-router-dom';

import { Work } from './sites/work/Work';
import { About } from './sites/about/About';
import { Info } from './sites/info/Info';
import { Home } from './sites/home/Home';
import { NotFound } from './sites/notfound/NotFound';
import { useEffect, useRef, useState } from 'react';

export function Pages() {
  //   console.log('Pages -- render');
  /*
   * properties
   */

  /*
   * hooks
   */

  // useEffect(() => {
  //   console.log('Pages :: onMount');
  // }, []);

  return (
    <>
      {/* {loaded && ( */}
        <>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/info" element={<Info />} />
          </Routes>
        </>
      {/* )} */}
    </>
  );
}
