import { Route, Routes, useLocation } from 'react-router-dom';

import { useEffect, useRef, useState } from 'react';
import { Home } from './sites/home/Home';
import { Work } from './sites/work/Work';
import { Edges } from './Edges';
import { Menu } from './component/Menu';
import { About } from './sites/about/About';
import { HireButton } from './component/HireButton';
import { Scroll } from './component/Scroll';
import { Layer } from './Layer';
import { MousePointer } from './shared/MousePointer';
import { HireModal } from './component/HireModal';
import { NotFound } from './sites/notfound/NotFound';
import { useSection } from '../stores/useSection';
// import style from './App2D.module.css';
import { Info } from './sites/info/Info';
import { SoundButton } from './component/SoundButton';

var r = document.querySelector(':root');
var rs = getComputedStyle(r);

export function App2D() {
  // console.log('App2D :: render');

  /*
   * properties
   */

  let location = useLocation();
  const setStartRoute = useSection((state) => state.setStartRoute);
  const setLocation = useSection((state) => state.setLocation);
  const isFirst = useRef(true);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('App2D :: onMount');
    let section = location.pathname.replace(/^\/|\/$/g, '');

    if (isFirst.current) {
      setStartRoute(section);
      isFirst.current = false;
    }

    if (section === '') {
      section = 'home';
    }

    setLocation(section);

    if (!useSection.getState().isKnownLocation) {
      section = 'default';
    }

    r.style.setProperty('--color-primary', rs.getPropertyValue(`--color-${section}-primary`));
    r.style.setProperty('--color-primary-invert', rs.getPropertyValue(`--color-${section}-primary-invert`));
    r.style.setProperty('--color-secondary', rs.getPropertyValue(`--color-${section}-secondary`));
    r.style.setProperty('--color-secondary-invert', rs.getPropertyValue(`--color-${section}-secondary-invert`));
    // }, 100);
  }, [location]);

  /*
   * visuals
   */

  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/work" element={<Work />} />
        <Route path="/about" element={<About />} />
        <Route path="/info" element={<Info />} />
      </Routes>

      <HireModal />
      <MousePointer />

      <Layer size={0.0065}>
        <header>
          <Menu />
          <HireButton />
          {/* <SoundButton /> */}
        </header>
        <Scroll />
      </Layer>

      <Layer size={0.003}>
        <Edges />
      </Layer>
    </>
  );
}
