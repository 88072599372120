import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { editable as e } from '@theatre/r3f';

import { useGLTF, useKTX2, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { Halo } from './Halo';
import { StampDecal } from './StampDecal';
import { Eye } from './Eye';
import { Nose } from './Nose';
import { Neck } from './Neck';
import { MainDecal } from '../../../components/MainDecal';
import { useMaps } from '../../../../stores/useMaps';
import { useMouse } from '../../../../stores/useMouse';
import { useScrollPosition } from '../../../../stores/useScrollPosition';
import { useRotation } from '../../../../stores/useRotation';
import { Laser } from './Laser';

export function Skull() {
  /*
   * properties
   */

  const inner = useRef();

  const setRotation = useRotation((state) => state.setRotation);
  const rotationSpeedPct = useRef(0);

  // const { scratches, stamp, skullColor, skullNormal } = useMaps();

  const { scratches, stamp, skullNormal } = useMemo(() => {
    return {
      scratches: useMaps.getState().scratches,
      stamp: useMaps.getState().stamp,
      skullNormal: useMaps.getState().skullNormal,
    };
  }, []);

  // const [scratches, stamp, skullNormal, skullColor] = useKTX2([
  //   'textures/shared/scratches.ktx2',
  //   'textures/shared/stamp.ktx2',
  //   'textures/shared/skullNormal.ktx2',
  //   'textures/shared/skullColor.ktx2',
  // ]);

  const scratch = scratches.clone();
  scratch.wrapS = THREE.MirroredRepeatWrapping;
  scratch.wrapT = THREE.MirroredRepeatWrapping;
  scratch.repeat.set(3, 3);

  // const materialConfig = useControls('skull material cyber', {
  //   metallness: { value: 1.0, min: 0, max: 1, step: 0.01 },
  //   roughness: { value: 0.78, min: 0, max: 1, step: 0.01 },
  //   envMapIntensity: { value: 0.4, min: 0, max: 10, step: 0.01 },
  //   normalScale: { value: 1, min: -3, max: 3, step: 0.01 },
  //   color: { value: '#104d72' },
  // });

  const materialConfig = {
    metallness: 1.0,
    roughness: 0.78,
    envMapIntensity: 0.4,
    normalScale: 1,
    color: '#104d72',
  };

  // const model = useGLTF('models/skull/Human_Skull.gltf');
  const model = useMaps.getState().skull;

  /*
   * hooks
   */

  //move camera on mouse move
  useFrame((state, delta) => {
    const { slowPctX, slowPctY } = useMouse.getState();
    let rotation = useRotation.getState().rotation;

    if (useScrollPosition.getState().scrollPosition < 0.98) {
      rotation += (0 - rotation) * 0.05;
      rotationSpeedPct.current = 0;
    } else {
      rotationSpeedPct.current += (1 - rotationSpeedPct.current) * 0.01;

      const rotationSpeed = delta * 0.5;
      rotation += rotationSpeed * rotationSpeedPct.current;

      if (rotation > Math.PI) {
        rotation -= Math.PI * 2;
      }
    }

    inner.current.rotation.y = rotation;
    inner.current.rotation.x = slowPctY * 0.08 + 0.15;

    setRotation(rotation);
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group theatreKey="skull" rotation={[0, 0, 0]} position={[0, -0.4, 0]}>
        <group ref={inner}>
          <mesh geometry={model.nodes.skull.geometry} scale={[0.35, 0.35, 0.35]} rotation={[-1.571, 0, 0]} castShadow>
            <meshStandardMaterial
              color={materialConfig.color}
              metalnessMap={scratch}
              metalness={materialConfig.metallness}
              roughness={materialConfig.roughness}
              envMapIntensity={materialConfig.envMapIntensity}
              normalMap={skullNormal}
              normalScale={[materialConfig.normalScale, materialConfig.normalScale]}
            />

            <MainDecal size="square" section="cyber" decal="beehive" name="forehead" />

            <MainDecal size="square" section="cyber" decal="arrow" name="top1" />
            <MainDecal size="square" section="cyber" decal="triangle" name="top2" />
            <MainDecal size="square" section="cyber" decal="arrow" name="eyeTopLeft" />
            <MainDecal size="square" section="cyber" decal="arrow" name="eyeBottomLeft" />
            <MainDecal size="square" section="cyber" decal="arrow" name="eyeTopRight" />
            <MainDecal size="square" section="cyber" decal="arrow" name="eyeBottomRight" />
            <MainDecal size="square" section="cyber" decal="arrow" name="noseLeft" />
            <MainDecal size="square" section="cyber" decal="arrow" name="noseRight" />

            <MainDecal size="long" section="cyber" decal="barcode" name="barcode" />
            <MainDecal size="long" section="cyber" decal="num32" name="32" />
            <MainDecal size="long" section="cyber" decal="text" name="text" />

            <StampDecal texture={stamp} />
          </mesh>

          <Eye left={true} />
          <Eye left={false} />

          <Nose left={true} />
          <Nose left={false} />

          <Laser index={0} />
          <Laser index={1} />
          <Laser index={2} />

          <Neck />
        </group>
      </e.group>
    </>
  );
}
