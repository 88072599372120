import { useEffect, useRef, useState } from 'react';
import style from './HireModal.module.css';
import gsap from 'gsap';
import { HireModalButton } from './HireModalButton';
import SVG from '../shared/svg/X.svg?react';
import { useHireModal } from '../../stores/useHireModal';
import FocusTrap from 'focus-trap-react';
import { useSection } from '../../stores/useSection';
import { Layer } from '../Layer';
import { useSounds } from '../../stores/useSounds';
import { useMouse } from '../../stores/useMouse';

export function HireModal() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const isTouch = useMouse((state) => state.isTouch);

  const win = useRef();
  const body = useRef();
  const background = useRef();

  const active = useRef(false);

  const transition = useSection((state) => state.transition);
  const isShown = useHireModal((state) => state.isShown);
  const hide = useHireModal((state) => state.hide);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log(window)
    window.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  useEffect(() => {
    hide();
  }, [transition]);

  useEffect(() => {
    if (!active.current && isShown) {
      // console.log("SHOW MODAL")
      active.current = true;
      setVisibility(true);
    }
    setTimeout(() => {
      playSound('modal');
    }, [100]);
  }, [isShown]);

  const setVisibility = (visible) => {
    // console.log('setVisibility, visible: ' + visible);
    let elements = Array.from(body.current.children);
    elements = elements.sort(function () {
      return 0.5 - Math.random();
    });

    let children = Array.from(win.current.children).concat(elements);

    gsap.set(children, { opacity: visible ? 0 : 1 });
    gsap.to(children, { opacity: visible ? 1 : 0, duration: 0.01, stagger: 0.05 });

    gsap.set(background.current, { opacity: visible ? 0 : 1 });
    gsap.to(background.current, {
      opacity: visible ? 0.85 : 0,
      duration: 0.4,
      onComplete: setVisibilityHandler,
      ease: 'power2.out',
    });
  };

  const setVisibilityHandler = () => {
    if (!active.current) {
      playSound('modal');
      hide();
    }
  };

  const onCloseOver = () => {
    // console.log('onCloseOver');
    // console.log(isTouch);
    if (!isTouch) {
      playSound('over');
    }
  };

  const closeHandler = () => {
    if (active.current) {
      playSound('thud');
      active.current = false;
      setVisibility(false);
    }
  };

  const handleEscapeKeyPress = (event) => {
    if (event.key === 'Escape') {
      closeHandler();
    }
  };

  const showMail = () => {
    // console.log('showMail');
    const mailToLink = 'mailto:piet@sector32.net';
    window.location.href = mailToLink;
  };

  /*
   * visuals
   */

  return (
    <>
      {isShown && (
        <div className={style.modal}>
          <Layer relative size={0.006}>
            <FocusTrap initialFocus={null}>
              <div className={style.window} ref={win}>
                <Layer relative size={0.003}>
                  <h1 className={style.title}>HIRE ME</h1>
                </Layer>
                <Layer relative size={0.0045}>
                  <p className={style.body} ref={body}>
                    <span className={style.stripe1} />
                    <span className={style.stripe2} />
                    <span>I am always on the lookout</span>
                    <br />
                    <span>for</span> <span className={style.fat}>fresh opportunities</span>
                    <br />
                    <span>and</span> <span className={style.fat}>creative projects</span>
                    <span>.</span>
                    <br />
                    <br />
                    <span>Are you interested in</span> <span className={style.fat}>collaborating</span>
                    <span>?</span>
                  </p>
                </Layer>
                <button onClick={closeHandler} onMouseEnter={onCloseOver} className={style.close}>
                  <SVG />
                </button>
                <Layer relative size={0.006}>
                  <HireModalButton onClick={showMail} />
                </Layer>
              </div>
            </FocusTrap>
          </Layer>

          <div className={style.background} ref={background} />
        </div>
      )}
    </>
  );
}
