import { useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';

import { MeshTransmissionMaterial, useGLTF, useKTX2 } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { Eye } from './Eye';
import { Mohawk } from './Mohawk';
import { Grill } from './Grill';
import { StampDecal } from './StampDecal';
import { MainDecal } from '../../../components/MainDecal';
import { useMaps } from '../../../../stores/useMaps';
import { editable as e } from '@theatre/r3f';
import { Panel } from '../panel/Panel';
import { useMouse } from '../../../../stores/useMouse';
import { useScrollPosition } from '../../../../stores/useScrollPosition';
import { useSounds } from '../../../../stores/useSounds';

export function Skull() {
  /*
   * properties
   */

  // const { scratches, stamp, skullColor } = useMaps();

  const { scratches, stamp, skullColor } = useMemo(() => {
    return {
      scratches: useMaps.getState().scratches,
      stamp: useMaps.getState().stamp,
      skullColor: useMaps.getState().skullColor,
    };
  }, []);

  // const [scratches, stamp, skullNormal, skullColor] = useKTX2([
  //   'textures/shared/scratches.ktx2',
  //   'textures/shared/stamp.ktx2',
  //   'textures/shared/skullNormal.ktx2',
  //   'textures/shared/skullColor.ktx2',
  // ]);

  const [theatreObject, setTheatreObject] = useState(null);

  const wrapper = useRef();

  const skull = useRef();
  const transparentMaterial = useRef();

  const inner = useRef();
  const innerMaterial = useRef();

  const skullRotation = useRef(0);
  const rotationSpeedPct = useRef(0);

  const playSound = useSounds((state) => state.playSound);
  const isTransparentShown = useRef(false);

  const scratch = scratches.clone();
  scratch.wrapS = THREE.MirroredRepeatWrapping;
  scratch.wrapT = THREE.MirroredRepeatWrapping;
  scratch.repeat.set(3, 3);

  // const materialConfig = useControls('glass material', {
  //   transmissionSampler: false,
  //   backside: false,
  //   samples: { value: 5, min: 1, max: 32, step: 1 },
  //   resolution: { value: 1024, min: 256, max: 2048, step: 256 },
  //   transmission: { value: 1.0, min: 0, max: 1 },
  //   roughness: { value: 1.5, min: 0, max: 5, step: 0.01 },

  //   thickness: { value: 5.2, min: 0, max: 10, step: 0.01 },
  //   ior: { value: 1.15, min: 1, max: 5, step: 0.01 },

  //   chromaticAberration: { value: 0.5, min: 0, max: 1 },
  //   anisotropy: { value: 0, min: 0, max: 1, step: 0.01 },
  //   distortion: { value: 0.5, min: 0, max: 1, step: 0.01 },
  //   distortionScale: { value: 0.5, min: 0.01, max: 1, step: 0.01 },
  //   temporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
  //   envMapIntensity: { value: 1, min: 0, max: 1, step: 0.01 },
  //   clearcoat: { value: 1.0, min: 0, max: 1, step: 0.01 },
  //   clearcoatRoughness: { value: 0.2, min: 0, max: 1, step: 0.01 },
  //   attenuationDistance: { value: 1.3, min: 0, max: 2, step: 0.01 },

  //   attenuationColor: '#afffe3',
  //   color: '#ffc9fc',
  //   bg: '#005073',
  // });

  const materialConfig = {
    transmissionSampler: false,
    backside: false,
    samples: 6,
    resolution: 1024,
    // transmission: .98,
    roughness: 2,

    thickness: 6,
    ior: 1.2,

    // chromaticAberration: .5,
    anisotropy: 0.5,
    distortion: 0.5,
    distortionScale: 0.5,
    temporalDistortion: 0,
    envMapIntensity: 0.8,
    clearcoat: 1.0,
    clearcoatRoughness: 0.2,
    // attenuationDistance: 1.3, iOS probleem

    attenuationColor: '#afffe3',
    color: '#ffc9fc',
    bg: '#005073',
  };

  // const innerConfig = useControls('inner skull', {
  //   position: { value: { x: 0, y: 0.35, z: -0.34 }, min: -2, max: 2, step: 0.01 },
  //   rotation: { value: { x: -1.4, y: 0, z: 0 }, min: -2, max: 2, step: 0.01 },
  //   scale: { value: { x: 0.21, y: 0.18, z: 0.17 }, min: -2, max: 2, step: 0.01 },
  // });

  const innerConfig = {
    position: { x: 0, y: 0.35, z: -0.34 },
    rotation: { x: -1.4, y: 0, z: 0 },
    scale: { x: 0.21, y: 0.18, z: 0.17 },
  };

  // const model = useGLTF('models/skull/Human_Skull.gltf');
  const model = useMaps.getState().skull;

  /*
   * hooks
   */

  // useEffect(() => {
  //   // show();

  //   console.log('JA');

  //   return () => {};
  // });

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        transparentMaterial.current.opacity = data.transparentMaterial;
        innerMaterial.current.opacity = data.innerMaterial;
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  useFrame((state, delta) => {
    const { slowPctX, slowPctY } = useMouse.getState();

    if (useScrollPosition.getState().scrollPosition < 0.98) {
      skullRotation.current += (0 - skullRotation.current) * 0.05;
      rotationSpeedPct.current = 0;
    } else {
      rotationSpeedPct.current += (1 - rotationSpeedPct.current) * 0.01;

      const rotationSpeed = delta * 0.5;
      skullRotation.current += rotationSpeed * rotationSpeedPct.current;

      if (skullRotation.current > Math.PI) {
        skullRotation.current -= Math.PI * 2;
      }
    }

    wrapper.current.rotation.y = slowPctX * 0.022 + skullRotation.current;
    wrapper.current.rotation.x = slowPctY * 0.1;

    const opacity = transparentMaterial.current.opacity;
    if (opacity > 0.07 && !isTransparentShown.current) {
      isTransparentShown.current = true;
      playSound('menu');
    } else if (opacity < 0.07 && isTransparentShown.current) {
      isTransparentShown.current = false;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group
        theatreKey="skull"
        ref={skull}
        dispose={null}
        scale={1.4}
        position={[0, -0.6, 0]}
        additionalProps={{
          transparentMaterial: 1,
          innerMaterial: 1,
        }}
        objRef={setTheatreObject}
      >
        <group ref={wrapper}>
          <mesh geometry={model.nodes.skull.geometry} scale={[0.25, 0.25, 0.25]} rotation={[-1.571, 0, 0]} castShadow>
            <MeshTransmissionMaterial
              ref={transparentMaterial}
              background={new THREE.Color(materialConfig.bg)}
              {...materialConfig}
              roughnessMap={scratch}
              transparent={true}
              opacity={1}
              // side={THREE.DoubleSide}
            />

            <MainDecal size="square" section="transparent" decal="quad" name="forehead" />
            <MainDecal size="long" section="transparent" decal="barcode" name="barcode" />
          </mesh>

          <e.group ref={inner} theatreKey="skullInner">
            <mesh
              geometry={model.nodes.skull.geometry}
              material={model.nodes.skull.material}
              position={[innerConfig.position.x, innerConfig.position.y, innerConfig.position.z]}
              rotation={[innerConfig.rotation.x, innerConfig.rotation.y, innerConfig.rotation.z]}
              scale={[innerConfig.scale.x, innerConfig.scale.y, innerConfig.scale.z]}
            >
              <StampDecal texture={stamp} />
              <meshStandardMaterial transparent={true} ref={innerMaterial} map={skullColor} />
            </mesh>
          </e.group>

          <Mohawk index={0} topY={2.11} />
          <Mohawk index={1} topY={2.06} />
          <Mohawk index={2} topY={1.84} />

          <Eye positionX={-0.75} rotationZ={-0.12} index={0} />
          <Eye positionX={0.75} rotationZ={0.12} index={1} blink />

          <Panel
            index={0}
            text={["Hi, I'm Piet,", 'independent creative', 'front-end web developer']}
            position={[2.2, 1, -1.5]}
            rotation={[0.1, 1.2, 0]}
          />

          <Panel
            index={1}
            text={["You're welcome", 'to poke around', 'in my brain']}
            position={[3.2, 1, 0]}
            rotation={[0.1, 0.3, 0]}
          />

          <Panel
            index={2}
            text={['Take a deep dive', 'in my cranium.', 'Dum diddy dum']}
            position={[-1, 1, 1]}
            rotation={[0.1, 0.3, 0]}
          />

          {/* <Grill pct={-1} positionX={-0.2} positionZExtra={-0.1} index={0} />
        <Grill pct={0} positionX={0} positionZExtra={0} index={1} />
        <Grill pct={1} positionX={0.2} positionZExtra={-0.1} index={2} /> */}
        </group>
      </e.group>
    </>
  );
}
