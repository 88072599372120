import { Html } from '@react-three/drei';
import { useControls } from 'leva';
import { useEffect, useRef, useState } from 'react';
import style from './Intro.module.css';
import gsap from 'gsap';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import { useSection } from '../../../stores/useSection';
import { useSounds } from '../../../stores/useSounds';

export function Intro() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);

  const timeoutId = useRef();
  const tween = useRef();
  const [visible, setVisible] = useState(true);

  const wrapper = useRef();
  const top = useRef();
  const line1 = useRef();
  const line2 = useRef();
  const paragraph1 = useRef();
  const paragraph2 = useRef();
  const paragraph3 = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    // console.log('Intro :: change transition');
    // console.log('transition: ' + transition);
    if (transition === 'show') {
      show();
    }
  }, [transition]);

  const show = () => {
    // console.log('Intro :: show');

    gsap.set(top.current.children, { opacity: 0, x: window.innerWidth * 0.05 });
    gsap.set(line1.current, { opacity: 0, y: window.innerHeight * 0.02 });
    gsap.set(line2.current, { opacity: 0, y: -window.innerHeight * 0.02 });
    gsap.set(paragraph1.current.children, { scale: 0 });
    gsap.set(paragraph2.current.children, { scale: 0 });
    gsap.set(paragraph3.current.children, { scale: 0 });

    timeoutId.current = setTimeout(showDelayed, 2000);

    tween.current = gsap.to(wrapper.current.children, {
      duration: 0.4,
      rotateX: -70,
      y: -window.innerHeight * 0.7,
      opacity: 0,
      stagger: 0.01,
      ease: 'power2.in',
      paused: true,
    });

    loop();
  };

  const showDelayed = () => {
    // console.log('Intro :: showDelayed');

    if (wrapper.current && top.current && top.current.children && paragraph1.current) {
      playSound('show');

      gsap.set(wrapper.current, { opacity: 1 });

      gsap.to(top.current.children, { duration: 0.5, stagger: 0.1, x: 0, opacity: 1, ease: 'power4.out' });
      gsap.to(line1.current, { delay: 0.5, duration: 0.5, y: 0, opacity: 1, ease: 'power4.out' });
      gsap.to(line2.current, { delay: 0.5, duration: 0.5, y: 0, opacity: 1, ease: 'power4.out' });

      showParagraph(paragraph1.current, 0.6);
      showParagraph(paragraph2.current, 0.7);
      showParagraph(paragraph3.current, 0.8);
    }
  };

  const showParagraph = (paragraph, delay) => {
    gsap.to(paragraph.children, {
      delay,
      duration: 0.1,
      stagger: 0.03,
      scale: 1,
      onStart: () => {
        playSound('generalButton');
      },
      ease: 'power4.out',
    });
  };

  const loop = () => {
    let scrollPosition = Math.min(1, useScrollPosition.getState().scrollPosition * 30);

    tween.current.progress(scrollPosition);

    setVisible(scrollPosition < 1);

    requestAnimationFrame(loop);
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={`${style.wrapper} ${visible ? style.visible : ''}`} ref={wrapper}>
        <div className={style.top} ref={top}>
          <div className={style.column}>
            <h1 className={style.title}>ABOUT</h1>
            <p className={style.subLabel}>について&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div className={style.bigStripe} />
          <div className={style.column}>
            <div className={style.smallStripe} />
            <h1 className={style.info}>SECTOR</h1>
            <p className={style.subLabel}>セクタ</p>
          </div>
          <div className={style.column}>
            <div className={style.smallStripe} />
            <h1 className={style.info}>32</h1>
            <p className={style.subLabel}>&nbsp;</p>
          </div>
        </div>
        <div className={style.line1} ref={line1} />
        <p ref={paragraph1} className={style.copy}>
          {'I like to create'.split('').map((char, index) => (
            <span key={index}>{char}</span>
          ))}
        </p>
        <p ref={paragraph2} className={style.copy}>
          {'digital experiences'.split('').map((char, index) => (
            <span key={index}>{char}</span>
          ))}
        </p>
        <p ref={paragraph3} className={style.copy}>
          {'with a soul'.split('').map((char, index) => (
            <span key={index}>{char}</span>
          ))}
        </p>
        <div className={style.line2} ref={line2} />
      </div>
    </>
  );
}
