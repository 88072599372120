import { useEffect, useRef } from 'react';
import style from './Cross.module.css';
import SVG from './svg/cross.svg?react';

export function Cross() {
  /*
   * properties
   */

  const container = useRef();
  let points;
  let pointer = 0;

  /*
   * hooks
   */

  useEffect(() => {
    points = Array.from(container.current.querySelectorAll('#points > *'));

    const intervalId = setInterval(render, 910);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const render = () => {
    points[pointer].style.visibility = 'visible';

    pointer++;
    if (pointer >= points.length) {
      pointer = 0;
    }

    points[pointer].style.visibility = 'hidden';
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.cross} ref={container}>
        <SVG />
      </div>
    </>
  );
}
