import { useEffect, useRef, useState } from 'react';
import style from './ContentItem.module.css';
import { useProjects } from '../../../stores/useProjects';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import parse from 'html-react-parser';

export function ContentItem({ index, project, amount }) {
  /*
   * properties
   */

  const container = useRef();
  const activeProject = useProjects((state) => state.activeProject);
  const setScrollTo = useScrollPosition((state) => state.setScrollTo);

  /*
   * hooks
   */

  useEffect(() => {
    container.current.style.transform = `rotateY(0deg)`;
    container.current.style.opacity = 1;
  }, []);

  useEffect(() => {
    // console.log('activeProject: ' + activeProject);

    if (activeProject === -1) {
      container.current.style.transform = `rotateY(0deg)`;
      container.current.style.opacity = 1;
    } else {
      const rot = Math.min(60, (activeProject - index) * 20);
      container.current.style.transform = `rotateY(-${rot}deg)`;
      container.current.style.opacity = 1 + (index - activeProject) * 0.5;
    }
  }, [activeProject]);

  const clickHandler = () => {
    // console.log('clickHandler');
    // onClick(index);

    if (container.current.style.opacity >= 0.5) {
      const pct = index / amount;
      // setScrollTo(0.62 + index * 0.04);
      setScrollTo(0.62 + pct * 0.28);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={`${style.left} ${activeProject === index ? style.shown : ''}`}></div>
      <div
        ref={container}
        onClick={clickHandler}
        className={`${style.line} ${project.site ? style.big : ''} ${activeProject === index ? style.active : ''}`}
      >
        <p className={style.title}>{project.title}</p>
        <p className={style.info}>{parse(project.info)}</p>
        <p className={style.body}>
          <span className={style.description}>Tasks&nbsp;&nbsp;&gt; </span>
          {project.tasks}
        </p>
        {project.site && (
          <p className={style.body}>
            <span className={style.description}>Link&nbsp;&nbsp;&gt; </span>
            <a tabIndex={activeProject === index ? '' : -1} href={project.url} target="_blank" className={style.link}>
              {project.site}{' '}
            </a>
          </p>
        )}
      </div>
    </>
  );
}
