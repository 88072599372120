import { useSection } from '../../../stores/useSection';
import { useEffect } from 'react';

export function NotFound() {
  console.log('NotFound -- render');

  /*
   * properties
   */

  const show = useSection((state) => state.show);

  /*
   * hooks
   */

  useEffect(() => {
    console.log('NotFound 3D :: onMount');
    show();
  }, []);

  /*
   * visuals
   */

  return <></>;
}
