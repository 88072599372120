import { useEffect, useMemo, useRef, useState } from 'react';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import style from './Intro.module.css';
import * as THREE from 'three';

import gsap from 'gsap';
import { useSection } from '../../../stores/useSection';
import { useSounds } from '../../../stores/useSounds';

const copy = '+ + ALL WORK/AND:MUCH PLAY';

export function Intro() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);
  const container = useRef();
  const paragraph = useRef();
  const tween = useRef();
  const [visible, setVisible] = useState(true);

  const colorLight = new THREE.Color('#fff15a');
  const colorDark = new THREE.Color('#ffa200');

  // const timeoutId = useRef();
  // const [rnd, setRnd] = useState(0.05);

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      show();
      loop();
    }
  }, [transition]);

  const show = () => {
    // console.log("Intro :: show");
    gsap.set(container.current.children, { transformOrigin: '100% 100%' });

    gsap.killTweensOf(container.current.children);

    tween.current = gsap.to(container.current.children, {
      duration: 1,
      rotateY: -120,
      opacity: 0,
      stagger: -0.075,
      ease: 'back.in',
      paused: true,
    });

    // let delay = 2;
    let index = 0;

    for (const child of container.current.children) {
      // console.log(child.children);
      // gsap.killTweensOf(child);
      // console.log(window.height)
      let delay = 2 + index * 0.1;

      // playSound('generalButton');

      gsap.set(child, { scaleX: 0, x: 20, y: 20, rotateX: -200, rotateZ: 8 });
      gsap.to(child, { scaleX: 1, duration: 0.5, delay: delay, ease: 'sine.out' });
      gsap.to(child, { x: 0, y: 0, duration: 0.4, delay: delay, ease: 'sine.in' });
      gsap.to(child, { rotateX: 0, rotateZ: 0, duration: 0.8, delay: delay, ease: 'back.out' });

      gsap.killTweensOf(child.children);
      gsap.set(child.children, { scale: 0 });
      gsap.to(child.children, {
        delay,
        duration: 0.05,
        scale: 1,
        stagger: -0.04,
        onStart: () => {
          playSound('generalButton');
        },
        ease: 'sine.out',
      });

      delay += 0.1;

      index++;
    }
  };

  const loop = () => {
    if (tween.current) {
      let scrollPosition = Math.min(1, useScrollPosition.getState().scrollPosition * 9);

      tween.current.progress(scrollPosition);

      setVisible(scrollPosition < 1);

      requestAnimationFrame(loop);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.intro} ${visible ? style.visible : ''}`}>
        {[...Array(8)].map((value, index) => (
          <p
            ref={paragraph}
            className={style.line}
            key={index}
            style={{
              color: '#' + colorLight.lerp(colorDark, index / 7).getHexString(),
              fontSize: `min(${4.5 - index * 0.2}vw, ${4.9 - index * 0.2}vh)`,
            }}
          >
            {copy.split('').map((char, index2) => (
              <span key={index2}>{char}</span>
            ))}
          </p>
        ))}
        <p className={style.bottom}>
          makes me <span className={style.bold}>*happy*</span>
        </p>
      </div>
    </>
  );
}
