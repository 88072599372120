import { Decal, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { useMaps } from '../../../../stores/useMaps';

export function BulgeDecal({ decal, flipped, opacity }) {
  /*
   * properties
   */

  const colorOn = new THREE.Color(2, 2, 1);
  const colorOff = new THREE.Color(0.42, 0.42, 0.42);

  // const texture = useTexture(`/textures/text/${decal}.png`);

  const texture = useMemo(() => {
    return useMaps.getState()[decal];
  }, []);

  // const { debug, metallness, roughness, envMapIntensity } = useControls('Bulge Decal Material', {
  //   debug: { value: false },
  //   metallness: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   roughness: { value: 0.3, min: 0, max: 1, step: 0.01 },
  //   envMapIntensity: { value: 0, min: 0, max: 10, step: 0.01 },
  // });

  const { debug, metallness, roughness, envMapIntensity } = {
    debug: false,
    metallness: 0.0,
    roughness: 0.3,
    envMapIntensity: 0,
  };

  const props = {};

  if (flipped) {
    props.rotation = { x: 0, y: -3.14, z: 1.57 };
  } else {
    props.rotation = { x: 0, y: 0, z: -1.57 };
  }

  // const { scale, position, rotation } = useControls('Bulge Decal Mesh', {
  //   scale: { value: { x: 1.6, y: 0.4, z: 0.5 }, min: 0, max: 20, step: 0.01 },
  //   position: { value: { x: 0, y: 0, z: 0.4 }, min: -8, max: 8, step: 0.01 },
  // });

  const { scale, position, rotation } = {
    scale: { x: 1.6, y: 0.4, z: 0.5 },
    position: { x: 0, y: 0, z: 0.4 },
  };

  const material = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    material.current.color.lerpColors(colorOff, colorOn, opacity);
  }, [opacity]);

  /*
   * visuals
   */

  return (
    <>
      <Decal
        // mesh={mesh}
        debug={debug}
        position={[position.x, position.y, position.z]}
        rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
        scale={[scale.x, scale.y, scale.z]} // Scale of the decal
      >
        <meshStandardMaterial
          polygonOffset={true}
          polygonOffsetUnits={-10}
          ref={material}
          alphaMap={texture}
          transparent={true}
          // opacity={1}
          metallness={metallness}
          roughness={roughness}
          envMapIntensity={envMapIntensity}
          color={colorOff}
        />
      </Decal>
    </>
  );
}
