import { useEffect, useRef } from 'react';
import { Billboard, RoundedBox, shaderMaterial } from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import gsap from 'gsap';
import * as THREE from 'three';

export function Primitive({ material, index, section }) {
  /*
   * properties
   */

  const mesh = useRef();
  const speed = useRef();
  const scale = useRef(0);
  const z = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    setPosition();

    return () => {
      gsap.killTweensOf(scale);
      gsap.killTweensOf(z);
    };
  }, []);

  useFrame((state, delta) => {
    mesh.current.rotation.x += delta * 2;
    mesh.current.rotation.y += delta * 1;
    mesh.current.position.x += speed.current.x;
    mesh.current.position.y += speed.current.y;
    mesh.current.position.z = z.current;

    mesh.current.scale.set(scale.current, scale.current, scale.current);
    // console.log(scale.current);
  });

  const setPosition = () => {
    if (mesh.current) {
      speed.current = new THREE.Vector2(-0.015 + Math.random() * 0.03, -0.015 + Math.random() * 0.03);
      mesh.current.position.set(-15 + Math.random() * 30, -15 + Math.random() * 30, 0);
      // mesh.current.position.set(-15, 0, 0);
      mesh.current.rotation.set(Math.random() * 8, Math.random() * 8, Math.random() * 8);

      scale.current = 0;
      z.current = 0;
      const duration = 4 + Math.random() * 4;

      gsap.to(scale, { current: 1, duration: 0.5, ease: 'power4.out' });
      gsap.to(scale, { current: 0, delay: 0.5, duration: duration - 0.5, ease: 'power4.in' });
      gsap.to(z, { current: -2, duration: duration, ease: 'linear.none', onComplete: setPosition });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      {section === 'work' && (
        <RoundedBox
          ref={mesh}
          args={[1.3, 0.9, 0.2]} // Width, height, depth. Default is [1, 1, 1]
          radius={0.05} // Radius of the rounded corners. Default is 0.05
          smoothness={2} // The number of curve segments. Default is 4
          bevelSegments={2} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
          creaseAngle={0.5} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
          material={material}
        ></RoundedBox>
      )}

      {section === 'about' && (
        <mesh ref={mesh} material={material}>
          <capsuleGeometry args={[0.4, 0.9, 4, 8]} />
        </mesh>
      )}

      {section === 'info' && (
        <mesh ref={mesh} material={material}>
          {/* <icosahedronGeometry args={[1, 0]} /> */}
          <sphereGeometry args={[0.6, 5, 5]} />
        </mesh>
      )}

      {section === 'home' && (
        <mesh ref={mesh} material={material}>
          <coneGeometry args={[0.8, 1, 3, 1]} />
        </mesh>
      )}
    </>
  );
}
