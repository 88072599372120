import { ScrollControls } from '@react-three/drei';
import { Content } from './Content';
import { useSection } from '../../../stores/useSection';
import { useEffect } from 'react';

export function Work() {
  /*
   * properties
   */

  const show = useSection((state) => state.show);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Work 3D :: onMount');
    show();
  }, []);

  /*
   * visuals
   */

  return (
    <>
      <Content />
    </>
  );
}
