import { Decal } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useRef } from 'react';
import * as THREE from 'three';

export function GogglesDecal({ mesh, part }) {
  /*
   * properties
   */

  // const { debug, metallness, roughness, envMapIntensity } = useControls('Goggles Decal Material', {
  //   debug: { value: false },
  //   metallness: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   roughness: { value: 0.3, min: 0, max: 1, step: 0.01 },
  //   envMapIntensity: { value: 0, min: 0, max: 10, step: 0.01 },
  // });

  const { debug, metallness, roughness, envMapIntensity } = {
    debug: false,
    metallness: 0.0,
    roughness: 0.3,
    envMapIntensity: 0,
  };

  const props = {};

  if (part === 'l1' || part === 'l2' || part === 'l3' || part === 'l4') {
    props.scale = { x: 0.1, y: 0.65, z: 0.1 };
    props.rotation = { x: 0, y: 0, z: 0 };
  } else if (part === 'x1' || part === 'x2') {
    props.scale = { x: 0.1, y: 0.8, z: 0.1 };
  } else if (part === 'd1') {
    props.scale = { x: 0.3, y: 0.1, z: 0.1 };
    props.position = { x: -0.03, y: 0, z: 0.76 };
    props.rotation = { x: 0, y: 0, z: 0 };
  }

  if (part === 'l1') {
    props.position = { x: 0.4, y: 0, z: 0.76 };
  } else if (part === 'l2') {
    props.position = { x: 0.6, y: 0, z: 0.76 };
  } else if (part === 'l3') {
    props.position = { x: 0.8, y: 0, z: 0.76 };
  } else if (part === 'l4') {
    props.position = { x: 1.1, y: 0, z: 0.76 };
  }

  if (part === 'x1') {
    props.position = { x: -0.65, y: 0, z: 0.76 };
    props.rotation = { x: 0, y: 0, z: -0.8 };
  } else if (part === 'x2') {
    props.position = { x: -0.65, y: 0, z: 0.76 };
    props.rotation = { x: 0, y: 0, z: 0.8 };
  }

  // const { scale, position, rotation } = useControls(part, {
  //   scale: { value: props.scale, min: 0, max: 20, step: 0.01 },
  //   position: { value: props.position, min: -8, max: 8, step: 0.01 },
  //   rotation: { value: props.rotation, min: -8, max: 8, step: 0.01 },
  // });

  const { scale, position, rotation } = {
    scale: props.scale,
    position: props.position,
    rotation: props.rotation,
  };

  const material = useRef();
  let col = 0;
  let nextTime = 0;

  /*
   * hooks
   */

  useFrame((state) => {
    const time = state.clock.elapsedTime;

    if (time > nextTime) {
      nextTime = time + 2 + Math.random() * 3;

      col = Math.floor(Math.random() * 3);

      if (col === 0) {
        material.current.color.set(0.4, 0.4, 0.4);
      } else if (col === 1) {
        material.current.color.set(3, 2.5, 1.5);
      } else {
        material.current.color.set(1, 1, 0.75);
      }
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <Decal
        mesh={mesh}
        debug={debug}
        position={[position.x, position.y, position.z]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        scale={[scale.x, scale.y, scale.z]} // Scale of the decal
      >
        <meshStandardMaterial
          // polygonOffset={true}
          // polygonOffsetUnits={-10}
          ref={material}
          depthTest={false}
          // opacity={1}
          // transparent={true}
          metallness={metallness}
          roughness={roughness}
          envMapIntensity={envMapIntensity}
          color={new THREE.Color(7, 7, 2)}
        />
      </Decal>
    </>
  );
}
