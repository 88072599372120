import { Environment, useHelper } from '@react-three/drei';
import { folder, useControls } from 'leva';
import { useEffect, useRef } from 'react';
import { useResponsive } from '../../../../stores/useResponsive';
// import { useControls } from 'leva';

export function Lighting() {
  /*
   * properties
   */

  const x = useResponsive((state) => state.x);
  const y = useResponsive((state) => state.y);

  const shadowLight = useRef();
  const camera = useRef();

  const rect = useRef();

  // const config = useControls('lights ° info', {
  //   'cyber ambient light blue': folder({
  //     ambientPosition: { value: { x: -3.4, y: 1, z: 1 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //     ambientIntensity: { value: 5.0, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     ambientColor: { value: '#348290', label: 'color' },
  //   }),

  //   'cyber rectangle light green': folder({
  //     rectPosition: { value: { x: 0, y: 0.44, z: 3.4 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //     rectIntensity: { value: 7, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     rectColor: { value: '#00ff19', label: 'color' },
  //   }),
  //   'shadow directional light': folder({
  //     positionShadow: { value: { x: -2, y: 1, z: 4 }, min: -15, max: 15, step: 0.01, label: 'position' },
  //     intensityShadow: { value: .5, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorShadow: { value: '#9bff82', label: 'color' },
  //   }),
  //   'shadow camera': folder({
  //     left: { value: -7, min: -10, max: 10, step: 0.01 },
  //     right: { value: 7, min: -10, max: 10, step: 0.01 },
  //     top: { value: 7, min: -10, max: 10, step: 0.01 },
  //     bottom: { value: -6, min: -10, max: 10, step: 0.01 },
  //     far: { value: 20, min: 0, max: 50, step: 0.01 },
  //   }),
  // });

  const config = {
    ambientPosition: { x: -3.4, y: 1, z: 1 },
    ambientIntensity: 5.0,
    ambientColor: '#348290',
    rectPosition: { x: 0, y: 0.44, z: 3.4 },
    rectIntensity: 7,
    rectColor: '#00ff19',
    positionShadow: { x: -2, y: 1, z: 4 },
    intensityShadow: 0.5,
    colorShadow: '#9bff82',
    left: -7,
    right: 7,
    top: 7,
    bottom: -6,
    far: 20,
  };

  // useHelper(shadowLight, DirectionalLightHelper);
  // useHelper(camera, CameraHelper);

  useEffect(() => {
    // console.log(x);
    shadowLight.current.target.position.x = x * 0.4;
  }, [x]);

  useEffect(() => {
    // console.log(y);
    shadowLight.current.target.position.y = y * 0.2;
  }, [y]);

  /*
   * visuals
   */

  return (
    <>
      <directionalLight
        ref={shadowLight}
        position={[config.positionShadow.x, config.positionShadow.y, config.positionShadow.z]}
        intensity={config.intensityShadow}
        color={config.colorShadow}
        castShadow
      >
        <orthographicCamera
          attach="shadow-camera"
          ref={camera}
          far={config.far}
          top={config.top}
          bottom={config.bottom}
          left={config.left}
          right={config.right}
        />
      </directionalLight>

      {shadowLight && shadowLight.current && <primitive object={shadowLight.current.target} />}

      <rectAreaLight
        args={[config.ambientColor, config.ambientIntensity, 5, 5]}
        position={[config.ambientPosition.x, config.ambientPosition.y, config.ambientPosition.z]}
        rotation={[0, -1.57, 0]}
      />

      <rectAreaLight
        ref={rect}
        args={[config.rectColor, config.rectIntensity, 5, 0.4]}
        position={[config.rectPosition.x, config.rectPosition.y, config.rectPosition.z]}
      />

      {/* <Environment
        background={false}
        path="./textures/environment/"
        files={[
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
        ]}
      /> */}
    </>
  );
}
