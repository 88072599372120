import { Html, useTexture } from '@react-three/drei';
import { useControls } from 'leva';
import { useEffect, useMemo, useRef, useState } from 'react';
import style from './Intro.module.css';
import gsap from 'gsap';
import { useScrollPosition } from '../../../../stores/useScrollPosition';
import { Label } from './Label';
import { useMaps } from '../../../../stores/useMaps';

export function Labels() {
  /*
   * properties
   */

  // const textures = useTexture([
  //   'textures/about/label1.png',
  //   'textures/about/label2.png',
  //   'textures/about/label3.png',
  //   'textures/about/label4.png',
  // ]);

  const textures = useMemo(() => {
    return [useMaps.getState().label1, useMaps.getState().label2, useMaps.getState().label3, useMaps.getState().label4];
  }, []);

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {/* FRONT */}
      {/* google top right */}
      <Label textures={textures} position={[-1.4, 0.65, 3.4]} />
      {/* forehead left */}
      <Label textures={textures} position={[1.2, 1.85, 2.5]} />
      {/* cheek left */}
      <Label textures={textures} position={[1.2, -0.9, 2.6]} />

      {/* RIGHT */}
      {/* cheek */}
      <Label textures={textures} position={[-2, -0.75, 1.7]} />
      {/* top */}
      <Label textures={textures} position={[-2.0, 1.5, 0.95]} />

      {/* LEFT */}
      {/* cheek top */}
      <Label textures={textures} position={[1.8, -0.3, 1]} />
      {/* cheek bottom */}
      <Label textures={textures} position={[2.1, -1.4, -0.4]} />
      {/* top */}
      <Label textures={textures} position={[2.3, 2, -1.4]} />

      {/* BACK */}
      {/* top right */}
      <Label textures={textures} position={[-1.7, 2.1, -2.4]} />
      {/* under decal */}
      <Label textures={textures} position={[0, -0.15, -3.3]} />
      {/* left under */}
      <Label textures={textures} position={[1.5, -0.4, -2.6]} />
    </>
  );
}
