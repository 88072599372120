import { BlendFunction, Effect } from 'postprocessing';
import { Uniform } from 'three';

const fragmentShader = /* glsl */ `
    uniform sampler2D displaceTexture;
    uniform float time;

    lowp vec3 permute(in lowp vec3 x) { return mod( x*x*34.+x, 289.); }

    lowp float snoise(in lowp vec2 v) {
      lowp vec2 i = floor((v.x+v.y)*.36602540378443 + v),
          x0 = (i.x+i.y)*.211324865405187 + v - i;
      lowp float s = step(x0.x,x0.y);
      lowp vec2 j = vec2(1.0-s,s),
          x1 = x0 - j + .211324865405187, 
          x3 = x0 - .577350269189626; 
      i = mod(i,289.);
      lowp vec3 p = permute( permute( i.y + vec3(0, j.y, 1 ))+ i.x + vec3(0, j.x, 1 )   ),
           m = max( .5 - vec3(dot(x0,x0), dot(x1,x1), dot(x3,x3)), 0.),
           x = fract(p * .024390243902439) * 2. - 1.,
           h = abs(x) - .5,
          a0 = x - floor(x + .5);
      return .5 + 65. * dot( pow(m,vec3(4.))*(- 0.85373472095314*( a0*a0 + h*h )+1.79284291400159 ), a0 * vec3(x0.x,x1.x,x3.x) + h * vec3(x0.y,x1.y,x3.y));
    }
    
    void mainUv(inout vec2 uv)
    {
        float displacementIntensity = texture(displaceTexture, uv).r;
        displacementIntensity = smoothstep(0.1, 0.3, displacementIntensity);
        displacementIntensity *= .032;

	      float t = time * 1.4;
        float s = 1.0;
        uv.x += displacementIntensity * snoise(uv*(4.3*(s/3.7+1.2))-vec2(t*1.2,0.));
        uv.y += displacementIntensity * (snoise(uv * (1.0 * (1.0 / 3.7 + .2)) -vec2(t*1.2,0.)) - .75);

    }

    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor)
    {
        // float displacementIntensity = texture(displaceTexture, uv).r;
        // displacementIntensity = smoothstep(0.1, 0.3, displacementIntensity);
        // outputColor = vec4(displacementIntensity, 0.0, 0.0, 1.0);

        outputColor = inputColor;
    }
`;

export default class LuiquidEffect extends Effect {
  constructor({ texture, blendFunction = BlendFunction.NORMAL }) {
    super('DarkenEffect', fragmentShader, {
      blendFunction,

      uniforms: new Map([
        ['displaceTexture', new Uniform(texture)],
        ['time', new Uniform(0)],
      ]),
    });

    this.time = 0;
    this.texture = texture;
  }

  update(renderer, inputBuffer, deltaTime) {
    this.texture.needsUpdate = true;
    this.time += deltaTime;
    this.uniforms.get('time').value = this.time;
  }
}
