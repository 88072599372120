import { Instance } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';

export function NeckItem({ index, total }) {
  /*
   * properties
   */

  const instance = useRef();
  const material = useRef();

  /*
   * hooks
   */

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    let pct = (time * 0.3 + index * 0.33) % 1;

    const pos = Math.pow(pct, 0.6);
    instance.current.position.z = pos * 1.1;

    let scale = 1 - Math.pow(pct, 3);
    scale *= 1.3;
    instance.current.scale.set(scale + 0.03 - Math.random() * 0.05, scale + 0.03 - Math.random() * 0.05, scale);

    // material.current.opacity = 0.7 + Math.random() * 0.3;
    material.current.opacity = 0.55 + Math.random() * 0.12;
  });

  /*
   * visuals
   */

  return (
    <mesh ref={instance} rotation={[0, 0, 0]}>
      <torusGeometry args={[0.7, 0.025, 10, 32]} />
      <meshBasicMaterial
        ref={material}
        transparent
        color={[0.2 * 1.5, 2 * 1.5, 0.3 * 1.5]}
        blending={THREE.AdditiveBlending}
      />
    </mesh>
  );
}
