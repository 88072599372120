import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

import { Html, RoundedBox, useGLTF, useKTX2, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { StampDecal } from './StampDecal';
import { Bulge } from './Bulge';
import { Goggles } from './Goggles';
import { useMaps } from '../../../../stores/useMaps';
import { editable as e } from '@theatre/r3f';
import { useMouse } from '../../../../stores/useMouse';
import { useScrollPosition } from '../../../../stores/useScrollPosition';
import { Intro } from './Intro';
import { Labels } from './Labels';
import { useRotation } from '../../../../stores/useRotation';

export function Skull() {
  /*
   * properties
   */

  const skull = useRef();
  const inner = useRef();

  const setRotation = useRotation((state) => state.setRotation);
  const rotationSpeedPct = useRef(0);

  // const { scratches, stamp } = useMaps();

  const { scratches, stamp } = useMemo(() => {
    return { scratches: useMaps.getState().scratches, stamp: useMaps.getState().stamp };
  }, []);

  // const [scratches, stamp, skullNormal, skullColor] = useKTX2([
  //   'textures/shared/scratches.ktx2',
  //   'textures/shared/stamp.ktx2',
  //   'textures/shared/skullNormal.ktx2',
  //   'textures/shared/skullColor.ktx2',
  // ]);

  const scratch = scratches.clone();
  scratch.wrapS = THREE.MirroredRepeatWrapping;
  scratch.wrapT = THREE.MirroredRepeatWrapping;
  scratch.repeat.set(3, 3);

  // const materialConfig = useControls('skull material tech', {
  //   metallness: 0.17, min: 0, max: 1, step: 0.01 },
  //   roughness: 2.0, min: 0, max: 5, step: 0.01 },
  //   envMapIntensity: 0.08, min: 0, max: 10, step: 0.01 },
  //   color: '#cccccc' },
  // });

  const materialConfig = {
    metallness: 0.17,
    roughness: 2.0,
    envMapIntensity: 0.08,
    color: '#cccccc',
  };

  // const model = useGLTF('models/skull/Human_Skull.gltf');
  const model = useMaps.getState().skull;
  const material = new THREE.MeshStandardMaterial();

  /*
   * hooks
   */

  useEffect(() => {
    material.color.set(materialConfig.color);
    material.roughnessMap = scratch;
    material.roughness = materialConfig.roughness;
    material.metalness = materialConfig.metallness;
    material.envMapIntensity = materialConfig.envMapIntensity;
  }, [model, materialConfig]);

  //move camera on mouse move
  useFrame((state, delta) => {
    const { slowPctX, slowPctY } = useMouse.getState();
    let rotation = useRotation.getState().rotation;

    if (useScrollPosition.getState().scrollPosition < 0.98) {
      rotation += (0 - rotation) * 0.05;
      rotationSpeedPct.current = 0;
    } else {
      rotationSpeedPct.current += (1 - rotationSpeedPct.current) * 0.01;

      const rotationSpeed = delta * 0.5;
      rotation += rotationSpeed * rotationSpeedPct.current;

      if (rotation > Math.PI) {
        rotation -= Math.PI * 2;
      }
    }

    // inner.current.rotation.y = slowPctX * -0.05 + skullRotation.current;
    inner.current.rotation.y = rotation;
    inner.current.rotation.x = slowPctY * 0.08 + 0.1;

    setRotation(rotation);
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group ref={skull} theatreKey="aboutSkull" position={[0, -0.4, 0]}>
        <group ref={inner} rotation={[0.1, 0, 0]}>
          <mesh
            geometry={model.nodes.skull.geometry}
            material={material}
            scale={[0.33, 0.33, 0.33]}
            rotation={[-1.571, 0, 0]}
            castShadow
            receiveShadow
          >
            <StampDecal texture={stamp} />
          </mesh>

          <Bulge material={material} front={true} flipped={false} />
          <Bulge material={material} front={false} flipped={false} />
          <Bulge material={material} front={true} flipped={true} />
          <Bulge material={material} front={false} flipped={true} />

          <Labels />

          <Goggles material={material} />
        </group>
      </e.group>
    </>
  );
}
