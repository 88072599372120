import { Billboard, Html } from '@react-three/drei';
import style from './Label.module.css';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { useRotation } from '../../../../stores/useRotation';
import * as THREE from 'three';

export function Label({ textures, position }) {
  /*
   * properties
   */

  const mesh = useRef();
  const material = useRef();
  let textureTime = 0;
  let textureCounter = Math.floor(Math.random() * 4);

  let blinkTime = 0;

  /*
   * hooks
   */

  useEffect(() => {
    mesh.current.visible = Math.round(Math.random());
  }, []);

  useFrame((state) => {
    const time = state.clock.elapsedTime;

    //set rotation
    const rot = useRotation.getState().rotation;
    mesh.current.rotation.y = -rot;

    //set texture
    if (!textureTime || textureTime <= time) {
      textureTime = time + 1.5 + Math.random() * 4;
      material.current.map = textures[textureCounter];

      textureCounter++;
      if (textureCounter === 4) {
        textureCounter = 0;
      }
    }

    //set blink
    if (!blinkTime || blinkTime <= time) {
      blinkTime = time + 5 + Math.random() * 3;
      mesh.current.visible = !mesh.current.visible;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh} position={position}>
        <planeGeometry args={[0.3, 0.3]} />
        <meshBasicMaterial
          // side={THREE.DoubleSide}
          transparent={true}
          ref={material}
          map={textures[0]}
        />
        {/* <boxGeometry args={[0.3, 0.3]} />
        <meshBasicMaterial color="red" /> */}
      </mesh>
    </>
  );
}
