import { useFrame } from '@react-three/fiber';
import style from './Scroller.module.css';
import { useEffect, useRef, useState } from 'react';
import { useScrollPosition } from '../../stores/useScrollPosition';
import { useSection } from '../../stores/useSection';

export function Scroller({ label, children }) {
  /*
   * properties
   */

  const container = useRef();

  const [enabled, setEnabled] = useState(false);

  const transition = useSection((state) => state.transition);
  const setScrollPosition = useScrollPosition((state) => state.setScrollPosition);
  const resetScrollPosition = useScrollPosition((state) => state.resetScrollPosition);
  const scrollTo = useScrollPosition((state) => state.scrollTo);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Scroller:: onMount');
    resetScrollPosition();

    const timeoutId = setTimeout(() => {
      setEnabled(true);
    }, 2000);

    return () => {
      // console.log('Scroller :: destroy');
      resetScrollPosition();
      clearTimeout(timeoutId);
    };
  }, []);

  const scrollHandler = (event) => {
    const element = container.current;
    const pct = element.scrollTop / (element.scrollHeight - element.clientHeight);

    setScrollPosition(pct);
  };

  useEffect(() => {
    const element = container.current;
    const slideSpace = element.scrollHeight - element.clientHeight;

    container.current.scrollTo({
      top: scrollTo * slideSpace,
      behavior: 'smooth',
    });
  }, [scrollTo]);

  /*
   * visuals
   */

  return (
    <div
      ref={container}
      className={`${style.scrollContainer} ${transition === 'shown' ? style.enabled : ''}`}
      onScroll={scrollHandler}
    >
      {enabled && (
        <>
          <div className={style.child} />
          {children}
        </>
      )}
    </div>
  );
}
