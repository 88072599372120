import { useEffect, useMemo, useRef } from 'react';
import { useMouse } from '../../stores/useMouse';
import { useControls } from 'leva';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

export function Displacement({ setTexture }) {
  /*
   * properties
   */

  // const config = useControls('Mouse cursor displacement texture', {
  //   show: { value: false, label: 'preview' },
  // })

  const ctx = useRef();

  const canvasCursor = useRef(new THREE.Vector2(9999, 9999));
  const canvasCursorPrevious = useRef(new THREE.Vector2(9999, 9999));
  const timePrevious = useRef(0);

  const glowImage = new Image();
  glowImage.src = './images/glow.png';

  const canvas = useMemo(() => {
    // console.log('Displacement :: create canvas')
    var c = document.createElement('canvas');
    // console.log(c)

    document.body.appendChild(c);
    return c;
  }, []);

  /*
   * hooks
   */

  useEffect(() => {
    initCanvas();

    // console.log('Displacement :: onMount')

    // console.log(canvas.getContext('2d'))

    const intervalId = setInterval(loop, 1000 / 60);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const initCanvas = () => {
    ctx.current = canvas.getContext('2d');

    // console.log(ctx.current);

    const size = 128;
    canvas.width = size * 2;
    canvas.height = size * 2;

    canvas.style.width = size + 'px';
    canvas.style.height = size + 'px';
    ctx.current.scale(2, 2);
    ctx.current.fillRect(0, 0, 128, 128);

    canvas.style.position = 'fixed';
    canvas.style.bottom = 0;
    canvas.style.left = 0;
    canvas.style.visibility = 'hidden';

    const texture = new THREE.CanvasTexture(canvas);

    setTexture(texture);
  };

  // useEffect(() => {
  //   canvas.style.visibility = config.show ? 'visible' : 'hidden'
  // }, [config])

  const loop = () => {
    const time = new Date();
    const now = time.getTime();

    if (!timePrevious.current) {
      timePrevious.current = now;
    }

    const delta = now - timePrevious.current;
    timePrevious.current = now;

    // console.log(delta);

    const { slowPctX, slowPctY } = useMouse.getState();
    const context = ctx.current;

    // Fade out
    context.globalCompositeOperation = 'source-over';
    // console.log(delta);
    context.globalAlpha = delta * 0.003;
    context.fillRect(0, 0, 128, 128);

    // Speed alpha
    canvasCursor.current.set(slowPctX, slowPctY);
    const cursorDistance = canvasCursorPrevious.current.distanceTo(canvasCursor.current);
    canvasCursorPrevious.current.copy(canvasCursor.current);

    const dist = Math.min(1, cursorDistance * delta); //percentage

    const alpha = dist * .5;
    const sizeFactor = 0.5 + dist * 0.5;
    const glowSize = 18 * sizeFactor;
    context.globalCompositeOperation = 'lighten';
    context.globalAlpha = alpha;
    context.drawImage(
      glowImage,
      64 + slowPctX * 64 - glowSize * 0.5,
      64 + slowPctY * 64 - glowSize * 0.5,
      glowSize,
      glowSize
    );
  };

  /*
   * visuals
   */

  return <></>;
}
