import { useEffect, useRef, useState } from 'react';
import style from './Line.module.css';
import { useSection } from '../../../stores/useSection';
import { Layer } from '../../Layer';
import gsap from 'gsap';

export function Line({ label, index }) {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const element = useRef();
  const timeoutId = useRef();
  const intervalId = useRef();
  const labelArray = useRef('..........................'.split(''));
  //   const labelArray = useRef('                          '.split(''));

  /*
   * hooks
   */

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    element.current.innerHTML = labelArray.current.join('');
    timeoutId.current = setTimeout(changeText, 100 + Math.round(Math.random() * 1000));

    gsap.set(element.current, { opacity: 0, x: window.innerHeight * 0.02 });
    gsap.to(element.current, { opacity: 1, delay: Math.random() * .5, x: 0, duration: 0.75, ease: 'power4.out' });
  };

  const hide = () => {
    gsap.to(element.current, {
      opacity: 0,
      duration: 0.3,
      ease: 'power4.in',
    });
  };

  const changeText = () => {
    clearInterval(intervalId.current);

    let keys = [...Array(label.length).keys()];
    keys = keys.sort(() => Math.random() - 0.5);

    let intervalIndex = 0;
    const loops = 2;
    intervalId.current = setInterval(() => {
      if (intervalIndex < keys.length * loops) {
        const loop = Math.ceil((intervalIndex + 1) / keys.length);
        const key = keys[intervalIndex % keys.length];

        if (loop != loops) {
          const char = Math.random().toString(36).substring(4, 5).toUpperCase();
          labelArray.current[key] = char;
        } else {
          labelArray.current[key] = label.charAt(key);
        }

        element.current.innerHTML = labelArray.current.join('');

        intervalIndex++;
      } else {
        clearInterval(intervalId.current);
      }
    }, 30);
  };

  /*
   * visuals
   */

  return (
    <>
      <Layer relative={true} size={0.002 * (index - 7)}>
        <div ref={element} className={style.line}>
          {label}
        </div>
      </Layer>
    </>
  );
}
