import { Decal } from '@react-three/drei';
import { useControls } from 'leva';

export function StampDecal({ texture }) {
  /*
   * properties
   */

  // const { debug, scale, position, rotation, color, alpha, metallness, roughness, envMapIntensity } = useControls(
  //   'decal ° reflect',
  //   {
  //     debug: false },
  //     scale: 5, min: 0, max: 20, step: 0.01 },
  //     alpha: 0.75, min: 0, max: 1, step: 0.01 },
  //     position: { x: 0, y: 8, z: 4 }, min: -8, max: 8, step: 0.01 },
  //     rotation: { x: 1.57, y: 3.14, z: 0 }, min: -7, max: 7, step: 0.01 },
  //     metallness: 0, min: 0, max: 1, step: 0.01 },
  //     roughness: 0.1, min: 0, max: 1, step: 0.01 },
  //     envMapIntensity: 1.4, min: 0, max: 10, step: 0.01 },
  //     color: '#ffbf00' },
  //   }
  // );

  const { debug, scale, position, rotation, color, alpha, metallness, roughness, envMapIntensity } = {
    debug: false,
    scale: 5,
    alpha: 0.75,
    position: { x: 0, y: 8, z: 4 },
    rotation: { x: 1.57, y: 3.14, z: 0 },
    metallness: 0,
    roughness: 0.1,
    envMapIntensity: 1.4,
    color: '#ffbf00',
  };

  /*
   * visuals
   */

  return (
    <>
      <Decal
        debug={debug}
        position={[position.x, position.y, position.z]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        scale={scale} // Scale of the decal
      >
        <meshStandardMaterial
          polygonOffset
          polygonOffsetFactor={-10}
          alphaMap={texture}
          opacity={alpha}
          transparent={true}
          // depthTest={false}
          color={color}
          metallness={metallness}
          roughness={roughness}
          envMapIntensity={envMapIntensity}
        />
      </Decal>
    </>
  );
}
