import { Decal, Dodecahedron, PerspectiveCamera, RenderTexture, RoundedBox } from '@react-three/drei';
import { useControls } from 'leva';
import { EyeContent } from './EyeContent';
import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { editable as e } from '@theatre/r3f';
import * as THREE from 'three';

export function Crusifix() {
  /*
   * properties
   */

  const material1 = useRef();
  const material2 = useRef();

  // const containerConfig = useControls('crusifix ° reflect', {
  //   position: { value: { x: 0, y: 0.78, z: 3.1 }, min: -8, max: 8, step: 0.01 },
  //   rotation: { value: { x: -0.14, y: 0, z: 0 }, min: -8, max: 8, step: 0.01 },
  // });

  const containerConfig = {
    position: { x: 0, y: 0.78, z: 3.1 },
    rotation: { x: -0.14, y: 0, z: 0 },
  };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    material1.current.opacity = 0.5 + Math.random() * 0.5;
    material2.current.opacity = 0.5 + Math.random() * 0.5;
  });

  /*
   * visuals
   */

  return (
    <>
      <group
        position={[containerConfig.position.x, containerConfig.position.y, containerConfig.position.z]}
        rotation={[containerConfig.rotation.x, containerConfig.rotation.y, containerConfig.rotation.z]}
      >
        <e.mesh theatreKey={'cross h'} position-y={-0.1}>
          <boxGeometry args={[0.6, 0.1, 0.2]} />
          <meshBasicMaterial ref={material1} color={[1.5, 3, 0.5]} transparent={true} />
        </e.mesh>
        <e.mesh theatreKey={'cross v'}>
          <boxGeometry args={[0.1, 0.8, 0.2]} />
          <meshBasicMaterial ref={material2} color={[1.5, 3, 0.5]} transparent={true} />
        </e.mesh>
      </group>
    </>
  );
}
