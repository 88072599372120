import { create } from 'zustand';

export const useProjects = create((set) => ({
  activeProject: -1,

  setActiveProject: (activeProject) => set({ activeProject }),

  projects: [
    {
      title: 'Curious & Co',
      video: 'curious.mp4',
      info: 'Concept & Design by <a href="https://curious.co/" target="_blank">Curious & Company</a>',
      tasks: 'Front End // WebGL',
      site: 'www.curious.co',
      url: 'https://curious.co/',
    },
    {
      title: 'Manifesto',
      video: 'manifesto.mp4',
      info: 'Concept & Design by <a href="https://www.itsoffbrand.com/" target="_blank">Off+Brand</a>',
      tasks: 'Front End // WebGL',
      site: 'manifesto.offbrand.com',
      url: 'https://manifesto.itsoffbrand.com/',
    },
    {
      title: 'David Lee Studio',
      video: 'davelee.mp4',
      info: 'Concept, Design and Front End by <a href="https://www.itsoffbrand.com/" target="_blank">Off+Brand</a>',
      tasks: 'WebGL',
      site: 'www.davidlee.studio',
      url: 'https://www.davidlee.studio/',
    },
    {
      title: 'Omen',
      video: 'omen.mp4',
      info: 'Concept & Design by <a href="https://www.itsoffbrand.com/" target="_blank">Off+Brand</a>',
      tasks: 'Front End // WebGL',
      site: 'nemonium.com',
      url: 'https://nemonium.com/',
    },
    {
      title: 'Ethrum',
      video: 'ethrum.mp4',
      info: 'Concept by myself',
      tasks: 'Front End // WebGL // Design',
      site: '',
      url: '',
    },
    {
      title: 'Aptos',
      video: 'aptos.mp4',
      info: 'Concept, Design and Front End by <a href="https://www.itsoffbrand.com/" target="_blank">Off+Brand</a>',
      tasks: 'WebGL',
      site: 'aptoslabs.com',
      url: 'https://aptoslabs.com/',
    },
    {
      title: 'Clockwork 9',
      video: 'clockwork9.mp4',
      info: 'Concept and Design by Clockwork 9',
      tasks: 'Front End // WebGL',
      site: '',
      url: '',
    },
    {
      title: 'Sector 32 Story',
      video: 'story.mp4',
      info: 'Story of my professional life',
      tasks: 'Front End // WebGL // Design',
      site: 'story.sector32.net',
      url: 'https://story.sector32.net/',
    },
    {
      title: 'Sector 32 2022',
      video: 'old.mp4',
      info: 'Ye olde Sector 32 website',
      tasks: 'Front End // WebGL // Design',
      site: '',
      url: '',
    },
  ],
}));
