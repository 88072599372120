import { useEffect, useRef, useState } from 'react';
import { useSounds } from '../../stores/useSounds';

export function RandomLabel({ label, active }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);

  const element = useRef();
  const intervalId = useRef();
  const labelArray = useRef(label.split(''));

  /*
   * hooks
   */

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (active) {
      changeText(true);
    } else {
      changeText(false);
    }
    // playSound("randomLabel")
  }, [active]);

  const changeText = (upperCase) => {
    clearInterval(intervalId.current);

    let keys = [...Array(label.length).keys()];
    keys = keys.sort(() => Math.random() - 0.5);

    let intervalIndex = 0;
    const loops = 2;
    intervalId.current = setInterval(() => {
      if (intervalIndex < keys.length * loops) {
        const loop = Math.ceil((intervalIndex + 1) / keys.length);
        const key = keys[intervalIndex % keys.length];

        if (loop != loops) {
          const char = Math.random().toString(36).substring(4, 5).toUpperCase();
          labelArray.current[key] = char;
        } else if (upperCase) {
          labelArray.current[key] = label.charAt(key).toUpperCase();
        } else {
          labelArray.current[key] = label.charAt(key);
        }

        element.current.innerHTML = labelArray.current.join('');

        intervalIndex++;
      } else {
        clearInterval(intervalId.current);
      }
    }, 35);
  };

  /*
   * visuals
   */

  return (
    <>
      <span ref={element}>{label}</span>
    </>
  );
}
