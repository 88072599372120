import { useEffect, useRef, useState } from 'react';
import style from './MenuButton.module.css';
import gsap from 'gsap';
import { RandomLabel } from './RandomLabel';
import { useSection } from '../../stores/useSection';
import { useMouse } from '../../stores/useMouse';

export function MenuButton({ index, activeItem, label, subLabel, link, overHandler, outHandler }) {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const isTouch = useMouse((state) => state.isTouch);
  const hideSection = useSection((state) => state.hide);
  const button = useRef();
  // const link = useRef();
  const [isOver, setIsOver] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    let delay = 1.5 - 0.1 * index;

    gsap.killTweensOf(button.current);
    gsap.set(button.current, { scale: 0.7, opacity: 0 });

    gsap.to(button.current, {
      duration: 0.1,
      delay,
      opacity: 1,
      ease: 'power4.out',
    });

    gsap.to(button.current, {
      duration: 0.5,
      delay,
      scale: 1,
      ease: 'power4.out',
    });
  };

  const hide = () => {
    let delay = 0.05 * index;

    gsap.killTweensOf(button.current);
    gsap.to(button.current, {
      duration: 0.5,
      scale: 0.7,
      opacity: 0,
      delay,
      ease: 'power4.in',
    });
  };

  const onClick = () => {
    // console.log('klikkerdeklik');
    hideSection(link);
  };

  const onMouseOver = (index) => {
    if (!isTouch) {
      overHandler(index);
      setIsOver(true);
    }
  };

  const onMouseOut = () => {
    if (!isTouch) {
      outHandler();
      setIsOver(false);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={button} className={style.pair}>
        <button
          aria-label={label.replace(/\\/g, '')}
          className={`${style.link} ${activeItem === index ? style.big : ''} ${
            activeItem !== -1 && activeItem !== index ? style.small : ''
          }`}
          // tabIndex={index + 2}
          onClick={onClick}
          onMouseEnter={() => onMouseOver(index)}
          onMouseLeave={onMouseOut}
        >
          <RandomLabel label={label} active={isOver} />
        </button>
        <div className={`${style.line} ${index === 3 ? style.last : ''}`} />
        <div className={style.extra}>{subLabel}</div>
      </div>
    </>
  );
}
