import { useEffect, useRef } from 'react';
import { useResponsive } from '../../stores/useResponsive';
import { ScrollControls } from '@react-three/drei';

export function Section({ children }) {
  /*
   * properties
   */
  const group = useRef();

  const setPosition = useResponsive((state) => state.setPosition);

  /*
   * hooks
   */
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  const resizeHandler = () => {
    /*
     * THEATRE studio start, DISABLE for studio
     */
    const ratio = window.innerWidth / window.innerHeight;
    // console.log('resizeHandler');
    let posX = 0,
      posY = 0,
      rotX = 0,
      rotY = 0;
    if (ratio > 1) {
      // LANDSCAPE
      const factor = Math.min(1, ratio - 1);
      posX = -factor * 2.5;
      rotY = factor * 0.25;
    } else {
      // PORTRAIT
      const factor = Math.pow(1 - ratio, 1.5);
      posY = factor * 3;
      rotX = factor * 0.37;
    }
    // console.log(posX);
    group.current.position.x = posX;
    group.current.position.y = posY;
    group.current.rotation.x = rotX;
    group.current.rotation.y = rotY;
    setPosition(posX, posY);
    /*
     * THEATRE studio end
     */
  };

  /*
   * visuals
   */
  return (
    <>
      {/* <ScrollControls pages={3} damping={0.2}> */}
      <group ref={group}>{children}</group>
      {/* </ScrollControls> */}
    </>
  );
}
