import React, { useEffect, useRef, useState } from 'react';
import style from './Loader.module.css';
import gsap from 'gsap';

import { useProgress } from '@react-three/drei';

export function Loader() {
  const { active, progress, item, loaded, total } = useProgress();
  const [bar, setBar] = useState('[XXXXXXXXXX.........]');
  const container = useRef();

  useEffect(() => {
    let content = '';
    content = content.padStart(Math.round(progress / 5), ':').padEnd(20, '.');
    setBar('[' + content + ']');

    // console.log(progress)
  }, [progress]);

  useEffect(() => {
    gsap.killTweensOf(container.current);

    if (active) {
      gsap.set(container.current, { scale: 1 });
    } else {
      gsap.set(container.current, { scale: 0 });
    }
  }, [active]);

  return (
    <>
      <div ref={container} className={style.loader}>
        <div className={style.body}>
          Copyright 2000
          <br />
          Sector 32 OS
          <br />
          All rights reserved
          <br />
          <br />
          PC32 BOOTROM 1.1
          <br />
          INT2.86 Processor
          <br />
          <br />
          Booting...
          <br />
          <br />
          Item {loaded}/{total}
          <br />
          Filename {item.substring(item.lastIndexOf('/') + 1)}
          <br />
          <br />
          Progress {bar}
        </div>
      </div>
    </>
  );
}
