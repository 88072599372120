import { useFrame } from '@react-three/fiber';
import { HaloRing } from './HaloRing';
import { useRef } from 'react';
import { editable as e } from '@theatre/r3f';
import { useRotation } from '../../../../stores/useRotation';
import { useMouse } from '../../../../stores/useMouse';

export function Halo() {
  /*
   * properties
   */

  const container = useRef();
  const outer = useRef();

  let isBig = true;
  let nextTime = 0;

  /*
   * hooks
   */

  useFrame((state) => {
    const time = state.clock.elapsedTime;

    if (time > nextTime) {
      const rnd = Math.random();

      if (rnd > 0.8) {
        nextTime = time + rnd * 6;
      } else {
        nextTime = time + 0.01 + rnd * 0.1;
      }

      isBig = !isBig;

      const scale = isBig ? 1.01 : 0.98;

      container.current.scale.set(scale, scale, scale);
    }

    const { slowPctX, slowPctY } = useMouse.getState();

    let rotation = useRotation.getState().rotation;
    outer.current.rotation.y = rotation;
    outer.current.rotation.x = slowPctY * 0.08;
  });

  /*
   * visuals
   */
  return (
    <>
      {/* <group ref={container} position={[0, 1, -0.2]} rotation={[-0.12, 0, 0]}> */}
      <group ref={outer}>
        <e.group ref={container} theatreKey="halo" position={[0.1, 0, 0.1]} rotation={[0, 0, 0]}>
          <HaloRing pct={1} speed={0.1} index={0} />
          <HaloRing pct={0} speed={0.4} index={1} />
          <HaloRing pct={-1} speed={-1} index={2} />
        </e.group>
      </group>
    </>
  );
}
