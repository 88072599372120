import style from './Arrow2.module.css';
import SVG from './svg/arrow2.svg?react';

export function Arrow2() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={style.arrow2}>
        <SVG />
      </div>
    </>
  );
}
