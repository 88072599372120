import { useEffect, useRef, useState } from 'react';
import style from './Scroll.module.css';
import SVG from '../shared/svg/smallArrow.svg?react';
import gsap from 'gsap';
import { useScrollPosition } from '../../stores/useScrollPosition';
import { useSection } from '../../stores/useSection';

export function Scroll() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);

  const container = useRef();
  const wrapper = useRef();
  const isShown = useRef(true);

  let chars;
  let pointer = 0;

  const scrollPosition = useScrollPosition((state) => state.scrollPosition);

  /*
   * hooks
   */

  useEffect(() => {
    chars = Array.from(container.current.querySelectorAll('span'));

    const intervalId = setInterval(render, 250);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // console.log('Scroll :: transition');
    if (transition === 'show' && useSection.getState().isKnownLocation) {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    gsap.set(wrapper.current, { scale: 0 });
    gsap.to(wrapper.current, { delay: 2.1, scale: 1, duration: 0.6, ease: 'back.out' });
  };

  const hide = () => {
    gsap.to(wrapper.current, {
      delay: 0.05,
      y: window.innerHeight * 0.04,
      autoAlpha: 0,
      duration: 0.4,
      ease: 'back.in',
    });
  };

  useEffect(() => {
    // console.log(scrollPosition);
    if (scrollPosition > 0.01 && isShown.current) {
      // console.log('hide');
      isShown.current = false;

      gsap.killTweensOf(container.current);
      gsap.to(container.current, { y: -window.innerHeight * 0.04, autoAlpha: 0, duration: 0.5, ease: 'back.in' });
    } else if (scrollPosition < 0.01 && !isShown.current) {
      // console.log('show');
      isShown.current = true;

      gsap.killTweensOf(container.current);
      gsap.to(container.current, { y: 0, autoAlpha: 1, duration: 0.5, ease: 'back.out' });
    }
  }, [scrollPosition]);

  const render = () => {
    chars[pointer].style.opacity = 1;
    chars[pointer].style.transform = 'scale(1)';

    pointer++;
    if (pointer >= chars.length) {
      pointer = 0;
    }

    chars[pointer].style.opacity = 0.5;
    chars[pointer].style.transform = 'scale(.6)';
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.scroll} ref={wrapper}>
        <div className={style.container} ref={container}>
          <p className={style.text}>
            <span>S</span>
            <span>C</span>
            <span>R</span>
            <span>O</span>
            <span>L</span>
            <span>L</span>
          </p>
          <div className={style.arrow}>
            <div className={style.arrowInner}>
              <SVG />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
