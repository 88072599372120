import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useRef } from 'react';
import * as THREE from 'three';

export function Eye({ left }) {
  /*
   * properties
   */

  const top = useRef();
  const bot = useRef();

  let isDark = true;
  let nextTime = 0;

  // const configTop = useControls('Eye top', {
  //   position: { value: { x: -0.94, y: 0.12, z: 2.03 }, min: -4, max: 4, step: 0.01 },
  //   rotation: { value: { x: -0, y: -0.41, z: -1.63 }, min: -2, max: 2, step: 0.01 },
  // });

  // const configBot = useControls('Eye bottom', {
  //   position: { value: { x: -0.88, y: -0.26, z: 2.03 }, min: -4, max: 4, step: 0.01 },
  //   rotation: { value: { x: 0, y: 0.33, z: -1.28 }, min: -2, max: 2, step: 0.01 },
  // });

  const configTop = {
    position: { x: -0.94, y: 0.12, z: 2.03 },
    rotation: { x: -0, y: -0.41, z: -1.63 },
  };

  const configBot = {
    position: { x: -0.88, y: -0.26, z: 2.03 },
    rotation: { x: 0, y: 0.33, z: -1.28 },
  };

  const material = useRef();

  const multiplier = 2.5;
  const colorGreen = new THREE.Color(0.05 * multiplier, 1.0 * multiplier, 0.05 * multiplier);
  const colorBlue = new THREE.Color(0.0 * multiplier, 1.0 * multiplier, 1.0 * multiplier);
  const colorBlueDark = new THREE.Color(0 * multiplier, 0.2 * multiplier, 0.2 * multiplier);

  /*
   * hooks
   */

  useFrame((state) => {
    top.current.scale.set(0.8 + Math.random() * 0.4, 1, 0.8 + Math.random() * 0.4);
    bot.current.scale.set(0.8 + Math.random() * 0.4, 1, 0.8 + Math.random() * 0.4);

    const time = state.clock.elapsedTime;

    if (left && time > nextTime) {
      nextTime = time + 0.2 + Math.random() * 1.1;

      isDark = !isDark;

      material.current.color.copy(isDark ? colorBlue : colorBlueDark);
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group scale={[left ? 1 : -1, 1, 1]}>
        <mesh
          ref={top}
          position={[configTop.position.x, configTop.position.y, configTop.position.z]}
          rotation={[configTop.rotation.x, configTop.rotation.y, configTop.rotation.z]}
        >
          <cylinderGeometry args={[0.07, 0.04, 1.4, 10, 1, true]} />
          <meshBasicMaterial ref={material} color={new THREE.Color().lerpColors(colorGreen, colorBlue, 1)} />
        </mesh>

        <mesh
          ref={bot}
          position={[configBot.position.x, configBot.position.y, configBot.position.z]}
          rotation={[configBot.rotation.x, configBot.rotation.y, configBot.rotation.z]}
        >
          <cylinderGeometry args={[0.06, 0.02, 1.4, 10, 1, true]} />
          <meshBasicMaterial color={new THREE.Color().lerpColors(colorGreen, colorBlue, 0.3)} />
        </mesh>
      </group>
    </>
  );
}
