import { Decal } from '@react-three/drei';
import { useControls } from 'leva';

export function StampDecal({ texture }) {
  /*
   * properties
   */

  // const { debug, scale, position, rotation, color, alpha, metallness, roughness, envMapIntensity } = useControls(
  //   'cyber decal',
  //   {
  //     debug: { value: false },
  //     scale: { value: 4.3, min: 0, max: 20, step: 0.01 },
  //     position: { value: { x: 0, y: 8, z: 4.6 }, min: -8, max: 8, step: 0.01 },
  //     rotation: { value: { x: 1.57, y: 3.14, z: 0 }, min: -7, max: 7, step: 0.01 },
  //     metallness: { value: 1.0, min: 0, max: 1, step: 0.01 },
  //     roughness: { value: 0.22, min: 0, max: 1, step: 0.01 },
  //     envMapIntensity: { value: 7, min: 0, max: 10, step: 0.01 },
  //     color: { value: '#213541' },
  //   }
  // );

  const { debug, scale, position, rotation, color, alpha, metallness, roughness, envMapIntensity } = {
      debug: false,
      scale: 4.3,
      position: { x: 0, y: 8, z: 4.6 },
      rotation: { x: 1.57, y: 3.14, z: 0 }, 
      metallness: 1.0, 
      roughness: 0.22, 
      envMapIntensity: 7, 
      color: '#213541',
  };

  /*
   * visuals
   */

  return (
    <>
      <Decal
        debug={debug}
        position={[position.x, position.y, position.z]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        scale={scale} // Scale of the decal
      >
        <meshStandardMaterial
          polygonOffset
          polygonOffsetFactor={-10}
          alphaMap={texture}
          opacity={alpha}
          transparent={true}
          // depthTest={false}
          color={color}
          metallness={metallness}
          roughness={roughness}
          envMapIntensity={envMapIntensity}
        />
      </Decal>
    </>
  );
}
