import { useState, useEffect, useRef } from 'react';
import style from './Contact.module.css';
// import SVG from './svg/contact.svg?react';
import SVG from './svg/contactBig.svg?react';
import { RandomLabel } from '../component/RandomLabel';
import { useMouse } from '../../stores/useMouse';
import { useSounds } from '../../stores/useSounds';

export function Contact() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const container = useRef();
  const isTouch = useMouse((state) => state.isTouch);
  let parts;
  const [isOver, setIsOver] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    parts = Array.from(container.current.querySelectorAll('#parts > *'));

    const intervalId = setInterval(render, 1678);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const render = () => {
    for (const part of parts) {
      // console.log(part);
      part.style.opacity = Math.random() > 0.7 ? 0.5 : 1;
    }
  };

  const onMouseOver = () => {
    // overHandler(index);
    if (!isTouch) {
      playSound('generalButton');
      setIsOver(true);
    }
  };

  const onMouseOut = () => {
    // outHandler();
    if (!isTouch) {
      playSound('generalButton');
      setIsOver(false);
    }
  };

  const clickHandler = () => {
    playSound('thud');
    window.location.href = 'mailto:piet@sector32.net';
  };

  /*
   * visuals
   */

  return (
    <>
      <footer className={style.contact} ref={container}>
        <button
          aria-label="contact"
          className={style.button}
          onMouseEnter={onMouseOver}
          // onMouseOver={onMouseOver}
          onMouseLeave={onMouseOut}
          // onMouseOut={onMouseOut}
          onClick={clickHandler}
        >
          <RandomLabel label="Contact" active={isOver} />
        </button>
        <SVG />
      </footer>
    </>
  );
}
