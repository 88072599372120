import { Center, PerspectiveCamera, Text3D } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useRef } from 'react';
import * as THREE from 'three';
import { useMouse } from '../../../../stores/useMouse';

export function EyeContent({ left }) {
  /*
   * properties
   */

  const text = useRef();
  const inner = useRef();

  // const materialConfig = useControls('number material ° reflect', {
  //   metallness: { value: 9, min: 0, max: 2, step: 0.01 },
  //   roughness: { value: 0.8, min: 0, max: 1, step: 0.01 },
  //   color: { value: '#83deff' },
  // });

  const materialConfig = {
    metallness: 9,
    roughness: 0.8,
    color: '#83deff',
  };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    let time = state.clock.elapsedTime;

    text.current.rotation.y = left ? time + 0.5 : time * 0.723;

    const { slowPctX, slowPctY } = useMouse.getState();
    // console.log(slowPctX)
    // inner.current.position.x = slowPctX * -0.4;
    // inner.current.position.y = slowPctY * 0.4;
    inner.current.rotation.y = slowPctX * -0.6;
    inner.current.rotation.x = slowPctY * -0.6;
  });

  /*
   * visuals
   */

  return (
    <>
      <PerspectiveCamera makeDefault manual aspect={0.75 / 0.5} position={[0, 0, 5]} />

      <ambientLight intensity={0.3} />

      <directionalLight intensity={10} position={[-2, 0, 1]} color={left ? '#9ce5ff' : '#cf8be6'} />

      <Center ref={text}>
        <Text3D
          font="/fonts3D/Mechsuit_Regular.json"
          ref={inner}
          curveSegments={2}
          bevelEnabled={false}
          height={1}
          size={2.5}
        >
          {left ? 3 : 2}
          <meshStandardMaterial
            metallness={materialConfig.metallness}
            roughness={materialConfig.roughness}
            color={materialConfig.color}
          />
        </Text3D>
      </Center>

      <color attach="background" args={[left ? new THREE.Color(2, 0.2, 10) : new THREE.Color(0.5, 1, 10)]} />
    </>
  );
}
