import { useGLTF, useKTX2, useTexture } from '@react-three/drei';
import { useMaps } from '../stores/useMaps';
import { useEffect } from 'react';

export function Materials() {
  // console.log('App3D Materials');
  /*
   * properties
   */
  
  const skull = useGLTF('models/skull/Human_Skull.gltf');

  const maps = useKTX2([
    'textures/shared/scratches.ktx2',
    'textures/shared/skullNormal.ktx2',
    'textures/shared/skullColor.ktx2',
    'textures/shared/stamp.ktx2',

    'textures/text/how.ktx2',
    'textures/text/skills.ktx2',
    'textures/text/what.ktx2',
    'textures/text/who.ktx2',

    'textures/about/label1.ktx2',
    'textures/about/label2.ktx2',
    'textures/about/label3.ktx2',
    'textures/about/label4.ktx2',

    'textures/square/arrow.ktx2',
    'textures/square/beehive.ktx2',
    'textures/square/quad.ktx2',
    'textures/square/triangle.ktx2',

    'textures/long/32.ktx2',
    'textures/long/barcode.ktx2',
    'textures/long/text.ktx2',

    'textures/dust/blurredPoint.ktx2',

    'textures/cyber/cybertext.ktx2',
  ]);

  useMaps.getState().init(skull, maps);

  // useEffect(() => {
  //   // console.log(scratches, stamp, skullNormal, skullColor);
  //   console.log('Materials are loaded');
  // }, [scratches, stamp, skullNormal, skullColor]);

  /*
   * hooks
   */

  return <></>;
}
