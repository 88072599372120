import { useEffect, useRef, useState } from 'react';
import style from './Menu.module.css';
import gsap from 'gsap';
import { MenuButton } from './MenuButton';
import SVG from '../shared/svg/menu.svg?react';
import { useSection } from '../../stores/useSection';
import { useSounds } from '../../stores/useSounds';

export function Menu() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);

  const transition = useSection((state) => state.transition);
  const menu = useRef();
  const svg = useRef();

  const timeoutId = useRef();
  const [activeItem, setActiveItem] = useState(-1);

  const items = [
    { label: '\\home', subLabel: 'これ', link: 'home' },
    { label: '\\about', subLabel: 'について', link: 'about' },
    { label: '\\work', subLabel: 'ポートフォリオ', link: 'work' },
    { label: '\\info', subLabel: 'コンタクト', link: 'info' },
  ];

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      clearTimeout(timeoutId.current);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    // console.log("MENU START")
    const delay = 1.7;

    gsap.killTweensOf(svg.current);
    gsap.set(svg.current, { x: window.innerWidth * 0.05, opacity: 0 });

    gsap.to(svg.current, {
      duration: 0.1,
      delay,
      opacity: 1,
      ease: 'power4.out',
    });

    gsap.to(svg.current, {
      duration: 0.5,
      delay,
      x: 0,
      ease: 'power4.out',
    });
  };

  const hide = () => {
    gsap.killTweensOf(svg.current);

    gsap.to(svg.current, {
      duration: 0.5,
      x: window.innerWidth * 0.05,
      opacity: 0,
      ease: 'power4.out',
    });
  };

  const resizeHandler = () => {
    // console.log(resizeHandler);

    let size = Math.min(window.innerWidth, window.innerHeight) / 720;
    size = Math.min(0.8, size);
    // console.log(size);
    // size *= 0.95;

    gsap.set(menu.current, { scale: 0.1 + size * 0.9 });
  };

  useEffect(() => {
    // console.log(activeItem);

    if (activeItem === -1) {
      playSound('menu');
    } else {
      playSound('menu');
    }
  }, [activeItem]);

  const overHandler = (index) => {
    clearTimeout(timeoutId.current);
    setActiveItem(index);

    // console.log('Menu :: overHandler');
  };

  const outHandler = () => {
    timeoutId.current = setTimeout(() => {
      setActiveItem(-1);
      // console.log('Menu :: outHandler');
    }, 150);
  };

  /*
   * visuals
   */

  return (
    <>
      <menu ref={menu} className={style.menu}>
        {items.map((value, index) => (
          <MenuButton
            key={index}
            index={index}
            label={value.label}
            subLabel={value.subLabel}
            link={value.link}
            activeItem={activeItem}
            overHandler={overHandler}
            outHandler={outHandler}
          />
        ))}

        <div ref={svg} className={style.art}>
          <SVG />
        </div>
      </menu>
    </>
  );
}
