import { useEffect, useRef, useState } from 'react';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import style from './Content.module.css';
import { useProjects } from '../../../stores/useProjects';
import { ContentItem } from './ContentItem';
import { useSounds } from '../../../stores/useSounds';

export function Content() {
  /*
   * properties
   */

  // console.log('INTRO :: RENDER');

  const container = useRef();
  const inner = useRef();

  const playSound = useSounds((state) => state.playSound);
  const projects = useProjects((state) => state.projects);
  const activeProject = useProjects((state) => state.activeProject);
  const setActiveProject = useProjects((state) => state.setActiveProject);

  /*
   * hooks
   */
  useEffect(() => {
    setActiveProject(-1);
    loop();

    return () => {
      setActiveProject(-1);
    };
  }, []);

  const loop = () => {
    if (container.current) {
      const amount = projects.length;
      let scrollPosition = Math.min(1, useScrollPosition.getState().scrollPosition * 4 - 2.5);

      const size = Math.min(window.innerWidth, window.innerHeight);

      const innerHeight = size * (0.06 * (amount - 1) + 0.14);
      const projectHeight = size * 0.14;

      inner.current.style.top = -((innerHeight - projectHeight) * scrollPosition) + 'px';

      const newActiveProject = Math.max(-1, Math.round(scrollPosition * (amount - 1)));
      const currentActiveProject = useProjects.getState().activeProject;

      if (currentActiveProject != newActiveProject) {
        // console.log(useScrollPosition.getState().scrollPosition);
        playSound('over');
        setActiveProject(newActiveProject);
      }

      const opacity = 1 - (useScrollPosition.getState().scrollPosition - 0.91) * 40;
      container.current.style.opacity = Math.max(0, Math.min(1, opacity));

      requestAnimationFrame(loop);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.content}>
        <div ref={inner} className={style.inner}>
          {projects.map((project, index) => (
            <span key={index}>
              <ContentItem project={project} index={index} amount={projects.length} />
            </span>
          ))}
        </div>
      </div>
    </>
  );
}
