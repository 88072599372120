import { ScrollControls } from '@react-three/drei';
import { Content } from './Content';
import { useEffect } from 'react';
import { useSection } from '../../../stores/useSection';

export function Info() {
  /*Info
   * properties
   */

  // console.log('About :: render');
  const show = useSection((state) => state.show);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Info :: onMount');
    show();
  }, []);

  /*
   * visuals
   */

  return (
    <>
      <Content />
    </>
  );
}
