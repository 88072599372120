import { useEffect, useRef } from 'react';
import style from './Logo.module.css';
import SVG from './svg/logo.svg?react';

export function Logo() {
  /*
   * properties
   */

  const container = useRef();
  let points;
  let pointer = 0;
  let parts;

  /*
   * hooks
   */

  useEffect(() => {
    points = Array.from(container.current.querySelectorAll('#points > *'));
    const intervalId1 = setInterval(renderPoints, 1147);

    // parts = Array.from(container.current.querySelectorAll('#parts > *'));
    // const intervalId2 = setInterval(renderParts, 3342);

    return () => {
      clearInterval(intervalId1);
      // clearInterval(intervalId2);
    };
  }, []);

  const renderPoints = () => {
    points[pointer].style.opacity = 1;

    pointer++;
    if (pointer >= points.length) {
      pointer = 0;
    }

    points[pointer].style.opacity = .65;
  };

  // const renderParts = () => {
  //   for (const part of parts) {
  //     // console.log(part);
  //     part.style.opacity = Math.random() > .8 ? .2 : 1;
  //   }
  // };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.logo} ref={container}>
        <SVG />
      </div>
    </>
  );
}
