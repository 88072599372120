import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { useMouse } from '../../../../stores/useMouse';
import { Line } from '@react-three/drei';
import { useContact } from '../../../../stores/useContact';

export function Part({ index }) {
  /*
   * properties
   */

  const container = useRef();
  const line = useRef();

  let nextTime = 3.5;

  /*
   * hooks
   */

  const points = useMemo(() => {
    return [
      [0, 3.2 + Math.random() * 0.1, 0],
      [0, -3.2 + Math.random() * 0.1, 0],
    ];
  }, [index]);

  const rotation = useMemo(() => {
    return new THREE.Euler(Math.random() * 8, Math.random() * 8, Math.random() * 8);
  }, [index]);

  const lineWidth = useMemo(() => {
    return 0.5 + Math.random() * 6;
  }, [index]);

  useEffect(() => {
    // console.log('onMount');
    container.current.rotation.set(index * 1, index * 2, index * 3);
  }, [index]);

  useFrame((state) => {
    const time = state.clock.elapsedTime;

    if (time > nextTime) {
      if (nextTime === 3.5) {
        // console.log('JAJA');
      } else {
        // console.log('OOK JAJA');
        rotation.set(Math.random() * 8, Math.random() * 8, Math.random() * 8);
        container.current.rotation.copy(rotation);
      }
      nextTime = time + 2 + Math.random() * 6;
    }

    const scale = useContact.getState().lineSize;
    line.current.scale.set(scale, scale, scale);
  }, []);

  /*
   * visuals
   */
  return (
    <>
      <group ref={container} rotation={[rotation.x, rotation.y, rotation.z]}>
        <Line
          ref={line}
          points={points}
          color={new THREE.Color(0.15, 1.5, 0.15)}
          lineWidth={lineWidth}
          //   dashed={true}
          //   dashScale={10}
        />
      </group>
    </>
  );
}
