import { useEffect, useRef, useState } from 'react';
import style from './SectionContent.module.css';
import gsap from 'gsap';
import { useSection } from '../../stores/useSection';
import { Layer } from '../Layer';

export function SectionContent({ children }) {
  /*
   * properties
   */

  const container = useRef();
  const transition = useSection((state) => state.transition);

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    gsap.set(container.current, { opacity: 0 });
    gsap.to(container.current, { duration: 0.5, opacity: 1, ease: 'power4.out' });
  };

  const hide = () => {
    gsap.to(container.current, { duration: 0.5, opacity: 0, ease: 'power4.in' });
  };

  /*
   * visuals
   */

  return (
    <Layer size={0.012}>
      <div className={style.container} ref={container}>
        {children}
      </div>
    </Layer>
  );
}
