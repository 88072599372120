import { Howl, Howler } from 'howler';
import { create } from 'zustand';

let sounds = {
  menu: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Animation Short 13.mp3'] }),
};

export function loadSounds() {
  // console.log('useSounds :: loadSounds');
  sounds = {
    menu: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Animation Short 13.mp3'] }),

    generalButton: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Animation 45.mp3'] }),

    bigButton: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Short 17.mp3'] }),

    thud: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Buttons Mechanical 17.mp3'] }),

    showElementLoud: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Buttons Hard 09.mp3'] }),
    showElementWoosh: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Whoosh 17.mp3'] }),
    showElementSmall: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Whoosh 15.mp3'] }),

    showPage: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Animation 19.mp3'] }),
    hidePage: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Beep Low Pitch 12.mp3'] }),

    show: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Pop Up 02.mp3'] }),
    showBig: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Pop Up 35.mp3'] }),
    showButton: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Animation 12.mp3'] }),
    hide: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Pop Up 09.mp3'] }),

    over: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Animation 31.mp3'] }),

    modal: new Howl({ src: ['/sounds/Multimedia Internet CD-Rom Flash Radio Element 47.mp3'] }),

    // music: new Howl({ loop: true, html5: true, src: ['/sounds/loop.mp3'] }),
    music: new Howl({ loop: true, src: ['/sounds/loop.mp3'] }),
    // background: new Howl({ loop: true, html5: true, volume: 0.1, src: ['/sounds/Cosmic-Chatter.mp3'] }),
  };
}

export const useSounds = create((set) => ({
  playSound: (name) => {
    // console.log('playSound :: ' + name);
    let sound;

    if (sounds && Howler._audioUnlocked) {
      sound = sounds[name];
      if (sound) {
        sound.play();
      }
    }

    return sound;
  },
}));
