import { useFrame } from '@react-three/fiber';
import style from './MousePointer.module.css';
import { useEffect, useRef } from 'react';
import { useMouse } from '../../stores/useMouse';
import { useSection } from '../../stores/useSection';
import gsap from 'gsap';

export function MousePointer() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);

  const text = useRef();
  const h = useRef({ x: 0, y: 0 });
  const v = useRef({ x: 0, y: 0 });
  const opacityPct = useRef(0);

  const wrapper = useRef();
  const p1 = useRef();
  const p2 = useRef();
  const p3 = useRef();

  let opacity = 0;
  let lastX = 0;
  let lastY = 0;

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('MousePointer :: onMount');
    gsap.set(h.current, { y: -10 });
    gsap.set(v.current, { x: -10 });

    loop();
  }, []);

  useEffect(() => {
    // gsap.killTweensOf([h.current, v.current, opacityPct]);

    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    // console.log('MousePointer :: show');
    gsap.killTweensOf([h.current, v.current, opacityPct]);

    opacity = 0;

    gsap.set(h.current, { y: -10 });
    gsap.set(v.current, { x: -10 });

    gsap.to(h.current, { y: 0, delay: 1, duration: 1, ease: 'sine.out' });
    gsap.to(v.current, { x: 0, delay: 1, duration: 1, ease: 'sine.out' });

    opacityPct.current = 0;
    gsap.to(opacityPct, { current: 1, delay: 2, duration: 2, ease: 'sine.out' });
  };

  const hide = () => {
    // console.log('MousePointer :: hide');
    gsap.killTweensOf([h.current, v.current, opacityPct]);

    gsap.to(h.current, { y: -10, duration: 0.4, ease: 'sine.in' });
    gsap.to(v.current, { x: -10, duration: 0.4, ease: 'sine.in' });
    gsap.to(opacityPct, { current: 0, duration: 0.4, ease: 'sine.in' });
  };

  const loop = () => {
    const halfW = window.innerWidth * 0.5;
    const halfH = window.innerHeight * 0.5;

    const x = useMouse.getState().slowPctX;
    const y = useMouse.getState().slowPctY;
    const dist = Math.abs(x - lastX) + Math.abs(y - lastY);

    lastX = x;
    lastY = y;

    // gsap.set(p1.current, { x: halfW + x * halfW * 0.59, y: halfH + y * halfH * 0.59 });
    // gsap.set(p2.current, { x: halfW + x * halfW * 0.68, y: halfH + y * halfH * 0.68 });
    // gsap.set(p3.current, { x: halfW + x * halfW * 0.8, y: halfH + y * halfH * 0.8 });

    // gsap.set(p1.current, { x: halfW + x * halfW * .4, y: halfH + y * halfH * .4 });
    // gsap.set(p2.current, { x: halfW + x * halfW * .6, y: halfH + y * halfH * .6 });
    // gsap.set(p3.current, { x: halfW + x * halfW * .8, y: halfH + y * halfH * .8 });

    // gsap.set(p2.current, { x: halfW + x * halfW * .98 , y: halfH + y * halfH * .98  });
    gsap.set(p3.current, { x: halfW + x * halfW, y: halfH + y * halfH });

    gsap.set(h.current, { x: useMouse.getState().slowX });
    gsap.set(v.current, { y: useMouse.getState().slowY });

    opacity *= 0.93;
    opacity = Math.max(opacity, Math.min(3, dist * 25));

    wrapper.current.style.opacity = Math.max(0, Math.min(1, opacity * opacityPct.current));

    text.current.innerText = pad(x) + '//' + pad(y);

    requestAnimationFrame(loop);
  };

  const pad = (num) => {
    return Math.round(num * 100)
      .toString()
      .padStart(3, '0');
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={h} className={style.h}></div>
      <div ref={v} className={style.v}></div>

      <div ref={wrapper} className={style.wrapper}>
        {/* <div ref={p1} className={style.p1}></div> */}
        {/* <div ref={p2} className={style.p2}></div> */}
        <div ref={p3} className={style.p3}>
          <p ref={text} className={style.text}>
            999-999
          </p>
        </div>
      </div>
    </>
  );
}
