import { Line } from './Line';

export function Copy() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {Array.from({ length: 5 }, (_, i) => (
        <Line key={i} index={i} />
      ))}
    </>
  );
}
