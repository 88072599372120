import { create } from 'zustand';

export const useScrollPosition = create((set) => ({
  scrollPosition: 0,
  scrollTo: 0,

  resetScrollPosition: () => set({ scrollPosition: 0 }),
  setScrollPosition: (scrollPosition) => set({ scrollPosition }),
  setScrollTo: (scrollTo) => set({ scrollTo }),
}));
