import { Skull } from './skull/Skull';
import { Lighting } from './staging/Lighting';
import { useEffect } from 'react';
import { useTheatre } from '../../../stores/useTheatre';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import { SheetProvider } from '@theatre/r3f';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { SectionCamera } from '../../components/SectionCamera';
import { ShadowPlane } from './staging/ShadowPlane';
import { Background } from '../../components/background/Background';
import { Copy } from './copy/Copy';
import { Dust } from '../../components/Dust/Dust';
import * as THREE from 'three';
import { Primitives } from '../../components/primitives/Primitives';
import { Panel } from './panel/Panel';
import { useSounds } from '../../../stores/useSounds';
import { useSection } from '../../../stores/useSection';

export function Content() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const playSound = useSounds((state) => state.playSound);
  const project = useTheatre((state) => state.project);
  const sheet = project.sheet('About Section');

  /*
   * hooks
   */

  useEffect(() => {
    setTimeout(() => {
      // playSound('showElementWoosh');
      playSound('thud');
    }, [1000]);
  }, []);

  useFrame(() => {
    const scroll = useScrollPosition.getState().scrollPosition;
    sheet.sequence.position = Math.max(0, scroll) * 7.0;
  });

  /*
   * visuals
   */

  return (
    <>
      <SheetProvider sheet={sheet}>
        <Dust color="#17a772" />
        <Copy />
        <Skull />
        <Lighting />
        <ShadowPlane />
        <Background
          color1={new THREE.Color(0.0, 0.24, 0.12)}
          color2={new THREE.Color(0.0, 0.1, 0.05)}
          color3={new THREE.Color(0.0, 0.17, 0.17)}
          color4={new THREE.Color(0.0, 0.05, 0.07)}
        />
        <Primitives color="#1d7c5b" section="home" />

        <SectionCamera section="home" />
      </SheetProvider>
    </>
  );
}
