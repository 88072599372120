import { BlendFunction, Effect } from 'postprocessing';
import { Uniform } from 'three';
import gsap from 'gsap';

const fragmentShader = /* glsl */ `
    uniform vec3 color;
    uniform float amount;
    
    void mainUv(inout vec2 uv)
    {
        // uv.y *= 1.0 + amount;
    }

    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor)
    {
        outputColor = mix(inputColor, vec4(color, 1.0), amount);
    }
`;

export default class DarkenEffect extends Effect {
  constructor({ color, transition, blendFunction = BlendFunction.NORMAL }) {
    super('DarkenEffect', fragmentShader, {
      blendFunction,
      uniforms: new Map([
        ['color', new Uniform(color)],
        ['amount', new Uniform(0)],
      ]),
    });
    // console.log('DarkenEffect :: constructor');
    // console.log('- transition: ' + transition);

    gsap.killTweensOf(this);
    if (transition === 'shown') {
      this.amount = 0;
    } else if (transition === 'show') {
      this.amount = 1;
      gsap.to(this, { amount: 0, delay: 0.5, ease: 'sine.in' });
    } else if (transition === 'hide') {
      this.amount = 0;
      gsap.to(this, { amount: 1, delay: 0, duration: 0.8, ease: 'power4.in' });
    } else {
      this.amount = 1;
    }
  }

  update(renderer, inputBuffer, deltaTime) {
    this.uniforms.get('amount').value = this.amount;
  }
}
