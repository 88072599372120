import { getProject } from '@theatre/core';
import { create } from 'zustand';

import projectState from '../theatre/state.json';
import { useKTX2 } from '@react-three/drei';

export const useMaps = create((set) => ({
  loaded: false,

  skull: null,

  scratches: null,
  skullColor: null,
  skullNormal: null,
  stamp: null,

  how: null,
  skills: null,
  what: null,
  who: null,

  label1: null,
  label2: null,
  label3: null,
  label4: null,

  arrow: null,
  beehive: null,
  quad: null,
  triangle: null,

  num32: null,
  barcode: null,
  text: null,

  blurredPoint: null,

  cybertext: null,

  init: (skull, maps) =>
    set({
      loaded: true,

      skull: skull,

      scratches: maps[0],
      skullNormal: maps[1],
      skullColor: maps[2],
      stamp: maps[3],

      how: maps[4],
      skills: maps[5],
      what: maps[6],
      who: maps[7],

      label1: maps[8],
      label2: maps[9],
      label3: maps[10],
      label4: maps[11],

      arrow: maps[12],
      beehive: maps[13],
      quad: maps[14],
      triangle: maps[15],

      num32: maps[16],
      barcode: maps[17],
      text: maps[18],

      blurredPoint: maps[19],

      cybertext: maps[20],
    }),
}));
