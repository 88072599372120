import { useNavigate } from 'react-router-dom';
import { Skull } from './skull/Skull';
import { Lighting } from './staging/Lighting';
import { PostProcessing } from './staging/PostProcessing';
import { Staging } from './staging/Staging';
import { useEffect } from 'react';
import { useTheatre } from '../../../stores/useTheatre';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import { SheetProvider } from '@theatre/r3f';
import { Section } from '../../core/Section';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { SectionCamera } from '../../components/SectionCamera';
import { ShadowPlane } from './staging/ShadowPlane';
import { Background } from '../../components/background/Background';
import { Primitives } from '../../components/primitives/Primitives';
// import { ShadowPlane } from './staging/ShadowPlane';
import { Copy } from './copy/Copy';
import * as THREE from 'three';
import { Dust } from '../../components/Dust/Dust';
import { CenterPiece } from './CenterPiece';
import { Panel } from './panel/Panel';
import { World } from './world/World';
import { Halo } from './skull/Halo';
import { useSounds } from '../../../stores/useSounds';

export function Content() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const project = useTheatre((state) => state.project);
  const sheet = project.sheet('Info Section');
  let isSkullShown = false;

  /*
   * hooks
   */

  useFrame(() => {
    const scroll = useScrollPosition.getState().scrollPosition;
    sheet.sequence.position = Math.max(0, scroll) * 7.0;

    if (scroll > 0.83 && !isSkullShown) {
      isSkullShown = true;
      playSound('showElementSmall');
    } else if (scroll < 0.83 && isSkullShown) {
      isSkullShown = false;
      playSound('showElementSmall');
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <SheetProvider sheet={sheet}>
        <Copy />

        <World />
        <Halo />
        <Skull />

        <Lighting />
        <ShadowPlane />

        <Dust color="#149181" />

        <Panel
          index={0}
          position={[0, 1.3, 4.5]}
          rotation={[0, 0, 0]}
          text={[
            'The world is my oyster.',
            'I operate from Leuven, Belgium. However, I work for clients all over the world.',
            'Digital borders are thin.',
          ]}
        />

        <Panel
          index={1}
          position={[1.8, 2.4, 0]}
          rotation={[0, 0, -0.3]}
          link="Mail me."
          text={['Then call me, Slack me,', 'Meet me, Zoom me,', 'WhatsApp me.']}
        />

        <Panel
          index={2}
          position={[1, 1.5, 2]}
          rotation={[0, 0, -0.15]}
          text={['Hope to talk,', 'to you soon.',]}
        />

        <Background
          color1={new THREE.Color(0, 0.06 * 0.65, 0.11 * 0.65)}
          color2={new THREE.Color(0, 0.12 * 0.65, 0.15 * 0.65)}
          color3={new THREE.Color(0, 0.27 * 0.65, 0.35 * 0.65)}
          color4={new THREE.Color(0, 0.21 * 0.65, 0.47 * 0.65)}
        />
        <Primitives color="#00a1c3" section="info" />

        <SectionCamera section="info" />
      </SheetProvider>

      {/* <PostProcessing /> */}
      <Staging />
    </>
  );
}
