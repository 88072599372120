import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { useRef } from 'react';
import * as THREE from 'three';
import { editable as e } from '@theatre/r3f';
import { GradientTexture, Line } from '@react-three/drei';
import { useSounds } from '../../../../stores/useSounds';

export function Mohawk({ index, rotation, position, gradient }) {
  /*
   * properties
   */

  const laser = useRef();
  const laserMaterial = useRef();

  const colorLight = new THREE.Color(1 * 4, 0.17 * 4, 0.25 * 4);

  const playSound = useSounds((state) => state.playSound);
  let isPointShown = false;
  let isCapShown = false;
  let isLaserShown = false;
  const point = useRef();
  const cap = useRef();
  const laserWrapper = useRef();

  // const config = useControls('Mohawk ° reflect', {
  //   position: { value: { x: 0, y: 1.66, z: 2.89 }, min: -5, max: 5, step: 0.01 },
  //   rotation: { value: { x: 1.2, y: 0, z: 0 }, min: -5, max: 5, step: 0.01 },
  //   top: { value: 0, min: 0, max: 1, step: 0.01 },
  //   bot: { value: 0.18, min: 0, max: 1, step: 0.01 },
  //   height: { value: 0.3, min: 0, max: 1, step: 0.01 },
  // });

  const config = {
    position: { x: 0, y: 1.66, z: 2.89 },
    rotation: { x: 1.2, y: 0, z: 0 },
    top: 0,
    bot: 0.18,
    height: 0.3,
  };

  /*
   * hooks
   */

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    laser.current.scale.set(0.95 + Math.random() * 0.1, 1, 0.95 + Math.random() * 0.1);
    // laserMaterial.current.opacity = 0.92 + Math.random() * 0.16;

    const amount = Math.sin(time * 2.2 + index * 1.6); // 0 - 1

    laserMaterial.current.opacity = 0.45 - amount * 0.4;

    let scale = 0.65 + amount * 0.5;

    laser.current.scale.set(scale, 1, scale);

    // if (index === 0) {
    //   console.log(laserWrapper.current.scale);
    // }

    scale = cap.current.scale.x;
    if (scale > .1 && !isCapShown) {
      isCapShown = true;
      playSound('showElementWoosh');
    } else if (scale < .1 && isCapShown) {
      isCapShown = false;
    }

    scale = laserWrapper.current.scale.x;
    if (scale > .1 && !isLaserShown) {
      isLaserShown = true;
      playSound('menu');
    } else if (scale < .1 && isLaserShown) {
      isLaserShown = false;
      playSound('menu');
    }

    const pos = point.current.position.y;
    if (pos <= 0.22 && !isPointShown) {
      isPointShown = true;
      playSound('thud');
    } else if (pos > 0.22 && isPointShown) {
      isPointShown = false;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group position={[position.x, position.y, position.z]} rotation={[rotation.x, rotation.y, rotation.z]}>
        <e.mesh ref={cap} theatreKey={'cap ' + index} position={[0, 0, 0]}>
          <cylinderGeometry args={[config.bot, config.bot + 0.05, 0.15, 20]} />
          <meshBasicMaterial color={colorLight} />
        </e.mesh>

        <e.mesh ref={point} theatreKey={'point ' + index} position={[0, 0.2, 0]}>
          <cylinderGeometry args={[config.top, config.bot, config.height, 20]} />
          <meshStandardMaterial color="#573a00" envMapIntensity={3} roughness={0.3} metalness={0.99} />
        </e.mesh>

        <e.mesh ref={laserWrapper} theatreKey={'laser ' + index}>
          <mesh position={[0, 2.2, 0]} ref={laser}>
            <cylinderGeometry args={[0.7, 0.001, 4, 20, 1, true, true]} />
            <meshBasicMaterial
              ref={laserMaterial}
              color={[0.35, 1.1, 0.8]}
              // side={THREE.DoubleSide}
              transparent={true}
              blending={THREE.AdditiveBlending}
            >
              <GradientTexture
                stops={[0, 0.5]} // As many stops as you want
                colors={gradient} // Colors need to match the number of stops
                size={32} // Size is optional, default = 1024
              />
            </meshBasicMaterial>
          </mesh>
        </e.mesh>
      </group>
    </>
  );
}
