import { useEffect } from 'react';
import { useScrollPosition } from '../../../stores/useScrollPosition';
import { BigButton } from '../../component/BigButton';
import { Scroller } from '../../component/Scroller';
import { SectionContent } from '../../component/SectionContent';
// import style from './About.module.css';
import { Intro } from './Intro';

export function About() {
  /*
   * properties
   */

  // console.log('Work 2D :: render');

  const resetScrollPosition = useScrollPosition((state) => state.resetScrollPosition);

  /*
   * hooks
   */

  useEffect(() => {
    resetScrollPosition();
  }, [])

  /*
   * visuals
   */

  return (
    <>
      <Scroller label="about" />
      <SectionContent>
        <Intro />
        <BigButton label="Work" subLabel="//RECENT PROJECTS" topLabel="ポートフォリオ" link="work" />
      </SectionContent>
    </>
  );
}
