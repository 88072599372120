import { useEffect, useRef, useState } from 'react';
import style from './HireButton.module.css';
import { RandomLabel } from '../component/RandomLabel';
// import SVG from '../shared/svg/hire.svg?react';
import SVG from '../shared/svg/hireBig.svg?react';
import gsap from 'gsap';
import { useHireModal } from '../../stores/useHireModal';
import { useSection } from '../../stores/useSection';
import { useMouse } from '../../stores/useMouse';
import { useSounds } from '../../stores/useSounds';

export function HireButton() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);
  const isTouch = useMouse((state) => state.isTouch);
  const showModal = useHireModal((state) => state.show);
  const container = useRef();
  let parts;
  const [isOver, setIsOver] = useState(false);
  const isFirst = useRef(true);

  /*
   * hooks
   */

  useEffect(() => {
    parts = Array.from(container.current.querySelectorAll('#parts > *'));
    const intervalId = setInterval(render, 1753);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const render = () => {
    for (const part of parts) {
      part.style.opacity = Math.random() > 0.7 ? 0.5 : 1;
    }
  };

  useEffect(() => {
    if (transition === 'show') {
      if (isFirst.current) {
        isFirst.current = false;
      } else {
        show();
      }
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const show = () => {
    const delay = 1.9;

    gsap.killTweensOf(container.current);
    gsap.set(container.current, { x: window.innerWidth * 0.04, opacity: 0, scale: 0.9 });

    gsap.to(container.current, {
      duration: 0.1,
      delay,
      opacity: 1,
      ease: 'power4.out',
    });

    gsap.to(container.current, {
      duration: 0.5,
      delay,
      x: 0,
      scale: 1,
      ease: 'power4.out',
    });
  };

  const hide = () => {
    gsap.killTweensOf(container.current);

    gsap.to(container.current, {
      duration: 0.3,
      delay: 0.1,
      x: window.innerWidth * 0.04,
      opacity: 0,
      scale: 0.9,
      ease: 'power4.in',
    });
  };

  const onMouseOver = (e) => {
    if (!isTouch) {
      // overHandler(index);
      playSound('generalButton');
      setIsOver(true);
    }
  };

  const onMouseOut = () => {
    if (!isTouch) {
      // outHandler();
      playSound('generalButton');
      setIsOver(false);
    }
  };

  const onClick = () => {
    playSound('thud');
    showModal();
  }

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.hire}>
        <button
          aria-label="hire me"
          className={style.button}
          onClick={onClick}
          onMouseEnter={onMouseOver}
          // onMouseOver={onMouseOver}
          onMouseLeave={onMouseOut}
          // onMouseOut={onMouseOut}
          // tabIndex={1}
        >
          <RandomLabel label="Hire/me" active={isOver} />
        </button>
        <SVG />
      </div>
    </>
  );
}
