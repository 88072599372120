import { Decal, useTexture } from '@react-three/drei';
import { useControls } from 'leva';
import { useMemo } from 'react';
import * as THREE from 'three';
import { useMaps } from '../../stores/useMaps';

export function MainDecal({ size, decal, section, name }) {
  /*
   * properties
   */

  // const texture = useTexture(`/textures/${size}/${decal}.png`);

  const texture = useMemo(() => {
    return useMaps.getState()[decal];
  }, []);

  let sectionProps = {};
  let decalProps = {};
  let scale;
  let debug = false;

  if (size === 'square') {
    scale = { x: 1, y: 1, z: 1 };
  } else if (size === 'long') {
    scale = { x: 2, y: 1, z: 1 };
  }

  if (section === 'transparent') {
    sectionProps = {
      metallness: 0,
      roughness: 1,
      envMapIntensity: 0,
    };

    if (name == 'eye1' || name == 'eye2') {
      decalProps.scale = 0.22;
      decalProps.position = { x: 0, y: -2, z: 0 };
      decalProps.rotation = { x: -1.57, y: 0, z: 0 };
      if (name === 'eye2') {
        decalProps.rotation.z = -1.57;
      }

      sectionProps.color = '#50b8f4';
      sectionProps.metallness = 1;
      sectionProps.roughness = 0.11;
      sectionProps.envMapIntensity = 2;
    } else if (name == 'forehead') {
      decalProps.scale = 0.9;
      decalProps.position = { x: 0, y: -8.75, z: 2.5 };
      decalProps.rotation = { x: 1.57, y: 0, z: 0 };

      sectionProps.color = '#7ca89a';
      sectionProps.envMapIntensity = 10;
    } else if (name == 'barcode') {
      decalProps.scale = 1.12;
      decalProps.position = { x: 0, y: 9.23, z: -0.75 };
      decalProps.rotation = { x: 1.34, y: -3.14, z: 0.0 };

      sectionProps.color = '#6a9fc6';
      sectionProps.envMapIntensity = 0.5;
    }
  }

  if (section === 'reflect') {
    sectionProps = {
      metallness: 0.5,
      roughness: 0.15,
      envMapIntensity: 3.5,
    };

    if (name == 'top1') {
      decalProps.scale = 0.6;
      decalProps.position = { x: -2.5, y: -8, z: 4.5 };
      decalProps.rotation = { x: 1.21, y: 0, z: 0 };
      sectionProps.color = '#f450b8';
    } else if (name == 'top2') {
      decalProps.scale = 0.6;
      decalProps.position = { x: -2.5, y: -8, z: 3.7 };
      decalProps.rotation = { x: 1.2, y: 0, z: 0 };
      sectionProps.color = '#f450b8';
    } else if (name == 'barcode') {
      decalProps.scale = 0.85;
      decalProps.position = { x: 0, y: 8.61, z: -1.69 };
      decalProps.rotation = { x: 1.15, y: -3.14, z: 0.0 };
      sectionProps.color = '#f000ff';
    } else if (name == 'triangleRightBot') {
      decalProps.scale = 0.7;
      decalProps.position = { x: -5.75, y: 0.65, z: -2.7 };
      decalProps.rotation = { x: 1.57, y: -1.43, z: 0 };
      sectionProps.color = '#aeff00';
    } else if (name == 'triangleRightTop1') {
      decalProps.scale = 1;
      decalProps.position = { x: -6.75, y: 0.35, z: 2.4 };
      decalProps.rotation = { x: 1.57, y: -1.43, z: 3.14 };
      sectionProps.color = '#aeff00';
    } else if (name == 'triangleRightTop2') {
      decalProps.scale = 0.7;
      decalProps.position = { x: -6.55, y: 0.5, z: 4.2 };
      decalProps.rotation = { x: -1.57, y: -1.43, z: 3.14 };
      sectionProps.color = '#aeff00';
    } else if (name == '32') {
      decalProps.scale = 1.2;
      decalProps.position = { x: -6.24, y: 0.47, z: 0.4 };
      decalProps.rotation = { x: 1.57, y: -1.43, z: 1.57 };
      sectionProps.color = '#aeff00';
    } else if (name == 'triangleLeft1') {
      decalProps.scale = 2.3;
      decalProps.position = { x: 5.45, y: -1.8, z: 0.9 };
      decalProps.rotation = { x: 1.57, y: 1.43, z: -1.57 };
      sectionProps.color = '#eeeeee';
    } else if (name == 'triangleLeft2') {
      decalProps.scale = 0.8;
      decalProps.position = { x: 6.5, y: -0.2, z: 0.9 };
      decalProps.rotation = { x: 1.57, y: 1.43, z: 1.57 };
      sectionProps.color = '#eeeeee';
    } else if (name == 'triangleLeft3') {
      decalProps.scale = 0.5;
      decalProps.position = { x: 6.75, y: 0.55, z: 0.9 };
      decalProps.rotation = { x: 1.57, y: 1.43, z: 1.57 };
      sectionProps.color = '#eeeeee';
    } else if (name == 'text') {
      decalProps.scale = 0.9;
      decalProps.position = { x: 6.75, y: 1.25, z: -0.15 };
      decalProps.rotation = { x: 1.57, y: 1.43, z: 0 };
      sectionProps.color = '#eeeeee';
    } else if (name == 'mole') {
      decalProps.scale = 0.6;
      decalProps.position = { x: 2.1, y: -8.0, z: -2.37 };
      decalProps.rotation = { x: 1.57, y: 0.74, z: 0 };
      sectionProps.color = '#aeff00';
    } else if (name == 'eyebrow') {
      decalProps.scale = 0.8;
      decalProps.position = { x: -2.5, y: -8.34, z: 2.9 };
      decalProps.rotation = { x: 1.57, y: -0.43, z: 0.05 };
      sectionProps.color = '#eeeeee';
    }
  } else if (section === 'cyber') {
    sectionProps = {
      metallness: 0,
      roughness: 1,
      envMapIntensity: 0,
      color: '#4f9800',
    };

    if (name == 'top1') {
      decalProps.scale = 0.5;
      decalProps.position = { x: -1.4, y: -7.4, z: 6.5 };
      decalProps.rotation = { x: 1.21, y: 0, z: 0 };
    } else if (name == 'top2') {
      decalProps.scale = 0.5;
      decalProps.position = { x: -1.4, y: -7.7, z: 5.8 };
      decalProps.rotation = { x: 1.2, y: 0, z: 0 };
    } else if (name == 'barcode') {
      decalProps.scale = 1.1;
      decalProps.position = { x: -6.37, y: 3.66, z: -1.43 };
      decalProps.rotation = { x: -0.18, y: 4.57, z: -1.6 };
    } else if (name == 'forehead') {
      decalProps.scale = 0.6;
      decalProps.position = { x: 0, y: -8.75, z: 1 };
      decalProps.rotation = { x: 1.57, y: 0, z: 0 };
    } else if (name == '32') {
      decalProps.scale = 0.8;
      decalProps.position = { x: -4.96, y: -6.23, z: -1.93 };
      decalProps.rotation = { x: 1.57, y: -0.76, z: 0 };
    } else if (name == 'text') {
      decalProps.scale = 1.25;
      decalProps.position = { x: 5.83, y: 1.85, z: -3.53 };
      decalProps.rotation = { x: -6.81, y: 1.75, z: -3.42 };
    } else if (name == 'noseLeft') {
      decalProps.scale = 0.4;
      decalProps.position = { x: -1.15, y: -8.52, z: -3.9 };
      decalProps.rotation = { x: 1.57, y: 0, z: -3.79 };
    } else if (name == 'noseRight') {
      decalProps.scale = 0.4;
      decalProps.position = { x: 1.15, y: -8.52, z: -3.9 };
      decalProps.rotation = { x: 1.57, y: 0, z: 3.79 };
    } else if (name == 'eyeTopLeft') {
      decalProps.scale = 0.5;
      decalProps.position = { x: -4.56, y: -5.52, z: 0.49 };
      decalProps.rotation = { x: 3.05, y: 1.43, z: 0.01 };
    } else if (name == 'eyeBottomLeft') {
      decalProps.scale = 0.5;
      decalProps.position = { x: -1.49, y: -6.04, z: -0.46 };
      decalProps.rotation = { x: -2.98, y: 1.06, z: 0.0 };
    } else if (name == 'eyeTopRight') {
      decalProps.scale = 0.5;
      decalProps.position = { x: 4.56, y: -5.52, z: 0.49 };
      decalProps.rotation = { x: 3.05, y: 1.86, z: 0.01 };
    } else if (name == 'eyeBottomRight') {
      decalProps.scale = 0.5;
      decalProps.position = { x: 1.49, y: -6.04, z: -0.46 };
      decalProps.rotation = { x: -2.98, y: 2.12, z: 0.0 };
    }
  }

  if (!decalProps || !decalProps.scale) {
    debug = true;

    decalProps = useControls('main decal', {
      // scale: { value: 1, min: 0, max: 10, step: 0.01 },
      // position: { value: { x: 0, y: -8.75, z: 2.4 }, min: -10, max: 10, step: 0.01 },
      // rotation: { value: { x: 1.57, y: 0, z: 0 }, min: -7, max: 7, step: 0.01 },

      scale: { value: 1.1, min: 0, max: 10, step: 0.01 },
      position: { value: { x: -6.44, y: 3.75, z: -0.71 }, min: -10, max: 10, step: 0.01 },
      rotation: { value: { x: -0.18, y: 4.57, z: -1.67 }, min: -7, max: 7, step: 0.01 },
    });
  }

  /*
   * visuals
   */

  return (
    <>
      <Decal
        debug={debug}
        position={[decalProps.position.x, decalProps.position.y, decalProps.position.z]}
        rotation={[decalProps.rotation.x, decalProps.rotation.y, decalProps.rotation.z]}
        scale={[scale.x * decalProps.scale, scale.y * decalProps.scale, scale.z * decalProps.scale]}
      >
        <meshStandardMaterial
          polygonOffset
          polygonOffsetFactor={-10}
          // depthWrite={false}
          alphaMap={texture}
          transparent={true}
          metalness={sectionProps.metallness}
          roughness={sectionProps.roughness}
          envMapIntensity={sectionProps.envMapIntensity}
          color={sectionProps.color}
        />
      </Decal>
    </>
  );
}
