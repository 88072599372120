import { Environment, OrthographicCamera, useHelper, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { folder, useControls } from 'leva';
import { useEffect, useRef } from 'react';
import { CameraHelper, DirectionalLightHelper, SpotLightHelper } from 'three';
import { useResponsive } from '../../../../stores/useResponsive';
// import { useControls } from 'leva';

export function Lighting() {
  /*
   * properties
   */

  const x = useResponsive((state) => state.x);
  const y = useResponsive((state) => state.y);

  // const test = useTexture('./textures/environment/environment-min.jpg');

  const shadowLight = useRef();
  const light1 = useRef();
  const light2 = useRef();

  const camera = useRef();

  // const config = useControls('lights ° reflect', {
  //   helpers: { value: true },
  //   'directional light 1': folder({
  //     // positionLight1: { value: { x: -2, y: 2, z: 3 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //     intensityLight1: { value: 12, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorLight1: { value: '#be66ff', label: 'color' },
  //   }),
  //   'directional light 2': folder({
  //     // positionLight2: { value: { x: 2, y: -1, z: 2 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //     intensityLight2: { value: 8, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorLight2: { value: '#00ecff', label: 'color' },
  //   }),
  //   'shadow directional light': folder({
  //     positionShadow: { value: { x: -2, y: 1, z: 4 }, min: -15, max: 15, step: 0.01, label: 'position' },
  //     intensityShadow: { value: 2, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorShadow: { value: '#ffffff', label: 'color' },
  //   }),
  //   'shadow camera': folder({
  //     left: { value: -7, min: -10, max: 10, step: 0.01 },
  //     right: { value: 7, min: -10, max: 10, step: 0.01 },
  //     top: { value: 7, min: -10, max: 10, step: 0.01 },
  //     bottom: { value: -6, min: -10, max: 10, step: 0.01 },
  //     far: { value: 20, min: 0, max: 50, step: 0.01 },
  //   }),
  // });

  const config = {
    helpers: true,
    intensityLight1: 12,
    colorLight1: '#be66ff',
    intensityLight2: 8,
    colorLight2: '#00ecff',
    positionShadow: { x: -2, y: 1, z: 4 },
    intensityShadow: 2,
    colorShadow: '#ffffff',
    left: -7,
    right: 7,
    top: 7,
    bottom: -6,
    far: 20,
  };

  // useHelper(light1, DirectionalLightHelper);
  // useHelper(light2, DirectionalLightHelper);
  // useHelper(shadowLight, DirectionalLightHelper);
  // useHelper(camera, CameraHelper);

  /*
   * hooks
   */

  useFrame((state, delta) => {
    const time = state.clock.elapsedTime;
    const radius = 3;

    let angle = (time + 0) * 0.153;
    light1.current.position.x = Math.sin(angle) * radius;
    light1.current.position.y = Math.cos(angle) * radius;
    light1.current.position.z = 3;

    angle = -(time + 2) * 0.247;
    light2.current.position.x = Math.sin(angle) * radius;
    light2.current.position.y = Math.cos(angle) * radius;
    light2.current.position.z = 3;
  });

  useEffect(() => {
    // console.log(x);
    light1.current.target.position.x = x;
    light2.current.target.position.x = x;
    shadowLight.current.target.position.x = x * 0.4;
  }, [x]);

  useEffect(() => {
    // console.log(y);
    light1.current.target.position.y = y;
    light2.current.target.position.y = y;
    shadowLight.current.target.position.y = y * 0.2;
  }, [y]);

  /*
   * visuals
   */

  return (
    <>
      <directionalLight
        ref={shadowLight}
        position={[config.positionShadow.x, config.positionShadow.y, config.positionShadow.z]}
        intensity={config.intensityShadow}
        color={config.colorShadow}
        castShadow
      >
        <orthographicCamera
          attach="shadow-camera"
          ref={camera}
          far={config.far}
          top={config.top}
          bottom={config.bottom}
          left={config.left}
          right={config.right}
        />
      </directionalLight>

      {shadowLight && shadowLight.current && <primitive object={shadowLight.current.target} />}

      <directionalLight
        ref={light1}
        // position={[config.positionLight1.x, config.positionLight1.y, config.positionLight1.z]}
        intensity={config.intensityLight1}
        color={config.colorLight1}
      />

      {light1 && light1.current && <primitive object={light1.current.target} />}

      <directionalLight
        ref={light2}
        // position={[config.positionLight2.x, config.positionLight2.y, config.positionLight2.z]}
        intensity={config.intensityLight2}
        color={config.colorLight2}
      />

      {light2 && light2.current && <primitive object={light2.current.target} />}

      {/* <Environment
        background={false}
        // blur={.1}
        // map={test}
        path="./textures/environment/"
        files={[
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
        ]}
      /> */}
    </>
  );
}
