import { Decal } from '@react-three/drei';
import { useControls } from 'leva';

export function StampDecal({ texture }) {
  /*
   * properties
   */

  // const { debug, scale, position, rotation, color, alpha } = useControls('transparent decal', {
  //   debug: { value: false },
  //   scale: { value: 6.3, min: 0, max: 20, step: 0.01 },
  //   position: { value: { x: 0, y: 7.8, z: 1.8 }, min: -8, max: 8, step: 0.01 },
  //   rotation: { value: { x: 1.57, y: 3.14, z: 0 }, min: -7, max: 7, step: 0.01 },
  //   color: { value: '#c1e5ff' },
  //   alpha: { value: 0.75, min: 0, max: 2, step: 0.01 },
  // });

  /*
   * visuals
   */

  return (
    <>
      {/* <Decal
        debug={debug}
        position={[position.x, position.y, position.z]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        scale={scale} // Scale of the decal
      >
        <meshBasicMaterial alphaMap={texture} opacity={alpha} transparent={true} depthTest={false} color={color} />
      </Decal> */}

      <Decal
        debug={false}
        position={[0, 7.8, 1.8]}
        rotation={[1.57, 3.14, 0]}
        scale={6.3} // Scale of the decal
      >
        <meshBasicMaterial alphaMap={texture} opacity={0.75} transparent={true} depthTest={false} color="#c1e5ff" />
      </Decal>
    </>
  );
}
