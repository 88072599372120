import './style.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { StrictMode, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { App3D } from './app3D/App3D';
import { App2D } from './app2D/App2D';
// import { Sound } from './sound/Sound';
import { MouseController } from './controllers/MouseController';
import { SectionController } from './controllers/SectionController';
import { Loader } from './Loader';
import smoothscroll from 'smoothscroll-polyfill';

/*
 * THEATRE studio start, ENABLE for studio
 */

// import studio from '@theatre/studio';
// import extension from '@theatre/r3f/dist/extension';

// if (import.meta.env.DEV) {
//   studio.initialize();
//   studio.extend(extension);
// }

/*
 * THEATRE studio end
 */

const history = createBrowserHistory({ window });
const root = ReactDOM.createRoot(document.querySelector('#root'));

smoothscroll.polyfill();

root.render(
  <>
    {/* <StrictMode> */}
    <BrowserRouter history={history}>
      <MouseController />
      <SectionController />

      <Loader />
      <App2D />

      {/* THEATRE studio start, ENABLE for studio */}
      {/* <div style={{ position: 'absolute', left: '200px', width: 'calc(100vh - 450px)', height: 'calc(100vh - 450px)' }}> */}
      {/* THEATRE studio end */}

      <Suspense>
        <App3D />
      </Suspense>

      {/* THEATRE studio start, ENABLE for studio */}
      {/* </div> */}
      {/* THEATRE studio end */}
    </BrowserRouter>
    {/* </StrictMode> */}
  </>
);

function BrowserRouter({ children, history }) {
  let [state, dispatch] = React.useReducer((_, action) => action, {
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(dispatch), [history]);

  return <Router children={children} action={state.action} location={state.location} navigator={history} />;
}
