import { create } from 'zustand';

export const useSection = create((set) => ({
  transition: 'start', // the transition state ('hide' > 'load' > 'show' > 'shown')
  route: '', // the route to where to go current the transition has finished
  isKnownLocation: false, // true if the current route is one of the known sections

  setStartRoute: (route) => set({ route }),

  // start loading the new route
  hide: (route) =>
    set((state) => {
      return state.route === route || state.transition === 'hide'
        ? { transition: state.transition }
        : { transition: 'hide', route };
    }),

  // start loading the new route
  startLoad: () => set({ transition: 'load' }),

  // end the transition
  show: () => set({ transition: 'show' }),

  end: () => set({ transition: 'shown' }),

  // set the current route location
  setLocation: (route) => set({ isKnownLocation: ['home', 'about', 'work', 'info'].includes(route), route }),
}));
