import { useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { useGLTF, useKTX2 } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';
import { StampDecal } from './StampDecal';
import { Eye } from './Eye';
import { Crusifix } from './Crusifix';
import { Mohawk } from './Mohawk';
import { MainDecal } from '../../../components/MainDecal';
import { editable as e } from '@theatre/r3f';
import { useMouse } from '../../../../stores/useMouse';
import { useMaps } from '../../../../stores/useMaps';
import { useScrollPosition } from '../../../../stores/useScrollPosition';

export function Skull() {
  /*
   * properties
   */

  const skull = useRef();
  const inner = useRef();

  const skullRotation = useRef(0);
  const rotationSpeedPct = useRef(0);

  const material = useRef();
  const [theatreObject, setTheatreObject] = useState(null);

  // const { scratches, stamp, skullColor, skullNormal } = useMaps();

  const { scratches, stamp, skullNormal } = useMemo(() => {
    return {
      scratches: useMaps.getState().scratches,
      stamp: useMaps.getState().stamp,
      skullNormal: useMaps.getState().skullNormal,
    };
  }, []);

  // const [scratches, skullNormal, skullColor, stamp] = useKTX2([
  //   'textures/shared/scratches.ktx2',
  //   'textures/shared/skullNormal.ktx2',
  //   'textures/shared/skullColor.ktx2',
  //   'textures/shared/stamp.ktx2',
  // ]);

  const scratch = scratches.clone();
  scratch.wrapS = THREE.MirroredRepeatWrapping;
  scratch.wrapT = THREE.MirroredRepeatWrapping;
  scratch.repeat.set(1, 3);

  // const materialConfig = useControls('skull material ° reflect', {
  //   metallness: { value: 1.0, min: 0, max: 2, step: 0.01 },
  //   roughness: { value: 0.25, min: 0, max: 1, step: 0.01 },
  //   envMapIntensity: { value: 4, min: 0, max: 10, step: 0.01 },
  //   normalScale: { value: 0.5, min: -5, max: 5, step: 0.01 },
  //   color: { value: '#1d1d1d' },
  // });

  const materialConfig = {
    metallness: 1.0,
    roughness: 0.25,
    envMapIntensity: 4,
    normalScale: 0.5,
    color: '#1d1d1d',
  };

  // const model = useGLTF('models/skull/Human_Skull.gltf');
  const model = useMaps.getState().skull;

  /*
   * hooks
   */

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        material.current.envMapIntensity = data.envMapIntensity;
        material.current.normalScale.set(data.normalScale, data.normalScale);
        material.current.roughness = data.roughness;
        material.current.metalness = data.metalness;
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  useFrame((state, delta) => {
    const { slowPctX, slowPctY } = useMouse.getState();
    // console.log(slowPctX)

    if (useScrollPosition.getState().scrollPosition < 0.98) {
      skullRotation.current += (0 - skullRotation.current) * 0.05;
      rotationSpeedPct.current = 0;
    } else {
      rotationSpeedPct.current += (1 - rotationSpeedPct.current) * 0.01;

      const rotationSpeed = delta * 0.5;
      skullRotation.current += rotationSpeed * rotationSpeedPct.current;

      if (skullRotation.current > Math.PI) {
        skullRotation.current -= Math.PI * 2;
      }
    }

    inner.current.rotation.y = slowPctX * 0.022 + skullRotation.current;
    inner.current.rotation.x = -slowPctY * 0.05;
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group
        theatreKey="skull"
        ref={skull}
        position={[0, -0.4, 0]}
        scale={[1, 1, 1]}
        additionalProps={{
          envMapIntensity: 4,
          normalScale: 0.25,
          roughness: 0.25,
          metalness: 1,
        }}
        objRef={setTheatreObject}
      >
        <group ref={inner}>
          <mesh geometry={model.nodes.skull.geometry} scale={[0.35, 0.35, 0.35]} rotation={[-1.571, 0, 0]}>
            <meshStandardMaterial
              ref={material}
              color={materialConfig.color}
              metalnessMap={scratch}
              metalness={materialConfig.metallness}
              roughness={materialConfig.roughness}
              envMapIntensity={materialConfig.envMapIntensity}
              normalMap={skullNormal}
              normalScale={[materialConfig.normalScale, materialConfig.normalScale]}
            />

            <MainDecal size="square" section="reflect" decal="arrow" name="top1" />
            <MainDecal size="square" section="reflect" decal="triangle" name="top2" />
            <MainDecal size="square" section="reflect" decal="quad" name="eyebrow" />

            <MainDecal size="square" section="reflect" decal="beehive" name="triangleRightBot" />
            <MainDecal size="long" section="reflect" decal="num32" name="32" />
            <MainDecal size="square" section="reflect" decal="arrow" name="triangleRightTop1" />
            <MainDecal size="square" section="reflect" decal="triangle" name="triangleRightTop2" />

            <MainDecal size="square" section="reflect" decal="triangle" name="triangleLeft1" />
            <MainDecal size="square" section="reflect" decal="arrow" name="triangleLeft2" />
            <MainDecal size="square" section="reflect" decal="arrow" name="triangleLeft3" />
            <MainDecal size="long" section="reflect" decal="text" name="text" />

            <MainDecal size="long" section="reflect" decal="barcode" name="barcode" />
            <MainDecal size="square" section="reflect" decal="arrow" name="mole" />

            <StampDecal texture={stamp} />
          </mesh>

          <Eye left={true} />
          <Eye left={false} />

          <Mohawk
            index={0}
            rotation={{ x: 1.2, y: 0, z: 0 }}
            position={{ x: 0, y: 1.66, z: 2.89 }}
            gradient={['rgb(0%, 00%, 0%)', 'rgb(60%, 100%, 100%)']}
          />
          <Mohawk
            index={1}
            rotation={{ x: 1.03, y: 0, z: 0 }}
            position={{ x: 0, y: 2.42, z: 2.59 }}
            gradient={['rgb(0%, 00%, 0%)', 'rgb(100%, 100%, 60%)']}
          />
          <Mohawk
            index={2}
            rotation={{ x: 0.6, y: 0, z: 0 }}
            position={{ x: 0, y: 3.04, z: 2.05 }}
            gradient={['rgb(0%, 00%, 0%)', 'rgb(100%, 60%, 100%)']}
          />

          <Crusifix />
        </group>
      </e.group>
    </>
  );
}
