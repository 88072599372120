import { Decal, RenderTexture, RoundedBox, useVideoTexture } from '@react-three/drei';
import { folder, useControls } from 'leva';
import { EyeContent } from './EyeContent';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { editable as e } from '@theatre/r3f';
import * as THREE from 'three';
import { useProjects } from '../../../../stores/useProjects';

export function Eye({ left }) {
  /*
   * properties
   */

  const roundedBox = useRef();
  const material = useRef();
  const decal = useRef();

  const [videoSrc, setVideoSrc] = useState(null);

  const activeProject = useProjects((state) => state.activeProject);

  // const colorLine = new THREE.Color(1 * 4, 0.17 * 4, 0.25 * 4);
  const colorLine = new THREE.Color(1, 1, 1);

  // const config = useControls('eye ° reflect', {
  //   'tv group': folder({
  //     position: { value: { x: 1.04, y: 0.02, z: 2.15 }, min: -8, max: 8, step: 0.01 },
  //     rotation: { value: { x: -0.18, y: 0.24, z: 0.12 }, min: -8, max: 8, step: 0.01 },
  //   }),

  //   'tv material': folder({
  //     metallness: { value: 1.0, min: 0, max: 2, step: 0.01 },
  //     roughness: { value: 0.2, min: 0, max: 1, step: 0.01 },
  //     envMapIntensity: { value: 1.5, min: 0, max: 10, step: 0.01 },
  //     color: { value: '#222222' },
  //   }),

  //   'eye decal': folder({
  //     decalPosition: { value: { x: 0, y: 0, z: 0.12 }, min: -8, max: 8, step: 0.01 },
  //   }),
  // });

  const config = {
    position: { x: 1.04, y: 0.02, z: 2.15 },
    rotation: { x: -0.18, y: 0.24, z: 0.12 },
    metallness: 1.0,
    roughness: 0.2,
    envMapIntensity: 1.5,
    color: '#222222',
    decalPosition: { x: 0, y: 0, z: 0.12 },
  };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    material.current.opacity = 0.8 + Math.random() * 0.2;
    decal.current.scale.y = 0.95 + Math.random() * 0.1;
  });

  useEffect(() => {
    if (!left) {
      // console.log('Eye :: set activeProject');
      // console.log(activeProject);

      if (activeProject > -1) {
        const project = useProjects.getState().projects[activeProject];
        // console.log(project);
        setVideoSrc('/video/' + project.video);
      }
    }
  }, [activeProject]);

  /*
   * visuals
   */

  return (
    <>
      <e.group
        theatreKey={left ? 'eye left' : 'eye right'}
        position={[left ? -config.position.x : config.position.x, config.position.y, config.position.z]}
        rotation={[
          config.rotation.x,
          left ? -config.rotation.y : config.rotation.y,
          left ? -config.rotation.z : config.rotation.z,
        ]}
      >
        <RoundedBox
          ref={roundedBox}
          args={[0.9, 0.75, 0.25]} // Width, height, depth. Default is [1, 1, 1]
          radius={0.05} // Radius of the rounded corners. Default is 0.05
          smoothness={1} // The number of curve segments. Default is 4
          bevelSegments={1} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
          creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
        >
          <meshStandardMaterial
            metallness={config.metallness}
            roughness={config.roughness}
            envMapIntensity={config.envMapIntensity}
            color={config.color}
          />
        </RoundedBox>

        <Decal
          ref={decal}
          mesh={roundedBox}
          scale={[0.75, 0.6, 0.1]}
          // debug={true}
          position={[config.decalPosition.x, config.decalPosition.y, config.decalPosition.z]}
        >
          <meshBasicMaterial ref={material} roughness={0.6} polygonOffset polygonOffsetFactor={0}>
            <RenderTexture attach="map" width={64} height={64}>
              <EyeContent left={left} />
            </RenderTexture>
          </meshBasicMaterial>
        </Decal>

        {!left && (
          <Decal
            mesh={roundedBox}
            scale={[0.8, -0.66, 0.1]}
            // debug={true}
            position={[config.decalPosition.x, config.decalPosition.y, -config.decalPosition.z]}
          >
            <Suspense
              fallback={
                <meshStandardMaterial
                  color="#000000"
                  transparent
                  opacity={0.6}
                  polygonOffset
                  polygonOffsetFactor={-10}
                />
              }
            >
              <VideoMaterial src={videoSrc} />
            </Suspense>
          </Decal>
        )}
      </e.group>
    </>
  );
}

function VideoMaterial({ src }) {
  const texture = useVideoTexture(src);

  return (
    <meshBasicMaterial
      side={THREE.DoubleSide}
      map={texture}
      toneMapped={false}
      polygonOffset
      polygonOffsetFactor={-10}
    />
  );
}
