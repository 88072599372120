import { Environment} from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';

export function SharedLight() {
  /*
   * properties
   */


  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <Environment
        background={false}
        path="./textures/environment/"
        files={[
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
          'environment-min.jpg',
        ]}
      />
    </>
  );
}
