import { useHelper } from '@react-three/drei';
import { useControls } from 'leva';
import { useEffect, useRef, useState } from 'react';
import { BoxHelper, PlaneHelper } from 'three';
import { editable as e } from '@theatre/r3f';

export function ShadowPlane() {
  /*
   * properties
   */

  const mesh = useRef();
  const material = useRef();

  const [theatreObject, setTheatreObject] = useState(null);

  // useHelper(mesh, BoxHelper);

  // const shadowConfig = useControls('shadow plane ° home', {
  //   position: { value: { x: 0, y: 0, z: -5 }, min: -5, max: 5, step: 0.01 },
  //   rotation: { value: { x: 0, y: -0.1, z: 0 }, min: -5, max: 5, step: 0.01 },
  //   opacity: { value: 0.5, min: 0, max: 2, step: 0.01 },
  //   color: '#0c4541',
  // });

  const shadowConfig = {
    position: { x: 0, y: 0, z: -5 },
    rotation: { x: 0, y: -0.1, z: 0 },
    opacity: 0.5,
    color: '#0c4541',
  };

  /*
   * hooks
   */

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        material.current.opacity = data.opacity;
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  /*
   * visuals
   */

  return (
    <>
      <e.mesh
        theatreKey="shadowPlane"
        ref={mesh}
        position={[shadowConfig.position.x, shadowConfig.position.y, shadowConfig.position.z]}
        rotation={[shadowConfig.rotation.x, shadowConfig.rotation.y, shadowConfig.rotation.z]}
        receiveShadow
        additionalProps={{
          opacity: 0.5,
        }}
        objRef={setTheatreObject}
      >
        <planeGeometry args={[22, 18]} />
        <shadowMaterial
          ref={material}
          transparent={true}
          opacity={shadowConfig.opacity}
          color={shadowConfig.color}
          depthWrite={false}
        />
        {/* <meshBasicMaterial ref={material} color="red" /> */}
      </e.mesh>
    </>
  );
}
