import { BigButton } from '../../component/BigButton';
import { Scroller } from '../../component/Scroller';
import { SectionContent } from '../../component/SectionContent';

export function Info() {
  /*
   * properties
   */

  // console.log('Work 2D :: render');

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <Scroller label="info" />
      <SectionContent>
        <BigButton label="HOME" subLabel="//GO BACK HOME" topLabel="ホームページ" link="work" />
      </SectionContent>
    </>
  );
}
