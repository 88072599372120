import { useEffect, useRef } from 'react';
import { Billboard, shaderMaterial } from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { Primitive } from './Primitive';

export function Primitives({ color, section }) {
  /*
   * properties
   */

  const material = new THREE.MeshStandardMaterial({ color: color, transparent: true, opacity: 0.5, flatShading: true });

  /*
   * hooks
   */

  useFrame((state, delta) => {});

  /*
   * visuals
   */

  return (
    <>
      <group position={[0, 0, -7]}>
        {[...Array(12)].map((value, index) => (
          <Primitive key={index} index={index} section={section} material={material} />
        ))}
      </group>
    </>
  );
}
