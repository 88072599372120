import { useEffect, useRef } from 'react';
import { useSection } from '../stores/useSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSounds } from '../stores/useSounds';

export function SectionController() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const transition = useSection((state) => state.transition);
  const startLoad = useSection((state) => state.startLoad);
  const end = useSection((state) => state.end);

  const navigate = useNavigate();
  const location = useLocation();
  const timeoutId = useRef();

  /*
   * hooks
   */

  // start
  useEffect(() => {
    // console.log('detecting transition change: ' + transition)

    clearTimeout(timeoutId.current);

    if (transition === 'show') {
      playSound('showPage');
      timeoutId.current = setTimeout(() => {
        end();
      }, [1000]);
    } else if (transition === 'hide') {
      playSound('hidePage');
      timeoutId.current = setTimeout(() => {
        let route = useSection.getState().route;
        if (route === 'home') {
          route = '';
        }
        navigate(route);
      }, [1000]);
    }
  }, [transition]);

  useEffect(() => {
    startLoad();
  }, [location]);
}
