import { Bloom, ChromaticAberration, EffectComposer, Vignette } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import { folder, useControls } from 'leva';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import Darken from './Darken';
import * as THREE from 'three';
import Liquid from './Liquid';
import { useMouse } from '../../stores/useMouse';
import { useSection } from '../../stores/useSection';
import { Displacement } from './Displacement';

export function PostProcessing({ dpr }) {
  // console.log('PostProcessing render');
  /*
   * properties
   */

  const inited = useMouse((state) => state.inited);
  const isTouch = useMouse((state) => state.isTouch);

  const [showLiquid, setShowLiquid] = useState(false);
  const [texture, setTexture] = useState(null);

  const transition = useSection((state) => state.transition);

  // const config = useControls('main processing', {
  //   vignette: folder({
  //     vignetteEnabled: { value: true, label: 'enabled' },
  //     offset: { value: 0.14, min: 0, max: 2, label: 'offset' },
  //     darkness: { value: 0.9, min: 0, max: 2, label: 'darkness' },
  //   }),

  //   bloom: folder({
  //     bloomEnabled: { value: true, label: 'enabled' },
  //     intensity: { value: 2, min: 0, max: 40, step: 0.1, label: 'intensity' },
  //     luminanceThreshold: { value: 1, min: 0, max: 10, label: 'threshold' },
  //     luminanceSmoothing: { value: 0.1, min: 0, max: 0.2, label: 'smoothing' },
  //     mipmapBlur: { value: true, label: 'mipmapBlur' },
  //   }),

  //   brightness: folder({
  //     brightness: { value: 0, min: -3, max: 3, label: 'brightness' },
  //     contrast: { value: 0, min: -3, max: 3, label: 'contrast' },
  //   }),
  // });

  useEffect(() => {
    if (inited && !isTouch) {
      setShowLiquid(true);
    }
  }, [inited, isTouch]);

  /*
   * visuals
   */

  return (
    <>
      {showLiquid && <Displacement setTexture={setTexture} />}

      <EffectComposer key={dpr}>
        {texture && <Liquid texture={texture} blendFunction={BlendFunction.NORMAL} />}

        <ChromaticAberration
          blendFunction={BlendFunction.NORMAL} // blend mode
          offset={[0.0035, 0.0035]} // color offset
          radialModulation={true}
        />

        <Vignette offset={0.14} darkness={0.9} blendFunction={BlendFunction.NORMAL} />

        <Bloom
          intensity={2}
          luminanceThreshold={1} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.1} // smoothness of the luminance threshold. Range is [0, 1]
          mipmapBlur={true} // Enables or disables mipmap blur.
        />

        <Darken transition={transition} color={new THREE.Color('#0c0c0c')} blendFunction={BlendFunction.NORMAL} />
      </EffectComposer>
    </>
  );
}
