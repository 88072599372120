import style from './Arrow1.module.css';
import SVG from './svg/arrow1.svg?react';

export function Arrow1() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={style.arrow1}>
        <SVG />
      </div>
    </>
  );
}
