import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import * as THREE from 'three';
import { MainDecal } from '../../../components/MainDecal';
import { editable as e } from '@theatre/r3f';
import { useSounds } from '../../../../stores/useSounds';
// import { editable as e, SheetProvider } from '@theatre/r3f';

export function Eye({ positionX, rotationZ, blink, index }) {
  /*
   * properties
   */

  const material = useRef();
  let isOn = true;
  let nextTime = 0;

  const colorLight = new THREE.Color(1 * 4, 0.17 * 4, 0.25 * 4);
  const colorDark = new THREE.Color(1 * 0.02, 0.17 * 0.02, 0.25 * 0.02);

  const group = useRef();
  const playSound = useSounds((state) => state.playSound);
  const isShown = useRef(false);

  /*
   * hooks
   */

  useFrame((state) => {
    if (blink) {
      const time = state.clock.elapsedTime;

      if (time > nextTime) {
        const rnd = Math.random();

        if (rnd > 0.8) {
          nextTime = time + rnd * 3;
        } else {
          nextTime = time + 0.01 + rnd * 0.1;
        }

        isOn = !isOn;

        if (isOn) {
          // console.log('light');
          material.current.color.copy(colorLight);
        } else {
          // console.log('dark');
          material.current.color.copy(colorDark);
        }
      }
    }

    const scale = group.current.scale.x;
    if (scale > 0.75 && !isShown.current) {
      isShown.current = true;
      playSound('thud');
    } else if (scale < 0.75 && isShown.current) {
      isShown.current = false;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group ref={group} theatreKey={'eye' + index}>
        <mesh position={[positionX * 0.7, 0.1, -0.43]} rotation={[-1.51, 0, rotationZ]}>
          <cylinderGeometry args={[0.1, 0.2, 4, 16]} />
          <meshStandardMaterial color="#573a00" envMapIntensity={1} roughness={0.15} metalness={0.99} />
          <MainDecal
            size="square"
            section="transparent"
            decal={blink ? 'arrow' : 'triangle'}
            name={blink ? 'eye1' : 'eye2'}
          />
        </mesh>

        <mesh theatreKey="testje" position={[positionX, -0.01, 1.65]} rotation={[0, 0, 0]}>
          <torusGeometry args={[0.2, 0.03, 10, 20]} />
          <meshBasicMaterial ref={material} color={colorLight} />
        </mesh>
      </e.group>
    </>
  );
}
