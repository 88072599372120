import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useMouse } from '../stores/useMouse';
import style from './Layer.module.css';

export function Layer({ children, size, relative }) {
  /*
   * properties
   */

  const container = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    loop();

    return () => {};
  }, []);

  const loop = () => {
    if (container.current) {
      const { slowPctX, slowPctY, xMax } = useMouse.getState();

      gsap.set(container.current, { x: slowPctX * -size * xMax, y: slowPctY * -size * xMax });

      requestAnimationFrame(loop);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.layer} ${relative ? style.relative : ''}`}>
        {children}
      </div>
    </>
  );
}
