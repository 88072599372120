import { useEffect, useRef, useState } from 'react';
import style from './BigButton.module.css';
import gsap from 'gsap';
import { useScrollPosition } from '../../stores/useScrollPosition';
import { RandomLabel } from './RandomLabel';
import SVG from '../shared/svg/bigButton.svg?react';
import { useSection } from '../../stores/useSection';
import { useMouse } from '../../stores/useMouse';
import { useSounds } from '../../stores/useSounds';

export function BigButton({ label, subLabel, topLabel, link }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const [visible, setVisible] = useState(false);
  const isTouch = useMouse((state) => state.isTouch);
  const hideSection = useSection((state) => state.hide);
  const isFirst = useRef(false);

  const wrapper = useRef();
  const button = useRef();
  const [isOver, setIsOver] = useState(false);

  const onMouseOver = (index) => {
    // console.log('over');
    if (!isTouch) {
      playSound('bigButton');
      setIsOver(true);
    }
  };

  const onMouseOut = () => {
    // console.log('out');
    if (!isTouch) {
      playSound('bigButton');
      setIsOver(false);
    }
  };

  const onClick = () => {
    // console.log('klikkerdeklik');
    playSound('thud');
    hideSection(link);
  };

  /*
   * hooks
   */

  useEffect(() => {
    const timeoutId = setTimeout(loop, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      playSound('showButton');
    } else {
      if (useSection.getState().transition === 'shown') {
        playSound('hide');
      }
      // if (isFirst.current) {
      //   isFirst.current = false;
      // } else {
      //   // playSound('hide');
      // }
    }
  }, [visible]);

  const loop = () => {
    if (wrapper.current) {
      let scrollPosition = useScrollPosition.getState().scrollPosition;
      setVisible(scrollPosition > 0.99);
      requestAnimationFrame(loop);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.wrapper} ref={wrapper}>
        <button
          aria-label={label}
          // tabIndex={6}
          disabled={!visible}
          ref={button}
          className={`${style.button} ${visible ? style.visible : ''}`}
          onClick={onClick}
          onMouseEnter={onMouseOver}
          // onMouseOver={onMouseOver}
          onMouseLeave={onMouseOut}
          // onMouseOut={onMouseOut}
        >
          <SVG />
          <div className={style.topLabel}>{topLabel}</div>
          <RandomLabel label={label} active={isOver} />
          <div className={style.subLabel}>{subLabel}</div>
        </button>
      </div>
    </>
  );
}
