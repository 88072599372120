import { RoundedBox } from '@react-three/drei';
import { useControls } from 'leva';
import { GogglesDecal } from './GogglesDecal';
import { useRef } from 'react';

export function Goggles({ material }) {
  const box = useRef();

  // const config = useControls('goggles', {
  //   width: { value: 3.58, min: -5, max: 5, step: 0.01 },
  //   height: { value: 1.38, min: -5, max: 5, step: 0.01 },
  //   depth: { value: 1.54, min: -5, max: 5, step: 0.01 },
  //   radius: { value: 0.49, min: 0, max: 1, step: 0.01 },
  //   smoothness: { value: 2, min: 0, max: 10, step: 1 },
  //   bevelSegments: { value: 2, min: 0, max: 10, step: 1 },
  //   position: { value: { x: 0, y: 0.11, z: 2.44 }, min: -5, max: 5, step: 0.01 },
  //   rotation: { value: { x: -0.23, y: 0, z: 0 }, min: -5, max: 5, step: 0.01 },
  // });

  const config = {
    width: 3.58,
    height: 1.38,
    depth: 1.54,
    radius: 0.49,
    smoothness: 2,
    bevelSegments: 2,
    position: { x: 0, y: 0.11, z: 2.44 },
    rotation: { x: -0.23, y: 0, z: 0 },
  };

  return (
    <>
      <group
        position={[config.position.x, config.position.y, config.position.z]}
        rotation={[config.rotation.x, config.rotation.y, config.rotation.z]}
      >
        <RoundedBox
          ref={box}
          args={[config.width, config.height, config.depth]} // Width, height, depth. Default is [1, 1, 1]
          radius={config.radius} // Radius of the rounded corners. Default is 0.05
          smoothness={config.smoothness} // The number of curve segments. Default is 4
          bevelSegments={config.bevelSegments} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
          creaseAngle={0.5} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
          material={material}
        ></RoundedBox>

        <GogglesDecal mesh={box} part="d1" />

        <GogglesDecal mesh={box} part="l1" />
        <GogglesDecal mesh={box} part="l2" />
        <GogglesDecal mesh={box} part="l3" />
        <GogglesDecal mesh={box} part="l4" />

        <GogglesDecal mesh={box} part="x1" />
        <GogglesDecal mesh={box} part="x2" />
      </group>
    </>
  );
}
