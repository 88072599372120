import { useEffect, useRef } from 'react';
import { Logo } from './shared/Logo';
import { Footer1 } from './shared/Footer1';
import { Footer2 } from './shared/Footer2';
import { Arrows } from './shared/Arrows';
import { Barcode } from './shared/Barcode';
import { Code } from './shared/Code';
import { Cross } from './shared/Cross';
import { Num32 } from './shared/Num32';
import { Arrow2 } from './shared/Arrow2';
import { Arrow1 } from './shared/Arrow1';
import { Contact } from './shared/Contact';
import gsap from 'gsap';
import { useSection } from '../stores/useSection';
import style from './Edges.module.css';
import { SoundButton } from './component/SoundButton';
import { SoundBubble } from './component/SoundBubble';

export function Edges() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const container = useRef();
  const isFirst = useRef(true);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    for (const child of container.current.children) {
      if (!child.className.includes('contact')) {
        gsap.set(child, { scale: 0 });
      }
    }

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      show();
    } else if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  // SHOW
  const show = () => {
    // console.log('Edges :: show');
    const w = window.innerWidth * 0.5;
    const h = window.innerHeight * 0.5;

    const slide = -Math.max(window.innerWidth, window.innerHeight) * 0.04;

    let delay = 0.2;

    for (const child of container.current.children) {
      if (isFirst.current && child.className.includes('contact')) {
        isFirst.current = false;
      } else {
        const bounds = child.getBoundingClientRect();
        const left = bounds.x < w;
        const top = bounds.y < h;

        delay += 0.065;

        gsap.killTweensOf(child);
        gsap.set(child, { opacity: 1, x: 0, y: 0, scale: 1 });

        const duration = 0.75;

        gsap.from(child, {
          duration,
          delay,
          x: left ? slide : -slide,
          y: top ? slide : -slide,
          ease: 'power4.out',
        });

        gsap.from(child, {
          duration,
          delay: delay + 0,
          scale: 0,
          ease: 'back.out',
        });
      }
    }
  };

  // HIDE
  const hide = () => {
    const w = window.innerWidth * 0.5;
    const h = window.innerHeight * 0.5;

    const slide = -Math.max(window.innerWidth, window.innerHeight) * 0.1;

    let delay = 0;

    for (const child of container.current.children) {
      const bounds = child.getBoundingClientRect();
      const left = bounds.x < w;
      const top = bounds.y < h;

      gsap.killTweensOf(child);

      gsap.to(child, {
        duration: 0.3,
        delay,
        x: left ? slide : -slide,
        y: top ? slide : -slide,
        ease: 'power4.in',
      });

      delay += 0.02;
    }
  };

  // RESIZE
  const resizeHandler = () => {
    const scale = Math.min(window.innerWidth, window.innerHeight) / 1200;
    const size = 0.5 + scale * 0.7;

    var r = document.querySelector(':root');
    r.style.setProperty('--size', size);
    r.style.setProperty('--sizeBig', 1);
    r.style.setProperty('--scale', scale);
  };

  /*
   * visuals
   */

  return (
    <>
      <div
        ref={container}
        // className={`${style.container} ${transition === 'show' || transition === 'hide' ? style.shown : ''}`}
        className={style.container}
      >
        <Logo />

        <Barcode />
        <Arrows />

        <Footer1 />
        <Footer2 />

        <Arrow1 />
        <div className={style.contact}>
          <SoundBubble />
          <SoundButton />
          <Contact />
        </div>
        <Arrow2 />

        <Num32 />
        <Cross />
        <Code />
      </div>
    </>
  );
}
