import { useFrame } from '@react-three/fiber';
import style from './SoundBubble.module.css';
import { Howler } from 'howler';
import { useEffect, useRef, useState } from 'react';
import { useMouse } from '../../stores/useMouse';
import { useSection } from '../../stores/useSection';
import gsap from 'gsap';
import { RandomLabel } from './RandomLabel';

export function SoundBubble() {
  /*
   * properties
   */

  const wrapper = useRef();
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(true);

  /*
   * hooks
   */

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Howler._audioUnlocked) {
        clearInterval(intervalId);
        gsap.to(wrapper.current, {
          opacity: 0,
          onComplete: () => {
            setShow(false);
          },
          duration: 0.4,
          ease: 'power2.in',
        });
        setActive(true);
      }
    }, 250);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  /*
   * visuals
   */

  return (
    <>
      {show && (
        <div ref={wrapper} className={style.soundBubble}>
          <RandomLabel label="Touch anywhere" active={active} />
          <RandomLabel label="to start sound" active={active} />
        </div>
      )}
    </>
  );
}
