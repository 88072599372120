import { Environment, useHelper } from '@react-three/drei';
import { folder, useControls } from 'leva';
import { useEffect, useRef } from 'react';
import { CameraHelper, DirectionalLightHelper, SpotLightHelper } from 'three';
// import { useControls } from 'leva';
import gsap from 'gsap';

export function Lighting() {
  /*
   * properties
   */

  const shadowLight = useRef();

  const camera = useRef();

  // const ambientConfig = useControls('transparent ambient light', {
  //   lightIntensity: { value: 3.5, min: 0, max: 5, step: 0.01, label: 'intensity' },
  //   lightColor1: { value: '#0645ff', label: 'color 1' },
  //   lightColor2: { value: '#00ff06', label: 'color 2' },
  // });

  const ambientConfig = {
    lightIntensity: 3.5,
    lightColor1: '#0645ff',
    lightColor2: '#00ff06',
  };

  // const directionaConfig = useControls('transparent directional light', {
  //   position: { value: { x: -2, y: 0.15, z: -2 }, min: -5, max: 5, step: 0.01 },
  //   lightIntensity: { value: 0.2, min: 0, max: 5, step: 0.01, label: 'intensity' },
  //   color: { value: '#8affcf', label: 'color 1' },
  // });

  // const config = useControls('lights ° home', {
  //   helpers: { value: true },
  //   'shadow directional light': folder({
  //     positionShadow: { value: { x: -1.7, y: 0.44, z: 2 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //     // positionShadow: { value: { x: -2, y: 1, z: 4 }, min: -15, max: 15, step: 0.01, label: 'position' },
  //     intensityShadow: { value: 2.6, min: 0, max: 20, step: 0.01, label: 'intensity' },
  //     colorShadow: { value: '#def5ff', label: 'color' },
  //   }),
  //   'shadow camera': folder({
  //     left: { value: -7, min: -10, max: 10, step: 0.01 },
  //     right: { value: 7, min: -10, max: 10, step: 0.01 },
  //     top: { value: 6, min: -10, max: 10, step: 0.01 },
  //     bottom: { value: -6, min: -10, max: 10, step: 0.01 },
  //     far: { value: 20, min: 0, max: 50, step: 0.01 },
  //   }),
  // });

  const config = {
    helpers: false,

    positionShadow: { x: -1.7, y: 0.44, z: 2 },
    intensityShadow: 2.6,
    colorShadow: '#def5ff',

    left: -7,
    right: 7,
    top: 6,
    bottom: -6,
    far: 20,
  };

  // useHelper(shadowLight, DirectionalLightHelper);
  // useHelper(camera, CameraHelper);

  /*
   * hooks
   */

  useEffect(() => {
    show();

    return () => {};
  });

  const show = () => {
    // const delay = 1;
    // gsap.killTweensOf(shadowLight.current);
    // gsap.set(shadowLight.current.position, { x: 0 });
    // gsap.to(shadowLight.current.position, { x: -3, duration: 5, delay, ease: 'power1.out' });
  };

  /*
   * visuals
   */

  return (
    <>
      <directionalLight
        ref={shadowLight}
        position={[config.positionShadow.x, config.positionShadow.y, config.positionShadow.z]}
        intensity={config.intensityShadow}
        color={config.colorShadow}
        castShadow
      >
        <orthographicCamera
          attach="shadow-camera"
          ref={camera}
          far={config.far}
          top={config.top}
          bottom={config.bottom}
          left={config.left}
          right={config.right}
        />
      </directionalLight>

      <hemisphereLight
        position={[1, 0, 0]}
        intensity={ambientConfig.lightIntensity}
        args={[ambientConfig.lightColor1, ambientConfig.lightColor2]}
      />
    </>
  );
}
